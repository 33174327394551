.date-mask
	-webkit-mask-image: url('../img/new/recuadro-diagonal.svg')
	mask-image: url('../img/new/recuadro-diagonal.svg')
	mask: url('../img/new/recuadro-diagonal.svg')
	-webkit-mask-size: cover
	mask-size: cover
	-webkit-mask-repeat: no-repeat
	mask-repeat: no-repeat
	-webkit-mask-position: center
	mask-position: center
	width: 150px
	height: 74px
	


.mask-team-1
	-webkit-mask-image: url('../img/new/mascara_equipo_1.svg')
	mask-image: url('../img/new/mascara_equipo_1.svg')
	mask: url('../img/new/mascara_equipo_1.svg')
	-webkit-mask-size: cover
	mask-size: cover
	-webkit-mask-repeat: no-repeat
	mask-repeat: no-repeat
	-webkit-mask-position: center
	mask-position: center
	width:100%
	height: 100%	


.mask-team-2
	-webkit-mask-image: url('../img/new/mascara_equipo_2.svg')
	mask-image: url('../img/new/mascara_equipo_2.svg')
	mask: url('../img/new/mascara_equipo_2.svg')
	-webkit-mask-size: cover
	mask-size: cover
	-webkit-mask-repeat: no-repeat
	mask-repeat: no-repeat
	-webkit-mask-position: center
	mask-position: center
	width:100%
	height: 100%	



.mask-gallery
	-webkit-mask-image: url('../img/new/mascara_galeria.svg')
	mask-image: url('../img/new/mascara_galeria.svg')
	mask: url('../img/new/mascara_galeria.svg')
	-webkit-mask-size: cover
	mask-size: cover
	-webkit-mask-repeat: no-repeat
	mask-repeat: no-repeat
	-webkit-mask-position: center
	mask-position: center
	width:100%
	height: 100%	




	