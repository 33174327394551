.gmap
	width: 100%
	height: 100%
.gmap__container
	width: 100%
	height: 100%

// @media only screen and (max-width: $md)
// 	.gmap__container
// 		height: 600px
// 		max-height: 120vh
