.header[data-arts-theme-text='light'], .preloader[data-arts-theme-text='light']:not(.preloader_ended) + #page-header
	color: $color-gray-2
	.header__label
		color: #fff
	.logo__text-title
		color: #fff
	a, .button
		color: var(--color-gray-1)
		&:hover
			color: #fff
	.header__burger-line
		&:after, &:before
			background-color: #fff
	.menu .sub-menu
		background-color: #333333
	.menu .sub-menu > li a:hover
		background-color: #383838 !important
		color: #fff
		border-color: #fff
	.menu > li.current-menu-ancestor .sub-menu li.current-menu-ancestor > a
		background-color: #383838 !important
		color: #fff !important
	.menu > li.current-menu-ancestor .sub-menu li.current-menu-item > a
		background-color: #424242 !important
		color: #fff !important
