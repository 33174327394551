
$duration-5: 30s

@keyframes ticker
	0%
		transform: translateX(0)
	100%
		transform: translateX(-49%)

.border-ticker
	position: absolute !important
	z-index: 99
	height: 1px
	width: 100%
	.section__headline
		width: 100%
		margin: 0 auto
		background: #000

.ticker-wrap
	position: relative
	z-index: 99
	width: 100%
	overflow: hidden
	padding-left: 0% // offset items to begin
	border-bottom: 1px solid var(--color-dark-1)
	&.slider-top.fix
		position: relative !important

	&.slider-top
		margin-top: 115px
		padding-bottom: 1px
		position: absolute
		@media only screen and (max-width: $xs)
			margin-top: 80px

		.ticker
			margin-top: 15px
			margin-bottom: 10px
	&.news
		.ticker__item
			margin-right: 30px
			position: relative

		.ticker
			-webkit-animation-duration: 12s
			animation-duration: 12s
			margin: 15px 0px 10px 0px
		.svg
			width: 30px
			position: relative
			top: -10px
		.divider
			font-size: 60px
			line-height: 0.8

.ticker
	display: inline-block
	white-space: nowrap
	animation: ticker $duration-5 linear infinite
	&__item
		display: inline-block
		padding: 0px
		font-size: 20px
		color: #fff
		margin: 0
