details
	.contenido
		overflow: hidden
		ul.tabs
			display: grid
			grid-template-columns: repeat(auto-fill, minmax(200px, 1fr) )
			row-gap: 1rem
			margin: 0
			border-bottom: 1px solid var(--color-light-1)
			padding: 30px 70px 30px 90px
			background-color: var(--color-dark-1)
			color: white
			font-size: 19px
			list-style: none
			text-align: left
			@media only screen and (max-width: $xxl)
				padding: 30px 70px 30px 40px
				font-size: 18px
			@media only screen and (max-width: $xl)
				padding: 30px 70px 30px 20px
			@media only screen and (max-width: $md)
				font-size: 17px
			@media only screen and (max-width: $sm)
				font-size: 16px

			li.tab
				cursor: pointer
				user-select: none
				transition: all 1s ease
				padding: 2px 40px 2px 0px
				font-weight: 500
				line-height: 1.2

				&:hover, &.active
					color: var(--color-blue)
					text-decoration: underline

		.tab-content
			padding: 40px 30px 40px 90px
			background-color: var(--color-dark-1)
			margin: 0
			color: white
			list-style: none
			grid-template-columns: repeat(auto-fill, minmax(300px, 1fr) )
			display: none
			transition: all 2s
			font-size: 18px
			@media only screen and (max-width: $xxl)
				padding: 40px 30px 40px 40px
				font-size: 17px
			@media only screen and (max-width: $xl)
				padding: 40px 30px 40px 20px
			@media only screen and (max-width: $xs)
				font-size: 16px
			&.show
				display: grid
				animation: fade 1s forwards ease
			li
				transition: all 1s ease
				margin: .5rem 0
				cursor: pointer
				user-select: none
				padding-right: 60px
				&:hover
					color: var(--color-blue)
