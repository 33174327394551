
.header[data-arts-theme-text=light]
	.section__headline
		background: #fff
/* SLIDER PRINCIPIAL */	
.main-slider
	&[data-arts-theme-text="light"]

		.slider-projects-fullscreen__images
			background: #000 !important
			transition: background-color 0.4s ease
		h1, p, .button, span
			filter: invert(0%)
		span
			color: #fff
	.section-fullheight__inner_mobile
		min-height: 800px
		height: 100vh
		@media only screen and (max-width: $md)
			height: 100vh
			min-height: 600px

	.slider-projects-fullscreen__images
		transition: background-color 0.4s ease
		background: #fff
	.content_slider
		.slider__wrapper-button
			.button__title
				+fluid('font-size', 18, 20)



	.style1
		.content_slider
			+fluid('margin-left', 0, 150)
			position: relative
			top: 100px
			max-width: 700px

			
		
	.style2
		.content_slider
			max-width: 740px
			margin: 0 auto
			top: 100px
			position: relative
		.slider__wrapper-button
			display: flex
			justify-content: end
			align-items: end	
			@media only screen and (max-width: $xs)
				justify-content: start
		p
			+fluid('padding-left', 0, 100)
			+fluid('padding-right', 0, 100)
			margin-top: 30px
			
	.style3
		.content_slider
			max-width: 860px
			margin: 0 auto
			top: 100px
			position: relative
			.fix
				display: flex
				justify-content: space-between
				@media only screen and (max-width: $md)
					flex-direction: column

				h1
					max-width: 60%
					@media only screen and (max-width: $md)
						max-width: 100%
				p
					max-width: 40%
					margin-top: 80px
					@media only screen and (max-width: $md)
						max-width: 100%
						margin-top: 20px
		.slider__wrapper-button
			display: flex
			justify-content: end
			align-items: end	
			@media only screen and (max-width: $xs)
				justify-content: start
		

/* SLIDER BLOG */

.box-blog


	p
		font-size: 18px
	h4
		margin-bottom: 10px
	&.style-2
		padding: 40px 30px
		h4
			margin-top: 0px
	&.style-3
		padding: 0px 30px
		position: relative
	&.style-4
		padding: 0px
		.flex-1
			min-height: 300px
			height: 50%
		.flex-2
			min-height: 300px
			height: 50%
	.overlay
		background-color: rgba(0, 0, 0, 0.6)
		height: 100%
		z-index: 1
	&.bg-img
		z-index: 1
		&::before
			content: ''
			opacity: 0.5
			background: #000
			top: 0
			left: 0
			bottom: 0
			right: 0
			height: 100%
			width: 100%
			position: absolute
			z-index: -1
		.date
			background: var(--color-rose)
	&.bg-img-2
		padding: 0px
		a
			height: 600px
			@media only screen and (max-width: $xs)
				height: 540px
		img
			object-fit: cover
			height: 100%
			width: 100%
	.date
		background: #000
		&.position-absolute
			left: 20px
			top: 20px
	&.bg-opacity
		.title
			height: 100%
	.categorie
		color: var(--color-dark-1)
		font-size: 19px
		+fluid('font-size', 16, 18)
		font-weight: 600
	.arrow-in
		width: 80px
		svg
			transition: all .4s ease
	&:hover
		.arrow-in
			svg
				transition: all .4s ease
				transform: rotate(-140deg)
		.cls-1
			fill: #fff !important

.tags

	a
		display: table
		line-height: 2

		font-size: 13px !important
		padding: 0px 4px
		text-transform: uppercase
		font-weight: 600
		letter-spacing: 1px
		margin-bottom: 4px



		
.section-blog-slider
	.swiper-slide
		.box
			min-height: 600px
			padding: 20px
			margin-right: 0px
			margin-top: 50px
			margin-bottom: 50px
			position: relative
			@media only screen and (max-width: $lg)
				margin-top: 40px
				margin-bottom: 40px
			@media only screen and (max-width: $xs)
				margin-top: 30px
				margin-bottom: 30px
				min-height: 540px
			h5, p
				a
					text-decoration: underline

			&.style-2
				padding: 40px 40px 0px 40px
				h4
					margin-top: 0px
				.button
					position: absolute
					bottom: 0
					left: 0
					
			&.style-3
				padding: 0px 30px
				position: relative
			&.style-4
				padding: 0px
				.flex-1
					min-height: 300px
					height: 50%
				.flex-2
					min-height: 300px
					height: 50%
					padding: 30px 30px 0px 30px
			.overlay
				background-color: rgba(0, 0, 0, 0.6)
				height: 100%
				z-index: 1
			&.bg-img
				.date
					background: var(--color-rose)
			&.bg-img-2
				background: #F7F6F1
				padding: 30px
				.date
					display: none
			&.bg-rose
				.date
					background: #000
			&.bg-opacity
				.title
					height: 100%
			.date
				margin-top: -20px
				margin-left: -20px
				padding: 12px 10px
				font-size: 18px
				line-height: 1.2
				text-align: center
				font-weight: 500
			.categorie
				font-size: 19px
			.tags
				> div
					display: block
					line-height: 2
					a
						border: 1px solid #fff
						font-size: 13px
						padding: 2px 4px
						text-transform: uppercase
						font-weight: 600
						letter-spacing: 1px
			.arrow-in
				width: 80px
				svg
					transition: all .4s ease
			&:hover
				.arrow-in
					svg
						transition: all .4s ease
						transform: rotate(-140deg)
					.cls-1
						fill: #fff !important

.section_slider__blog
	.fix-40
		padding-top: 40px
		padding-bottom: 40px
		padding-left: 40px
		padding-right: 60px
		@media only screen and (max-width: $xxl)
			padding-right: 30px
		@media only screen and (max-width: $xl)
			padding-right: 0px
		@media only screen and (max-width: $xs)
			padding-left: 30px
			padding-right: 30px
	.view-all
		padding-top: 50px
		text-align: left !important

/* ECOSISTEMA */	

.main-slider.ecosistema
	.section-fullheight__inner_mobile
		height: auto !important
		@media only screen and (max-width: $md)
			min-height: 740px
		@media only screen and (max-width: $sm)
			min-height: 660px
		.circle
			position: relative
			right: -300px
			top: 60px
		.circle
			max-width: 360px
			max-height: 600px
			overflow: hidden
			margin: auto
			border-radius: 200px
			margin: 0 60px 0 0
			@media only screen and (max-width: $xl)
				max-width: 320px
				max-height: 560px
				top: 80px
				margin: 0 50px 0 0
			@media only screen and (max-width: $lg)
				max-width: 320px
				max-height: 520px
				top: 100px
				margin: 0 40px 0 0
			@media only screen and (max-width: $md)
				max-width: 300px
				max-height: 500px
				margin: 0 30px 0 0
				right: -200px
				top: 140px
			@media only screen and (max-width: $sm)
				right: 0
				margin: auto
				top: auto

		.circle:last-child
			@media only screen and (max-width: $md)
				display: none !important
		.circle:first-child
			@media only screen and (max-width: $sm)
				display: none !important

	.content_slider
		max-width: 460px
		top: 30px
		position: relative
		margin-left: 80px
		@media only screen and (max-width: $sm)
			margin: 0 auto

/* NOSOTROS */	
.main-slider.nosotros
	margin: 0px
	border-bottom: 1px solid #000 !important
	.section-fullheight__inner_mobile
		min-height: 800px
		height: 100vh
		@media only screen and (max-width: $md)
			height: 800px
			min-height: 800px
	.circle
		max-width: 720px
		max-height: 420px
		overflow: hidden
		margin: 0 auto
		border-radius: 200px
		top: 100px
		position: relative
		@media only screen and (max-width: $lg)
			max-width: 680px
			max-height: 400px
		@media only screen and (max-width: $sm)
			margin-left: 30px
			margin-right: 30px

		@media only screen and (max-width: $xs)
			max-width: 340px
			max-height: 560px
			margin: auto
			top: 100px
	.content_slider
		margin-left: 40px
		position: relative
		top: 100px
		max-width: 700px
		@media only screen and (max-width: $lg)
			max-width: 660px
		@media only screen and (max-width: $sm)
			margin-left: 10px
		@media only screen and (max-width: $xs)
			margin-left: 0px
			text-align: center

/* PROYECTOS*/	

.main-slider.proyectos
	.section-fullheight__inner_mobile
		@media (max-width: 500px)
			height: 880px
			min-height: 880px

		.circle
			position: absolute
			right: 0
			left: 0
			margin: 0 auto
			margin-left: 80px
		.circle
			max-width: 660px
			max-height: 660px
			overflow: hidden
			margin: 0 auto
			border-radius: 50%
			@media only screen and (max-width: $xxl)
				max-width: 600px
				max-height: 600px
			@media only screen and (max-width: $xl)
				max-width: 580px
				max-height: 580px
			@media only screen and (max-width: $lg)
				max-width: 540px
				max-height: 540px
			@media only screen and (max-width: $md)
				max-width: 500px
				max-height: 500px
				margin-top: 160px
			@media only screen and (max-width: $sm)
				max-width: 400px
				max-height: 400px
				top: 300px
				margin-top: 0px

			@media only screen and (max-width: $xs)
				max-width: 340px
				max-height: 340px
		.circle-dos
			margin-right: 0px
			right: -330px
			@media only screen and (max-width: $xl)
				right: -340px
			@media only screen and (max-width: $lg)
				right: -350px
			@media only screen and (max-width: $md)
				right: -400px

			@media only screen and (max-width: $sm)
				right: 0px
				left: 0px
				top: -200px
				margin: 0 auto

	.content_slider
		max-width: 640px
		top: 80px
		position: relative
		margin-left: 80px
		@media only screen and (max-width: $xs)
			margin-left: 0px

/* BLOG */		

.main-slider.blog
	.section-fullheight__inner_mobile
		height: 700px !important
		@media only screen and (max-width: $md)
			height: 500px !important
			min-height: 500px !important
		@media only screen and (max-width: $xs)
			height: 400px !important
			min-height: 400px !important
	.style__1
		.circle
			max-width: 70%
			max-height: 100%
			overflow: hidden
			margin: 0 0 0 auto
			border-radius: 0px
			top: 0px
			position: relative
			@media only screen and (max-width: $md)
				max-width: 100% !important

		.content_slider
			position: relative
			top: 0px
			max-width: 700px
			margin: auto 0
			left: 100px
			@media only screen and (max-width: $xl)
				left: 50px
			@media only screen and (max-width: $lg)
				left: 0px
	.tags
		position: absolute
		bottom: 20px
		ul
			list-style: none
			a
				border: 1px solid var(--color-orange)
				color: var(--color-orange)
				font-size: 14px

.main-slider.nosotros
	.hover-zoom__zoom, .hover-zoom__inner
		@media only screen and (max-width: $md)
			filter: brightness(0.5)

.main-slider.proyectos, .main-slider.ecosistema
	.hover-zoom__zoom, .hover-zoom__inner
		@media only screen and (max-width: $md)
			filter: opacity(0.7)
	.blend-mode
		mix-blend-mode: normal
	h1
		filter: invert(0%)
