html {
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  -webkit-text-rendering: optimizeSpeed;
  -moz-osx-font-smoothing: grayscale;
  font-kerning: none;
  scroll-behavior: auto !important;
}

html.smoothscroll body {
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

body {
  width: 100%;
  min-width: 320px;
  font-family: var(--font-primary);
  line-height: var(--paragraph-line-height);
  color: var(--paragraph-color-dark);
  font-size: 100%;
  word-wrap: break-word;
  overflow-wrap: anywhere;
  overflow-x: hidden;
}

img, iframe {
  max-width: 100%;
}

img {
  height: auto;
}

.page-wrapper__content {
  position: relative;
  overflow: hidden;
  z-index: 50;
}

a {
  text-decoration: none;
  color: var(--color-gray-3);
  transition: all 0.6s ease-in-out;
}
a:hover, a:focus {
  text-decoration: none;
}
a:hover {
  color: var(--color-dark-1);
}

.body_lock-scroll {
  overflow: hidden;
  position: fixed;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.container-fluid {
  padding-left: var(--gutter-horizontal, 120);
  padding-right: var(--gutter-horizontal, 120);
}

.container-fluid_paddings {
  padding-top: var(--gutter-horizontal, 120);
  padding-bottom: var(--gutter-horizontal, 120);
}

.container-fluid_default {
  padding-left: 20px;
  padding-right: 20px;
}

.arts-is-dragging {
  cursor: none;
}

#page-wrapper {
  overflow-x: hidden;
  background-color: #fff;
}

@media screen and (max-width: 991px) {
  .container_p-md-0 {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
  }
  .container_px-md-0 {
    padding-left: 0;
    padding-right: 0;
  }
  .container_px-md-0 > .row {
    margin-left: 0;
    margin-right: 0;
  }
  .container_px-md-0 > .row > [class*=col-] {
    padding-left: 0;
    padding-right: 0;
  }
  .container_py-md-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
}
@media screen and (max-width: 767px) {
  .container_p-sm-0 {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
  }
  .container_px-sm-0 {
    padding-left: 0;
    padding-right: 0;
  }
  .container_px-sm-0 > .row {
    margin-left: 0;
    margin-right: 0;
  }
  .container_px-sm-0 > .row > [class*=col-] {
    padding-left: 0;
    padding-right: 0;
  }
  .container_py-sm-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
}
@media screen and (max-width: 576px) {
  .container_p-xs-0 {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
  }
  .container_px-xs-0 {
    padding-left: 0;
    padding-right: 0;
  }
  .container_px-xs-0 > .row {
    margin-left: 0;
    margin-right: 0;
  }
  .container_px-xs-0 > .row > [class*=col-] {
    padding-left: 0;
    padding-right: 0;
  }
  .container_py-xs-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
}
:root {
  /* Bootstrap */
  --bs-gutter-x: 20px;
  --bs-gutter-y: 20px;
  /* Fonts */
  --font-primary: "Cordillera";
  /* Dark Colors */
  --color-rose: #ef8ecc;
  --color-orange: #ec6648;
  --color-blue: #4578f6;
  --color-yellow: #f2a343;
  --color-dark-1: #212121;
  --color-dark-2: #181818;
  --color-dark-3: #333333;
  --color-dark-4: #555555;
  /* Light Colors */
  --color-light-1: #e3e3e3;
  --color-light-2: #fafafa;
  --color-light-3: #efefef;
  --color-light-4: #ffffff;
  --color-gray-1: #e3e3e3;
  --color-gray-2: #ffffff;
  --color-gray-3: #888888;
  --color-gray-4: #e8e8e8;
  /* Portfolio Projects Colors */
  --color-portfolio-1: #f1f6f6;
  --color-portfolio-2: #262626;
  --color-portfolio-3: #f6f6f1;
  --color-portfolio-4: #d7d7d9;
  --color-portfolio-5: #dededb;
  --color-portfolio-6: #f7f6f3;
  --color-portfolio-7: #f0f0f0;
  --color-portfolio-8: #eaeaea;
  --color-portfolio-9: #f1f0f0;
  --color-portfolio-10: #eeece6;
  --color-portfolio-11: #fbf7f6;
  --color-portfolio-12: #e9eaec;
  /* Borders Colors */
  --color-border-dark: rgba(136, 136, 136, .3);
  --color-border-light: rgba(136, 136, 136, .3);
  /* XXL heading */
  --xxl-max-font-size: 110;
  --xxl-min-font-size: 60;
  --xxl-line-height: 0.9;
  --xxl-letter-spacing: 0;
  --xxl-color-dark: #212121;
  --xxl-color-light: #ffffff;
  /* XL heading */
  --xl-max-font-size: 92;
  --xl-min-font-size: 44;
  --xl-line-height: 1;
  --xl-letter-spacing: 0;
  --xl-color-dark: #212121;
  --xl-color-light: #ffffff;
  /* h1 heading */
  --h1-max-font-size: 58;
  --h1-min-font-size: 36;
  --h1-line-height: 1.2;
  --h1-letter-spacing: 0;
  --h1-color-dark: #212121;
  --h1-color-light: #ffffff;
  /* h2 heading */
  --h2-max-font-size: 50;
  --h2-min-font-size: 34;
  --h2-line-height: 1.1;
  --h2-letter-spacing: 0;
  --h2-color-dark: #212121;
  --h2-color-light: #ffffff;
  /* h3 heading */
  --h3-max-font-size: 40;
  --h3-min-font-size: 30;
  --h3-line-height: 1.2;
  --h3-letter-spacing: 0;
  --h3-color-dark: #212121;
  --h3-color-light: #ffffff;
  /* h4 heading */
  --h4-max-font-size: 30;
  --h4-min-font-size: 25;
  --h4-line-height: 1.2;
  --h4-letter-spacing: 0;
  --h4-color-dark: #212121;
  --h4-color-light: #ffffff;
  /* h5 heading */
  --h5-max-font-size: 26;
  --h5-min-font-size: 20;
  --h5-line-height: 1.2;
  --h5-letter-spacing: 0;
  --h5-color-dark: #212121;
  --h5-color-light: #ffffff;
  /* h6 heading */
  --h6-max-font-size: 22;
  --h6-min-font-size: 18;
  --h6-line-height: 1.3;
  --h6-letter-spacing: 0;
  --h6-color-dark: #212121;
  --h6-color-light: #ffffff;
  /* Subheading */
  --subheading-max-font-size: 26;
  --subheading-min-font-size: 20;
  --subheading-line-height: 1;
  --subheading-letter-spacing: 0;
  --subheading-color-dark: #212121;
  --subheading-color-light: #ffffff;
  /* Paragraph */	
  --paragraph-max-font-size: 18;
  --paragraph-min-font-size: 18;
  --paragraph-line-height: 1.4;
  --paragraph-letter-spacing: 0;
  --paragraph-color-dark: #212121;
  --paragraph-color-light: #ffffff;
  /* Blockquote */
  --blockquote-max-font-size: 24;
  --blockquote-min-font-size: 20;
  --blockquote-letter-spacing: 0;
  --blockquote-color-dark: #212121;
  --blockquote-color-light: #ffffff;
  /* Dropcap */
  --dropcap-max-font-size: 90;
  --dropcap-min-font-size: 60;
  --dropcap-color-dark: #111111;
  --dropcap-color-light: #ffffff;
  /* Fluid paddings & margins (min values) */
  --distance-min-xsmall: 30;
  --distance-min-small: 40;
  --distance-min-medium: 80;
  --distance-min-large: 80;
  --distance-min-xlarge: 90;
  /* Fluid paddings & margins (max values) */
  --distance-max-xsmall: 60;
  --distance-max-small: 80;
  --distance-max-medium: 100;
  --distance-max-large: 160;
  --distance-max-xlarge: 240;
  /* Container & page gutters */
  --gutter-horizontal: 30px;
  --gutter-vertical: 30px;
}
@media screen and (max-width: 1500px) {
  :root {
    --gutter-horizontal: 30px;
    --gutter-vertical: 30px;
  }
}
@media screen and (max-width: 1360px) {
  :root {
    --gutter-horizontal: 30px;
    --gutter-vertical: 30px;
  }
}
@media screen and (max-width: 991px) {
  :root {
    --gutter-horizontal: 20px;
    --gutter-vertical: 20px;
  }
}
@media screen and (max-width: 767px) {
  :root {
    --gutter-horizontal: 10px;
    --gutter-vertical: 12px;
  }
}

details .contenido {
  overflow: hidden;
}
details .contenido ul.tabs {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  row-gap: 1rem;
  margin: 0;
  border-bottom: 1px solid var(--color-light-1);
  padding: 30px 70px 30px 90px;
  background-color: var(--color-dark-1);
  color: white;
  font-size: 19px;
  list-style: none;
  text-align: left;
}
@media only screen and (max-width: 1500px) {
  details .contenido ul.tabs {
    padding: 30px 70px 30px 40px;
    font-size: 18px;
  }
}
@media only screen and (max-width: 1360px) {
  details .contenido ul.tabs {
    padding: 30px 70px 30px 20px;
  }
}
@media only screen and (max-width: 991px) {
  details .contenido ul.tabs {
    font-size: 17px;
  }
}
@media only screen and (max-width: 767px) {
  details .contenido ul.tabs {
    font-size: 16px;
  }
}
details .contenido ul.tabs li.tab {
  cursor: pointer;
  user-select: none;
  transition: all 1s ease;
  padding: 2px 40px 2px 0px;
  font-weight: 500;
  line-height: 1.2;
}
details .contenido ul.tabs li.tab:hover, details .contenido ul.tabs li.tab.active {
  color: var(--color-blue);
  text-decoration: underline;
}
details .contenido .tab-content {
  padding: 40px 30px 40px 90px;
  background-color: var(--color-dark-1);
  margin: 0;
  color: white;
  list-style: none;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  display: none;
  transition: all 2s;
  font-size: 18px;
}
@media only screen and (max-width: 1500px) {
  details .contenido .tab-content {
    padding: 40px 30px 40px 40px;
    font-size: 17px;
  }
}
@media only screen and (max-width: 1360px) {
  details .contenido .tab-content {
    padding: 40px 30px 40px 20px;
  }
}
@media only screen and (max-width: 576px) {
  details .contenido .tab-content {
    font-size: 16px;
  }
}
details .contenido .tab-content.show {
  display: grid;
  animation: fade 1s forwards ease;
}
details .contenido .tab-content li {
  transition: all 1s ease;
  margin: 0.5rem 0;
  cursor: pointer;
  user-select: none;
  padding-right: 60px;
}
details .contenido .tab-content li:hover {
  color: var(--color-blue);
}

/* BLOG */	
.section-blog .grid .box {
  margin-top: 35px;
  margin-bottom: 35px;
}
.section-blog .grid .section__headline.horizontal {
  position: absolute;
  right: 15px;
  height: calc(100% - 0px);
}
.section-blog .grid .section__headline.line-full-2 {
  width: 100% !important;
  position: absolute;
  left: -15px;
}
@media only screen and (max-width: 576px) {
  .section-blog .grid .section__headline.line-full-2 {
    left: 0px;
  }
}
.section-blog .grid .grid__item:nth-child(3n+1) .section__headline.horizontal {
  display: none !important;
}
@media only screen and (max-width: 991px) {
  .section-blog .grid .grid__item:nth-child(3n+1) .section__headline.horizontal {
    display: block !important;
  }
}
@media only screen and (max-width: 991px) {
  .section-blog .grid .grid__item:nth-child(2n+1) .section__headline.horizontal {
    display: none !important;
  }
}
@media only screen and (max-width: 767px) {
  .section-blog .grid .grid__item:nth-child(1n+1) .section__headline.horizontal {
    display: none !important;
  }
}
.section-blog .grid__item_fluid-1 {
  padding-top: 0px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 40px;
}
@media only screen and (max-width: 1360px) {
  .section-blog .grid__item_fluid-1 {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media only screen and (max-width: 576px) {
  .section-blog .grid__item_fluid-1 {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.section-blog .grid_fluid-1 {
  margin: 0px -20px;
}
@media only screen and (max-width: 1360px) {
  .section-blog .grid_fluid-1 {
    margin-left: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .section-blog .grid_fluid-1 {
    margin: 0px !important;
  }
}

.blog-internal .editor {
  font-size: calc(var(--paragraph-min-font-size) * 1px);
}
@media screen and (min-width: 320px) {
  .blog-internal .editor {
    font-size: calc(var(--paragraph-min-font-size) * 1px + (var(--paragraph-max-font-size) - var(--paragraph-min-font-size)) * ((100vw - 320px) / 1600));
  }
}
@media screen and (min-width: 1920px) {
  .blog-internal .editor {
    font-size: calc(var(--paragraph-max-font-size) * 1px);
  }
}
.blog-internal .editor .ol, .blog-internal .editor ul {
  padding-left: 15px;
}
.blog-internal .editor h1 {
  font-size: calc(var(--h2-min-font-size) * 1px);
  color: var(--color-rose);
  font-weight: 600;
}
@media screen and (min-width: 320px) {
  .blog-internal .editor h1 {
    font-size: calc(var(--h2-min-font-size) * 1px + (var(--h2-max-font-size) - var(--h2-min-font-size)) * ((100vw - 320px) / 1600));
  }
}
@media screen and (min-width: 1920px) {
  .blog-internal .editor h1 {
    font-size: calc(var(--h2-max-font-size) * 1px);
  }
}
.blog-internal .editor h2 {
  font-size: calc(var(--h3-min-font-size) * 1px);
  color: var(--color-orange);
  font-weight: 600;
}
@media screen and (min-width: 320px) {
  .blog-internal .editor h2 {
    font-size: calc(var(--h3-min-font-size) * 1px + (var(--h3-max-font-size) - var(--h3-min-font-size)) * ((100vw - 320px) / 1600));
  }
}
@media screen and (min-width: 1920px) {
  .blog-internal .editor h2 {
    font-size: calc(var(--h3-max-font-size) * 1px);
  }
}
.blog-internal .editor h3 {
  font-size: calc(var(--h4-min-font-size) * 1px);
}
@media screen and (min-width: 320px) {
  .blog-internal .editor h3 {
    font-size: calc(var(--h4-min-font-size) * 1px + (var(--h4-max-font-size) - var(--h4-min-font-size)) * ((100vw - 320px) / 1600));
  }
}
@media screen and (min-width: 1920px) {
  .blog-internal .editor h3 {
    font-size: calc(var(--h4-max-font-size) * 1px);
  }
}
.blog-internal .editor h4 {
  font-size: calc(var(--h5-min-font-size) * 1px);
}
@media screen and (min-width: 320px) {
  .blog-internal .editor h4 {
    font-size: calc(var(--h5-min-font-size) * 1px + (var(--h5-max-font-size) - var(--h5-min-font-size)) * ((100vw - 320px) / 1600));
  }
}
@media screen and (min-width: 1920px) {
  .blog-internal .editor h4 {
    font-size: calc(var(--h5-max-font-size) * 1px);
  }
}
.blog-internal .editor a {
  color: var(--color-orange);
  font-weight: 600;
}
.blog-title {
  margin-top: 140px;
  margin-bottom: 40px;
}
.blog-title .tags {
  margin-top: 30px;
}
.blog-title .tags a {
  border: 1px solid var(--color-orange);
  color: var(--color-orange);
  font-size: 12px !important;
}

@media only screen and (min-width: 991px) {
  .single-post .header {
    mix-blend-mode: difference;
  }
  .single-post .header .header__burger-label {
    color: #fff;
  }
  .single-post .header .header__burger-line:before, .single-post .header .header__burger-line:after {
    background: #fff;
  }
  .single-post .header .logo__wrapper-img {
    filter: invert(100%);
  }
  .single-post .header .section__headline {
    background-color: #fff;
  }
  .single-post .header.header_sticky, .single-post .header.opened, .single-post .header[data-arts-header-animation=intransition] {
    mix-blend-mode: normal;
  }
  .single-post .header.header_sticky .header__burger-label, .single-post .header.opened .header__burger-label, .single-post .header[data-arts-header-animation=intransition] .header__burger-label {
    color: #000;
  }
  .single-post .header.header_sticky .header__burger-line:before, .single-post .header.header_sticky .header__burger-line:after, .single-post .header.opened .header__burger-line:before, .single-post .header.opened .header__burger-line:after, .single-post .header[data-arts-header-animation=intransition] .header__burger-line:before, .single-post .header[data-arts-header-animation=intransition] .header__burger-line:after {
    background: #000;
  }
  .single-post .header.header_sticky .logo__wrapper-img, .single-post .header.opened .logo__wrapper-img, .single-post .header[data-arts-header-animation=intransition] .logo__wrapper-img {
    filter: invert(0%);
  }
  .single-post .header.header_sticky .section__headline, .single-post .header.opened .section__headline, .single-post .header[data-arts-header-animation=intransition] .section__headline {
    background: #000;
  }
}
.share p {
  color: var(--color-rose);
  margin-bottom: 10px;
}

@media only screen and (max-width: 576px) {
  .section-blog .box-blog {
    margin-right: 0px;
  }
}

.box-blog {
  background: var(--color-light-3);
  min-height: 620px;
}
@media only screen and (max-width: 576px) {
  .box-blog {
    min-height: auto;
  }
}

.box-blog.fix .categorie {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 20px;
  gap: 4px;
}
.box-blog.fix .categorie a {
  color: var(--color-dark-1);
}
.box-blog.fix .content {
  background: var(--color-light-3);
}
.box-blog.fix .content .title {
  padding: 0px 20px;
}
.box-blog.fix .bg-img {
  height: 300px !important;
}
.box-blog.fix h4 {
  color: var(--color-dark-1);
}

.categories-menu {
  display: flex;
  gap: 12px;
}
.categories-menu a {
  color: var(--color-light-1);
  font-size: calc(1 * (18 * 1px));
  font-weight: bold;
  background: var(--color-dark-1);
  padding: 5px 10px;
}
@media screen and (min-width: 320px) {
  .categories-menu a {
    font-size: calc(1 * (18 * 1px + (24 - 18) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .categories-menu a {
    font-size: calc(1 * (24 * 1px));
  }
}
.categories-menu a.category_active {
  background: var(--color-blue);
}
.categories-menu a:hover {
  opacity: 0.8;
}

.box-blog.old {
  padding: 20px;
}
.box-blog.old .date {
  margin-top: -20px;
  margin-left: -20px;
  padding: 12px 10px;
  font-size: 18px;
  line-height: 1.2;
  text-align: center;
  font-weight: 500;
}

.box-blog.old-2 .date {
  margin-top: -20px;
  padding: 12px 10px;
  font-size: 18px;
  line-height: 1.2;
  text-align: center;
  font-weight: 500;
}

.date {
  padding: 0px 15px;
  font-size: 18px;
  line-height: 1.2;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-transform: uppercase;
  background: var(--color-dark-1);
}
.date .number {
  font-size: calc(1 * (20 * 1px));
  color: var(--color-light-1);
}
@media screen and (min-width: 320px) {
  .date .number {
    font-size: calc(1 * (20 * 1px + (26 - 20) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .date .number {
    font-size: calc(1 * (26 * 1px));
  }
}
.date .month {
  font-size: calc(1 * (16 * 1px));
  color: var(--color-light-1);
}
@media screen and (min-width: 320px) {
  .date .month {
    font-size: calc(1 * (16 * 1px + (18 - 16) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .date .month {
    font-size: calc(1 * (18 * 1px));
  }
}

.intro-single {
  margin-top: 130px;
}
@media only screen and (max-width: 767px) {
  .intro-single {
    margin-top: 112px;
  }
}
.intro-single:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 180px);
  background: var(--color-blue);
  z-index: -1;
}
@media only screen and (max-width: 991px) {
  .intro-single:before {
    height: calc(100% - 100px);
  }
}
.intro-single h1 {
  padding-top: 20px;
  padding-bottom: 220px;
}
@media only screen and (max-width: 991px) {
  .intro-single h1 {
    padding-bottom: 20px;
  }
}
.intro-single .mask-blog {
  background: var(--color-light-1);
  width: 180px;
  height: 180px;
  position: absolute;
  bottom: 0;
  left: 0;
  clip-path: polygon(0 0, 100% 100%, 100% 100%, 0 100%);
  bottom: -1px;
}
@media only screen and (max-width: 991px) {
  .intro-single .mask-blog {
    display: none;
  }
}
.intro-single .date {
  position: absolute;
  top: 0;
}

.blog-internal {
  margin-top: -180px;
}
@media only screen and (max-width: 991px) {
  .blog-internal {
    margin-top: 0px;
  }
}
@media only screen and (max-width: 991px) {
  .blog-internal .date {
    display: none;
  }
}
.blog-internal .editor {
  margin-top: 20px;
  margin-bottom: 100px;
}
.blog-internal .editor .col-12 > p:first-of-type:not(blockquote p) {
  width: 60%;
}
@media only screen and (max-width: 991px) {
  .blog-internal .editor .col-12 > p:first-of-type:not(blockquote p) {
    width: 100%;
  }
}
.blog-internal .editor .col-12 > p:nth-of-type(2):not(blockquote p) {
  width: 70%;
}
@media only screen and (max-width: 991px) {
  .blog-internal .editor .col-12 > p:nth-of-type(2):not(blockquote p) {
    width: 100%;
  }
}
.blog-internal .editor .col-12 p {
  width: 80%;
}
.blog-internal .editor .col-12 > ul > li:first-of-type {
  width: 50%;
}
.blog-internal .editor .col-12 > ul > li:nth-of-type(2) {
  width: 50%;
}

.card-carrusel {
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.card-carrusel .root {
  transform: rotate(-35deg) translateX(-50%);
}
.card-carrusel .root .tira {
  background-color: black;
  padding: 1rem 0;
  width: max-content;
  color: white;
  font-size: 2rem;
  font-weight: 500;
  margin: 3.5rem 0;
  animation: carrusel 16s infinite linear;
}
@media only screen and (max-width: 576px) {
  .card-carrusel .root .tira {
    font-size: 1.8rem;
    padding: 0.6rem 0;
    margin: 2rem 0;
  }
}
.card-carrusel .root .tira.inverse {
  animation-direction: reverse;
}

@keyframes carrusel {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-49%);
  }
}
/* CONTACTO  */	
.section-contacto .margin-header {
  margin-top: 0px;
}

.modal.contacto .wpcf7 {
  margin-top: 50px;
}
.modal.contacto .wpcf7 input, .modal.contacto .wpcf7 textarea {
  border-radius: 0px;
  border: 1px solid #000;
  min-width: 460px;
  padding: 8px;
  font-size: 18px;
  text-align: center;
}
.modal.contacto .wpcf7 textarea {
  height: 120px;
}
.modal.contacto .wpcf7 input {
  height: 36px;
}
.modal.contacto .wpcf7 label {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: notmal;
  letter-spacing: 1px;
}
@media only screen and (max-width: 767px) {
  .modal.contacto .wpcf7 label {
    font-size: 16px;
  }
}
.modal.contacto .wpcf7 .wpcf7-not-valid-tip {
  text-align: center;
  font-weight: 500;
  color: var(--color-rose);
}
.modal.contacto .wpcf7 .wpcf7-submit {
  color: var(--color-rose) !important;
  font-weight: 600;
  margin-top: 10px;
  border: 2px solid var(--color-rose) !important;
  padding: 8px 25px !important;
  background: transparent;
  font-size: 18px !important;
  transition: all 0.4s ease;
  min-width: 140px;
  height: auto;
  margin-left: 60px;
  margin-top: 20px;
}
.modal.contacto .wpcf7 .wpcf7-submit:hover {
  background: var(--color-rose) !important;
  color: #fff !important;
  transition: all 0.4s ease;
}

.section-contacto .bottom {
  display: none;
}
@media only screen and (max-width: 767px) {
  .section-contacto .bottom {
    display: block;
  }
}
@media only screen and (max-width: 767px) {
  .section-contacto .line-full-2, .section-contacto .horizontal {
    display: none;
  }
}

.grid__item:nth-child(3n+1) .section__headline.horizontal_ {
  display: none !important;
}

.grecaptcha-badge {
  display: none !important;
}

.circle-header {
  position: absolute;
  z-index: 999;
  z-index: 9;
  top: -120px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.circle-header .circle {
  max-width: 600px;
  max-height: 600px;
  overflow: hidden;
  margin: auto;
  border-radius: 0vw 0vw 300px 300px;
  margin: 0 auto;
  position: relative;
  top: 0px;
}
@media only screen and (max-width: 1199px) {
  .circle-header .circle {
    max-width: 560px;
    max-height: 560px;
  }
}
@media only screen and (max-width: 576px) {
  .circle-header .circle {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.section-estudios {
  margin-top: 340px;
}
@media only screen and (max-width: 1199px) {
  .section-estudios {
    margin-top: 320px;
  }
}
@media only screen and (max-width: 767px) {
  .section-estudios {
    margin-top: 300px;
  }
}
@media only screen and (max-width: 576px) {
  .section-estudios {
    margin-top: 260px;
  }
}
.section-estudios .box-content .box {
  position: relative;
  margin-bottom: 40px;
}
@media only screen and (max-width: 1500px) {
  .section-estudios .box-content .box {
    padding: 0px 15px;
  }
}
@media only screen and (max-width: 1360px) {
  .section-estudios .box-content .box {
    padding: 0px 10px;
  }
}
@media only screen and (max-width: 576px) {
  .section-estudios .box-content .box {
    margin-bottom: 40px;
  }
}
.section-estudios .box-content .box .flex-1 {
  min-height: 270px;
}
.section-estudios .box-content .box .img-download {
  min-height: 300px;
  background-size: cover;
  background-position: center;
  position: relative;
}
@media only screen and (max-width: 576px) {
  .section-estudios .box-content .box .img-download {
    min-height: 260px;
  }
}
.section-estudios .box-content .box .img-download .selectores {
  position: absolute;
  bottom: 20px;
  left: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.section-estudios .box-content .box .img-download .sector {
  color: var(--color-light-1);
  background: var(--color-dark-1);
  padding: 4px 8px;
  line-height: 1;
  font-weight: bold;
  text-transform: uppercase;
  font-size: calc(1 * (11 * 1px));
  letter-spacing: 1px;
  clip-path: polygon(0 0, calc(100% - 15px) 0, 100% 100%, 0 100%);
  padding-right: 20px;
  width: fit-content;
  display: inline-flex;
}
@media screen and (min-width: 320px) {
  .section-estudios .box-content .box .img-download .sector {
    font-size: calc(1 * (11 * 1px + (12 - 11) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .section-estudios .box-content .box .img-download .sector {
    font-size: calc(1 * (12 * 1px));
  }
}
.section-estudios .box-content .box .img-download .ano {
  background: var(--color-dark-1);
  color: #fff;
  display: inline-block;
  padding: 10px 4px;
  line-height: 1;
  font-weight: 600;
  position: absolute;
  left: 30px;
  font-size: calc(1 * (18 * 1px));
  max-width: 50px;
  word-break: break-all;
  text-align: center;
}
@media screen and (min-width: 320px) {
  .section-estudios .box-content .box .img-download .ano {
    font-size: calc(1 * (18 * 1px + (22 - 18) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .section-estudios .box-content .box .img-download .ano {
    font-size: calc(1 * (22 * 1px));
  }
}
.section-estudios .box-content .box .img-download .download {
  font-size: 17px;
  font-weight: 600;
  color: #000;
  position: absolute;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
}
.section-estudios .box-content .box .img-download .download .lazy-wrapper {
  margin-left: 10px;
  width: 20px;
}
.section-estudios .box-content .box .img-download .tringulo {
  width: 180px;
  height: 140px;
  position: absolute;
  right: 0px;
  top: -1px;
  clip-path: polygon(100% 0, 0 0, 100% 100%);
}
.section-estudios .box-content .box .bg {
  padding: 30px;
  min-height: 200px;
}

.filtros .todos, .filtros option {
  margin-bottom: 4px;
  font-weight: bold !important;
}
.filtros .todos.ano, .filtros option.ano {
  border-bottom: 0px;
}

select {
  display: block;
  background: var(--color-dark-1);
  padding: 10px 15px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("../img/general/select_black.png");
  background-position: right 15px bottom 50%;
  background-repeat: no-repeat;
  background-size: 6px 4px;
  font-weight: 600;
  font-size: 16px;
  color: var(--color-light-1);
  border: none;
  margin-bottom: 5px;
}

.section-estudios .box-content > div:nth-child(6n+1) .bg, .section-estudios .box-content > div:nth-child(6n+1) .tringulo {
  background: var(--color-rose);
}
.section-estudios .box-content > div:nth-child(6n+2) .bg, .section-estudios .box-content > div:nth-child(6n+2) .tringulo {
  background: var(--color-yellow);
}
.section-estudios .box-content > div:nth-child(6n+3) .bg, .section-estudios .box-content > div:nth-child(6n+3) .tringulo {
  background: var(--color-orange);
}
.section-estudios .box-content > div:nth-child(6n+4) .bg, .section-estudios .box-content > div:nth-child(6n+4) .tringulo {
  background: var(--color-orange);
}
.section-estudios .box-content > div:nth-child(6n+5) .bg, .section-estudios .box-content > div:nth-child(6n+5) .tringulo {
  background: var(--color-rose);
}
.section-estudios .box-content > div:nth-child(6n+6) .bg, .section-estudios .box-content > div:nth-child(6n+6) .tringulo {
  background: var(--color-yellow);
}

#feedInstagram .feed {
  width: 33%;
  padding: 0px 15px;
}
@media only screen and (max-width: 991px) {
  #feedInstagram .feed {
    padding: 0px 10px;
  }
}
@media only screen and (max-width: 767px) {
  #feedInstagram .feed {
    width: 50%;
  }
}
@media only screen and (max-width: 767px) {
  #feedInstagram .feed:last-child {
    width: 100%;
  }
}

.feedInstagram .feed {
  display: inline-block;
  overflow: hidden;
  position: relative;
  margin-bottom: 25px;
  width: 40%;
}
@media only screen and (max-width: 767px) {
  .feedInstagram .feed {
    margin-bottom: 20px;
  }
}
.feedInstagram .feed video {
  width: 100%;
  overflow: hidden;
  height: 100%;
  transition: all 1s ease;
}
.feedInstagram .feed a {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
}
.feedInstagram .feed a img {
  transition: all 1s ease;
  transform: scale(1);
  height: 100%;
  overflow: hidden;
}
.feedInstagram .feed svg {
  fill: white;
  width: 48px;
  height: 48px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(2);
  opacity: 0;
  transition: all 0.5s ease;
}
@media only screen and (max-width: 767px) {
  .feedInstagram .feed svg {
    width: 34px;
    height: 34px;
  }
}
.feedInstagram .feed:hover img, .feedInstagram .feed:hover video {
  filter: saturate(0);
  transform: scale(1.1);
}
.feedInstagram .feed:hover svg {
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
}

.bg-blue .new-style a, .bg-blue .new-style summary {
  border-bottom: 1px solid var(--color-light-1);
  border-right: 1px solid var(--color-light-1);
}
.bg-blue .new-style h2 {
  color: var(--color-light-1);
}

.new-style, details {
  padding-right: calc(1 * (20 * 1px));
}
@media screen and (min-width: 320px) {
  .new-style, details {
    padding-right: calc(1 * (20 * 1px + (200 - 20) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .new-style, details {
    padding-right: calc(1 * (200 * 1px));
  }
}
.new-style summary, details summary {
  padding-left: 90px !important;
}
@media only screen and (max-width: 1500px) {
  .new-style summary, details summary {
    padding-left: 40px !important;
  }
}
@media only screen and (max-width: 1360px) {
  .new-style summary, details summary {
    padding-left: 20px !important;
  }
}
.new-style[open] summary, details[open] summary {
  border-bottom: 1px solid var(--color-light-1);
}
.new-style[open] summary:before, details[open] summary:before {
  transform: rotate(-90deg);
}
.new-style[open] summary:after, details[open] summary:after {
  height: 100%;
}
.new-style[open] summary svg, details[open] summary svg {
  transition: all 0.3s ease;
  stroke: var(--color-light-1);
  transform: rotate(180deg);
}
.new-style[open] summary svg line, .new-style[open] summary svg polyline, details[open] summary svg line, details[open] summary svg polyline {
  stroke: var(--color-light-1);
}
.new-style[open] summary:after, details[open] summary:after {
  height: 100%;
}
.new-style[open] summary h2, details[open] summary h2 {
  color: var(--color-light-1);
  transition: all 0.3s ease;
}
.new-style a, .new-style summary, details a, details summary {
  border-bottom: 1px solid var(--color-dark-1);
  border-right: 1px solid var(--color-dark-1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: calc(1 * (20 * 1px));
  padding-bottom: calc(1 * (20 * 1px));
  padding-left: calc(1 * (20 * 1px));
  padding-right: calc(1 * (20 * 1px));
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
@media screen and (min-width: 320px) {
  .new-style a, .new-style summary, details a, details summary {
    padding-top: calc(1 * (20 * 1px + (35 - 20) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .new-style a, .new-style summary, details a, details summary {
    padding-top: calc(1 * (35 * 1px));
  }
}
@media screen and (min-width: 320px) {
  .new-style a, .new-style summary, details a, details summary {
    padding-bottom: calc(1 * (20 * 1px + (35 - 20) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .new-style a, .new-style summary, details a, details summary {
    padding-bottom: calc(1 * (35 * 1px));
  }
}
@media screen and (min-width: 320px) {
  .new-style a, .new-style summary, details a, details summary {
    padding-left: calc(1 * (20 * 1px + (200 - 20) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .new-style a, .new-style summary, details a, details summary {
    padding-left: calc(1 * (200 * 1px));
  }
}
@media screen and (min-width: 320px) {
  .new-style a, .new-style summary, details a, details summary {
    padding-right: calc(1 * (20 * 1px + (100 - 20) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .new-style a, .new-style summary, details a, details summary {
    padding-right: calc(1 * (100 * 1px));
  }
}
.new-style a .button, .new-style summary .button, details a .button, details summary .button {
  background: transparent;
}
.new-style a .button span, .new-style summary .button span, details a .button span, details summary .button span {
  color: var(--color-dark-1);
  position: relative;
  z-index: 1;
}
.new-style a h2, .new-style summary h2, details a h2, details summary h2 {
  font-weight: 600 !important;
  margin: 0;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease;
}
.new-style a svg, .new-style summary svg, details a svg, details summary svg {
  width: calc(1 * (80 * 1px));
  transition: all 0.3s ease;
  position: relative;
  z-index: 1;
}
@media screen and (min-width: 320px) {
  .new-style a svg, .new-style summary svg, details a svg, details summary svg {
    width: calc(1 * (80 * 1px + (90 - 80) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .new-style a svg, .new-style summary svg, details a svg, details summary svg {
    width: calc(1 * (90 * 1px));
  }
}
.new-style a:hover svg, .new-style summary:hover svg, details a:hover svg, details summary:hover svg {
  transition: all 0.3s ease;
  stroke: var(--color-light-1);
  transform: rotate(180deg);
}
.new-style a:hover svg line, .new-style a:hover svg polyline, .new-style summary:hover svg line, .new-style summary:hover svg polyline, details a:hover svg line, details a:hover svg polyline, details summary:hover svg line, details summary:hover svg polyline {
  stroke: var(--color-light-1);
}
.new-style a:hover:after, .new-style summary:hover:after, details a:hover:after, details summary:hover:after {
  height: 100%;
}
.new-style a:hover h2, .new-style summary:hover h2, details a:hover h2, details summary:hover h2 {
  color: var(--color-light-1);
  transition: all 0.3s ease;
}
.new-style a:after, .new-style summary:after, details a:after, details summary:after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  width: 100%;
  height: 0;
  background-color: var(--color-dark-1);
  transition: all 0.5s ease;
}

.cursor-pointer {
  cursor: pointer !important;
}

.box-hidden {
  overflow: hidden;
}
.box-hidden .bg-img {
  transition: all 0.5s ease;
}
.box-hidden:hover .bg-img {
  transform: scale(1.05);
  transition: all 0.5s ease;
}

.estudios .box .img-download {
  transition: all 0.5s ease;
}
.estudios .box:hover .img-download {
  transform: scale(1.04);
  transition: all 0.5s ease;
}

.box-blog:hover .bg-img {
  transform: scale(1.05);
  transition: all 0.5s ease;
}

.categories-menu a {
  position: relative;
}
.categories-menu a::after {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  right: -3px;
  bottom: -3px;
  border: 3px solid transparent;
  transition: all 0.3s ease;
}
.categories-menu a:hover {
  background: transparent !important;
  color: #000 !important;
}
.categories-menu a:hover::after {
  border-color: #000;
}

.fix-underline span {
  text-decoration: underline;
  text-decoration-thickness: 3px !important;
  text-underline-offset: 4px !important;
}

.date-mask {
  -webkit-mask-image: url("../img/new/recuadro-diagonal.svg");
  mask-image: url("../img/new/recuadro-diagonal.svg");
  mask: url("../img/new/recuadro-diagonal.svg");
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  width: 150px;
  height: 74px;
}

.mask-team-1 {
  -webkit-mask-image: url("../img/new/mascara_equipo_1.svg");
  mask-image: url("../img/new/mascara_equipo_1.svg");
  mask: url("../img/new/mascara_equipo_1.svg");
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  width: 100%;
  height: 100%;
}

.mask-team-2 {
  -webkit-mask-image: url("../img/new/mascara_equipo_2.svg");
  mask-image: url("../img/new/mascara_equipo_2.svg");
  mask: url("../img/new/mascara_equipo_2.svg");
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  width: 100%;
  height: 100%;
}

.mask-gallery {
  -webkit-mask-image: url("../img/new/mascara_galeria.svg");
  mask-image: url("../img/new/mascara_galeria.svg");
  mask: url("../img/new/mascara_galeria.svg");
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  width: 100%;
  height: 100%;
}

[data-arts-theme-text=light] .menu-scroll.style__2 ul li:after {
  background: #fff;
}
[data-arts-theme-text=light] .menu-scroll ul li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
}
[data-arts-theme-text=light] .menu-scroll ul li a {
  color: rgba(255, 255, 255, 0.6);
}
[data-arts-theme-text=light] .menu-scroll ul li:hover {
  color: #000;
}
[data-arts-theme-text=light] .menu-scroll ul li.active {
  border-bottom: 1px solid #fff;
  color: #fff !important;
}
[data-arts-theme-text=light] .menu-scroll ul li.active a {
  color: #fff;
}

.menu-scroll {
  width: 250px !important;
}
.menu-scroll.slider {
  position: absolute;
  right: 40px;
  top: 50%;
  transform: translate(0%, 0%);
  width: 260px;
}
@media only screen and (max-width: 1360px) {
  .menu-scroll.slider {
    right: 10px;
  }
}
.menu-scroll.style__2 {
  width: auto !important;
}
.menu-scroll.style__2 ul {
  display: flex;
  margin-bottom: 0;
}
.menu-scroll.style__2 ul li {
  position: relative;
  padding-right: 30px;
  border: none;
  margin-bottom: 0px;
}
.menu-scroll.style__2 ul li:after {
  content: "";
  position: absolute;
  right: 15px;
  height: 18px;
  width: 1px;
  top: 4px;
  background: #000;
}
.menu-scroll.style__2 ul li.active {
  border: none;
  color: #fff !important;
}
.menu-scroll.style__2 ul li:last-child:after {
  display: none !important;
}
.menu-scroll ul {
  list-style: none;
}
.menu-scroll ul li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.6);
  margin-bottom: 10px;
  font-size: 18px;
}
.menu-scroll ul li a {
  color: rgba(0, 0, 0, 0.6);
  font-size: 18px;
  display: block;
}
.menu-scroll ul li:hover {
  color: #000;
}
.menu-scroll ul li.active {
  border-bottom: 1px solid #000;
  font-weight: 500;
}
.menu-scroll ul li.active a {
  color: #000;
  font-weight: 600;
}

.section-texto-creatividad .sub-svg {
  line-height: 1.3 !important;
}
.section-texto-creatividad.show::after {
  opacity: 1;
  transition: opacity 2s;
}

.section-nosotros {
  padding-top: calc(1 * (170 * 1px));
}
@media screen and (min-width: 320px) {
  .section-nosotros {
    padding-top: calc(1 * (170 * 1px + (260 - 170) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .section-nosotros {
    padding-top: calc(1 * (260 * 1px));
  }
}
.section-nosotros strong {
  font-weight: normal !important;
  text-decoration: underline;
  text-underline-offset: 10px;
  text-decoration-thickness: 3px;
}

@media only screen and (max-width: 991px) {
  .section-apoyamos .image-hover {
    background: var(--color-dark-1) !important;
  }
}
.section-apoyamos .content-number:nth-child(1) .image-hover, .section-apoyamos .content-number:nth-child(1) .diagonal {
  background: var(--color-rose);
}
.section-apoyamos .content-number:nth-child(2) .image-hover, .section-apoyamos .content-number:nth-child(2) .diagonal {
  background: var(--color-orange);
}
.section-apoyamos .content-number:nth-child(3) .image-hover, .section-apoyamos .content-number:nth-child(3) .diagonal {
  background: var(--color-yellow);
}
.section-apoyamos .inside-number:hover {
  height: 350px;
}

.content-number {
  position: relative;
  transition: margin 0.5s ease-in-out;
}
.content-number .horizontal.left {
  position: absolute;
  left: 0;
}
.content-number .horizontal.right {
  position: absolute;
  right: 0;
}
.content-number .inside-number {
  padding: 0px;
  position: relative;
  height: calc(1 * (100 * 1px));
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}
@media screen and (min-width: 320px) {
  .content-number .inside-number {
    height: calc(1 * (100 * 1px + (240 - 100) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .content-number .inside-number {
    height: calc(1 * (240 * 1px));
  }
}
@media only screen and (max-width: 991px) {
  .content-number .inside-number {
    padding: 50px 20px 40px 0px;
    height: auto !important;
    overflow: visible;
  }
}
@media only screen and (max-width: 576px) {
  .content-number .inside-number {
    padding: 30px 10px 20px 0px;
  }
}
.content-number .inside-number .num {
  margin-right: calc(1 * (0 * 1px));
  font-weight: bold !important;
}
@media screen and (min-width: 320px) {
  .content-number .inside-number .num {
    margin-right: calc(1 * (0 * 1px + (20 - 0) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .content-number .inside-number .num {
    margin-right: calc(1 * (20 * 1px));
  }
}
.content-number .inside-number .title {
  line-height: 1.1;
}
@media only screen and (min-width: 991px) {
  .content-number .inside-number:hover {
    height: 350px;
  }
  .content-number .inside-number:hover + .content-number {
    margin-top: 20px;
    transition: margin 0.5s ease-in-out;
  }
  .content-number .inside-number:hover .normal {
    transform: translateY(-100%);
    opacity: 0;
  }
  .content-number .inside-number:hover .image-hover {
    transform: translateY(0);
    opacity: 1;
  }
}
.content-number .normal {
  position: absolute;
  width: 100%;
  transition: all 0.5s ease-in-out;
  transform: translateY(0);
  opacity: 1;
}
@media only screen and (max-width: 991px) {
  .content-number .normal {
    position: relative;
  }
}
.content-number .image-hover {
  position: absolute;
  width: 100%;
  transition: all 0.5s ease-in-out;
  transform: translateY(100%);
  opacity: 0;
  height: 100%;
}
@media only screen and (max-width: 991px) {
  .content-number .image-hover {
    position: relative;
    opacity: 1;
    transform: none;
    margin-top: 40px;
  }
}
.content-number .image-hover img {
  object-fit: cover;
  height: 200px;
  width: 100%;
  padding: 0px 5px;
}
@media only screen and (max-width: 1199px) {
  .content-number .image-hover img {
    padding: 0px;
  }
}
.content-number .image-hover .img-2 {
  position: absolute;
  bottom: 0;
}
@media only screen and (max-width: 991px) {
  .content-number .image-hover .img-2 {
    position: relative;
  }
}
.content-number .diagonal-fix {
  background: var(--color-dark-1);
  height: 100%;
  width: calc(1 * (-100 * 1px));
  position: absolute;
  right: 0;
  top: 1px;
  clip-path: polygon(100% 0, 0 100%, 100% 100%);
}
@media screen and (min-width: 320px) {
  .content-number .diagonal-fix {
    width: calc(1 * (-100 * 1px + (300 - -100) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .content-number .diagonal-fix {
    width: calc(1 * (300 * 1px));
  }
}
@media only screen and (max-width: 991px) {
  .content-number .diagonal-fix {
    display: none;
  }
}
.content-number .text-inside {
  margin-bottom: 30px;
  padding-left: calc(1 * (0 * 1px));
  font-weight: 600;
  max-width: 87%;
}
@media screen and (min-width: 320px) {
  .content-number .text-inside {
    padding-left: calc(1 * (0 * 1px + (20 - 0) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .content-number .text-inside {
    padding-left: calc(1 * (20 * 1px));
  }
}
@media only screen and (max-width: 991px) {
  .content-number .text-inside {
    color: var(--color-light-1) !important;
    padding-left: 0px;
    max-width: 100%;
  }
}

.section-linea-tiempo {
  padding-top: 20px;
}
.section-linea-tiempo .section-fullheight__inner_mobile {
  height: 900px;
}
@media only screen and (max-width: 767px) {
  .section-linea-tiempo .section-fullheight__inner_mobile {
    height: 800px;
  }
}
.section-linea-tiempo .menu-scroll {
  position: absolute;
  top: 0px;
}
.section-linea-tiempo .section__heading {
  position: relative;
  left: 40px;
}
.section-linea-tiempo .slider__heading strong, .section-linea-tiempo .slider__heading b {
  color: var(--color-orange) !important;
}
.section-linea-tiempo .slider__heading.h5, .section-linea-tiempo .blog-internal .editor .col-12 > p.slider__heading:nth-of-type(2):not(blockquote p), .blog-internal .editor .section-linea-tiempo .col-12 > p.slider__heading:nth-of-type(2):not(blockquote p), .section-linea-tiempo .content-number .slider__heading.text-inside, .content-number .section-linea-tiempo .slider__heading.text-inside {
  max-width: 300px;
}
.section-linea-tiempo .slider__heading.h5 p, .section-linea-tiempo .blog-internal .editor .col-12 > p.slider__heading:nth-of-type(2):not(blockquote p) p, .blog-internal .editor .section-linea-tiempo .col-12 > p.slider__heading:nth-of-type(2):not(blockquote p) p, .section-linea-tiempo .content-number .slider__heading.text-inside p, .content-number .section-linea-tiempo .slider__heading.text-inside p, .section-linea-tiempo .slider__heading.h5 a, .section-linea-tiempo .blog-internal .editor .col-12 > p.slider__heading:nth-of-type(2):not(blockquote p) a, .blog-internal .editor .section-linea-tiempo .col-12 > p.slider__heading:nth-of-type(2):not(blockquote p) a, .section-linea-tiempo .content-number .slider__heading.text-inside a, .content-number .section-linea-tiempo .slider__heading.text-inside a {
  font-size: 26px;
  margin: 0px 0px 4px 0px;
  font-weight: 600;
  color: var(--color-orange);
  line-height: 1.1;
}
.section-linea-tiempo .slider__heading.h5 a, .section-linea-tiempo .blog-internal .editor .col-12 > p.slider__heading:nth-of-type(2):not(blockquote p) a, .blog-internal .editor .section-linea-tiempo .col-12 > p.slider__heading:nth-of-type(2):not(blockquote p) a, .section-linea-tiempo .content-number .slider__heading.text-inside a, .content-number .section-linea-tiempo .slider__heading.text-inside a {
  color: #000 !important;
}
.section-linea-tiempo .bandera {
  margin-bottom: 6px;
}
.section-linea-tiempo .slider-projects__wrapper-image img {
  filter: grayscale(100%);
}
.section-linea-tiempo .slider-projects__wrapper-image {
  max-width: 400px;
}
.section-linea-tiempo .ano {
  position: absolute;
  left: -200px;
  z-index: 1;
}
.section-linea-tiempo .ano .numero {
  transform: rotate(270deg);
  font-size: 160px;
  color: var(--color-orange);
  transition: all 1s ease;
  font-weight: 600;
}
@media only screen and (max-width: 576px) {
  .section-linea-tiempo .ano .numero {
    font-size: 100px;
  }
}
.section-linea-tiempo .d-flex-bottom .ano {
  bottom: 110px !important;
}
@media only screen and (max-width: 767px) {
  .section-linea-tiempo .d-flex-bottom .ano {
    top: 0px !important;
    bottom: auto !important;
  }
}
.section-linea-tiempo .d-flex-top .ano {
  top: 120px !important;
}
@media only screen and (max-width: 767px) {
  .section-linea-tiempo .d-flex-top .ano {
    top: 0px !important;
  }
}
.section-linea-tiempo .d-flex-center .ano {
  top: 80px !important;
}
@media only screen and (max-width: 767px) {
  .section-linea-tiempo .d-flex-center .ano {
    top: 0px !important;
  }
}
.section-linea-tiempo .swiper-slide-active .ano {
  left: -220px;
}
@media only screen and (max-width: 767px) {
  .section-linea-tiempo .swiper-slide-active .ano {
    left: -50px;
  }
}
.section-linea-tiempo .swiper-slide-active .ano .numero {
  color: var(--color-orange);
  transition: all 1s ease;
}
.section-linea-tiempo .swiper-slide-active .slider-projects__wrapper-image img {
  filter: grayscale(0%);
}
.section-linea-tiempo .swiper-slide {
  padding: 180px 0px 140px 0px;
}
.section-linea-tiempo .cuadrada {
  max-width: 280px !important;
}
@media only screen and (max-width: 767px) {
  .section-linea-tiempo .cuadrada {
    max-width: 240px !important;
  }
}
.section-linea-tiempo .horizontal {
  max-width: 400px !important;
}
@media only screen and (max-width: 767px) {
  .section-linea-tiempo .horizontal {
    max-width: 300px !important;
  }
}
.section-linea-tiempo .vertical {
  max-width: 240px !important;
}
@media only screen and (max-width: 767px) {
  .section-linea-tiempo .vertical {
    max-width: 220px !important;
  }
}
.section-linea-tiempo .fix-center {
  position: relative;
  left: 10vw;
  display: flex;
  padding-left: 30px;
  padding-right: 20px;
}
@media only screen and (max-width: 767px) {
  .section-linea-tiempo .fix-center {
    display: block;
    left: 0px;
  }
}
.section-linea-tiempo .section-fullheight__header {
  width: 800px !important;
  height: 10px;
  z-index: 10;
}
.section-linea-tiempo .section-fullheight__header .arts-split-text__line {
  padding-bottom: 2px;
}
.section-linea-tiempo .section-fullheight__header .menu-scroll {
  padding-top: 90px;
  padding-left: 0px;
}
.section-linea-tiempo .section-fullheight__header .section__heading {
  padding-top: 160px;
}
.section-linea-tiempo .section-fullheight__header .menu-scroll ul {
  margin: 0px !important;
  padding: 0px !important;
}
.section-linea-tiempo .section-fullheight__header .menu-scroll ul li:after {
  display: none;
}
.section-linea-tiempo .section-fullheight__header .menu-scroll ul span {
  color: rgba(0, 0, 0, 0.6) !important;
  position: relative;
  left: 12px;
}

.section-equipo > div {
  padding: 100px 20px;
}
.section-equipo > div:nth-child(4n+1) {
  background: var(--color-rose);
}
.section-equipo > div:nth-child(4n+2) {
  background: var(--color-blue);
}
.section-equipo > div:nth-child(4n+3) {
  background: var(--color-blue);
}
.section-equipo > div:nth-child(4n) {
  background: var(--color-rose);
}
@media only screen and (max-width: 767px) {
  .section-equipo > div:nth-child(odd) {
    background: var(--color-rose);
  }
  .section-equipo > div:nth-child(even) {
    background: var(--color-blue);
  }
}

.menu-fix {
  position: absolute;
  top: -80px;
}

.sub {
  position: relative;
}
.sub::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  height: 100%;
  background-image: url(../img/general/sub.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center bottom;
  opacity: 1;
}

.sub-svg em, .sub-svg a {
  font-style: normal !important;
}

.section-linea-tiempo .sin-imagen .slider-projects__header.text {
  position: relative;
  left: 50px;
  top: 30px;
}

@media only screen and (max-width: 576px) {
  .section-apoyamos > .section-content {
    padding: 0px 15px;
  }
}

.diagonal {
  position: relative;
  height: calc(1 * (80 * 1px));
  width: calc(1 * (80 * 1px));
  clip-path: polygon(0 0, 100% 0, 0 100%);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px 0 0 10px;
  font-size: 24px;
  font-weight: bold;
  color: #000;
}
@media screen and (min-width: 320px) {
  .diagonal {
    height: calc(1 * (80 * 1px + (120 - 80) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .diagonal {
    height: calc(1 * (120 * 1px));
  }
}
@media screen and (min-width: 320px) {
  .diagonal {
    width: calc(1 * (80 * 1px + (120 - 80) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .diagonal {
    width: calc(1 * (120 * 1px));
  }
}

.title-top {
  position: absolute;
  top: 70px;
  left: calc(1 * (10 * 1px));
}
@media screen and (min-width: 320px) {
  .title-top {
    left: calc(1 * (10 * 1px + (80 - 10) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .title-top {
    left: calc(1 * (80 * 1px));
  }
}

.scroll-line {
  position: absolute;
  bottom: 20px;
  gap: calc(1 * (10 * 1px));
  left: calc(1 * (10 * 1px));
}
@media screen and (min-width: 320px) {
  .scroll-line {
    gap: calc(1 * (10 * 1px + (20 - 10) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .scroll-line {
    gap: calc(1 * (20 * 1px));
  }
}
@media screen and (min-width: 320px) {
  .scroll-line {
    left: calc(1 * (10 * 1px + (80 - 10) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .scroll-line {
    left: calc(1 * (80 * 1px));
  }
}
.scroll-line svg {
  width: calc(1 * (30 * 1px));
  position: relative;
  top: 4px;
}
@media screen and (min-width: 320px) {
  .scroll-line svg {
    width: calc(1 * (30 * 1px + (40 - 30) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .scroll-line svg {
    width: calc(1 * (40 * 1px));
  }
}

/* PROYECTOS  */	
.section-slider-ruta {
  padding-left: 120px !important;
  position: relative;
}
@media only screen and (max-width: 1500px) {
  .section-slider-ruta {
    padding-left: 100px !important;
  }
}
@media only screen and (max-width: 576px) {
  .section-slider-ruta {
    padding-left: 90px !important;
  }
}
.section-slider-ruta .section__headline.line-full {
  position: absolute;
}
.section-slider-ruta .section__headline.bottom {
  bottom: 1px;
}
.section-slider-ruta .section__headline.horizontal {
  position: absolute;
  right: 0px;
}
.section-slider-ruta .section__headline.horizontal.left {
  position: absolute;
  left: 0px;
  right: auto;
}
.section-slider-ruta .section__headline.top {
  top: 50%;
  position: absolute;
}
.section-slider-ruta .swiper-slide {
  width: auto;
}
.section-slider-ruta .swiper-slide:last-child {
  padding-right: 200px;
}
@media only screen and (max-width: 576px) {
  .section-slider-ruta .swiper-slide .section-content__content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .section-slider-ruta .swiper-slide .section-content__content h5 {
    margin-top: 0px !important;
  }
}
.section-slider-ruta .swiper-slide .box-content {
  text-align: left;
  height: 340px;
  border-right: none;
  position: relative;
  padding-left: 0px;
}
.section-slider-ruta .swiper-slide .box-content.left {
  left: 140px;
  position: relative;
  z-index: 999;
}
@media only screen and (max-width: 576px) {
  .section-slider-ruta .swiper-slide .box-content.left {
    left: 0px;
  }
}
.section-slider-ruta .swiper-slide .box-content .box_inside {
  padding-left: 40px;
  padding-right: 40px;
  width: 500px;
  position: relative;
}
@media only screen and (max-width: 576px) {
  .section-slider-ruta .swiper-slide .box-content .box_inside {
    width: 340px;
    padding-left: 25px;
    padding-right: 25px;
  }
}
.section-slider-ruta .swiper-slide .box-content .box_inside h5 {
  margin-top: 40px;
}
.section-slider-ruta .swiper-slide .box-content .box_inside:after {
  z-index: -1;
  position: absolute;
  left: 0;
  top: 0%;
  content: "";
  width: 0%;
  height: 100%;
  transition: all 0.5s ease;
}
.section-slider-ruta .swiper-slide .box-content .box_inside.rosa:after {
  background-color: var(--color-rose);
}
.section-slider-ruta .swiper-slide .box-content .box_inside.naranjo:after {
  background-color: var(--color-orange);
}
.section-slider-ruta .swiper-slide .box-content .box_inside.azul:after {
  background-color: var(--color-blue);
}
.section-slider-ruta .swiper-slide .box-content .box_inside:hover:after {
  width: 100%;
}
.section-slider-ruta .swiper-slide .box-content .box_inside h5 {
  font-weight: 600;
}
.section-slider-ruta .swiper-slide .first_1 {
  border-left: 0px;
}
.section-slider-ruta .swiper-slide .first_1 .box_inside {
  left: 100px;
  margin-right: 100px;
}
@media only screen and (max-width: 991px) {
  .section-slider-ruta .swiper-slide .first_1 .box_inside {
    left: 160px;
    margin-right: 160px;
  }
}
@media only screen and (max-width: 576px) {
  .section-slider-ruta .swiper-slide .first_1 .box_inside {
    left: 0px;
    margin-right: 0px;
  }
}
@media only screen and (max-width: 576px) {
  .section-slider-ruta .swiper-slide .first_1 .box_inside .horizontal.left {
    display: none;
  }
}
.section-slider-ruta .swiper-slide .first_2 {
  border-left: 0px;
}
.section-slider-ruta .swiper-slide .first_2 .box_inside {
  left: 240px;
  margin-right: 0px;
}
@media only screen and (max-width: 576px) {
  .section-slider-ruta .swiper-slide .first_2 .box_inside {
    left: 0px;
  }
}
@media only screen and (max-width: 576px) {
  .section-slider-ruta .swiper-slide .first_2 .box_inside .horizontal.left {
    display: none;
  }
}
.section-slider-ruta.proceso .swiper-slide {
  width: auto;
}
.section-slider-ruta.proceso .swiper-slide:last-child {
  padding-right: 60px !important;
}
.section-slider-ruta.proceso .box-content {
  text-align: left;
  min-height: 600px;
  border-right: none;
  position: relative;
  padding-left: 0px;
}
.section-slider-ruta.proceso .box-content .box_inside {
  padding-left: 30px;
  padding-right: 30px;
  width: 550px;
  position: relative;
}
@media only screen and (max-width: 1199px) {
  .section-slider-ruta.proceso .box-content .box_inside {
    width: 500px;
  }
}
@media only screen and (max-width: 991px) {
  .section-slider-ruta.proceso .box-content .box_inside {
    width: 450px;
  }
}
@media only screen and (max-width: 767px) {
  .section-slider-ruta.proceso .box-content .box_inside {
    width: 400px;
  }
}
@media only screen and (max-width: 576px) {
  .section-slider-ruta.proceso .box-content .box_inside {
    width: 340px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
.section-slider-ruta.proceso .box-content .box_inside h5 {
  margin-top: 0px;
}
.section-slider-ruta.proceso .box-content .box_inside:after {
  z-index: -1;
  position: absolute;
  left: 0;
  top: 0%;
  content: "";
  width: 0%;
  height: 100%;
  background-color: var(--color-rose);
  transition: all 0.5s ease;
}
.section-slider-ruta.proceso .box-content .box_inside.rose:after {
  background: var(--color-rose);
}
.section-slider-ruta.proceso .box-content .box_inside.orange:after {
  background: var(--color-orange);
}
.section-slider-ruta.proceso .box-content .box_inside.blue:after {
  background: var(--color-blue);
}
.section-slider-ruta.proceso .box-content .box_inside:hover:after {
  width: 100%;
}
.section-slider-ruta.proceso .box-content .box_inside:hover .color-rose {
  color: #fff !important;
}
.section-slider-ruta.proceso .box-content .box_inside h5 {
  font-weight: 600;
}
.section-slider-ruta.proceso .box-content .box_inside .title {
  margin-top: 40px;
}
@media only screen and (max-width: 576px) {
  .section-slider-ruta.proceso .box-content .box_inside .title {
    margin-top: 30px;
  }
}
.section-slider-ruta.proceso .first_1 {
  border-left: 0px;
}
.section-slider-ruta.proceso .first_1 .box_inside {
  left: 160px;
  margin-right: 160px;
}
@media only screen and (max-width: 576px) {
  .section-slider-ruta.proceso .first_1 .box_inside {
    left: 0px;
    margin-right: 0px;
  }
}

#tab2 h5 {
  max-width: 300px;
  padding-right: 20px;
}
@media only screen and (max-width: 576px) {
  #tab2 h5 {
    margin-bottom: 15px !important;
  }
}
@media only screen and (max-width: 576px) {
  #tab2 h5 br {
    display: none;
  }
}
#tab2 h6 {
  font-size: 19px;
}
@media only screen and (max-width: 576px) {
  #tab2 h6 {
    font-size: 17px;
  }
}
@media only screen and (max-width: 576px) {
  #tab2 p {
    font-size: 17px;
  }
}

.search-form ::placeholder {
  color: #000;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
}
@media only screen and (max-width: 991px) {
  .search-form ::placeholder {
    font-size: 16px;
  }
}
.search-form label input {
  width: 300px;
  border: none;
  border-bottom: 1px solid var(--color-dark-1);
  padding: 6px 0px;
  font-weight: 400;
  background: transparent;
}
.search-form .search-submit {
  width: 50px;
  height: 50px;
  background: transparent;
  border: none;
  cursor: pointer;
  background-image: url("data:image/svg+xml,%3Csvg width='50' height='50' viewBox='0 0 50 50' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='30' cy='20' r='10' stroke='black' stroke-width='2' fill='none'/%3E%3Cline x1='23' y1='27' x2='15' y2='35' stroke='black' stroke-width='2'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  font-size: 0px;
}

.header[data-arts-theme-text=light] .section__headline {
  background: #fff;
}

/* SLIDER PRINCIPIAL */	
.main-slider[data-arts-theme-text=light] .slider-projects-fullscreen__images {
  background: #000 !important;
  transition: background-color 0.4s ease;
}
.main-slider[data-arts-theme-text=light] h1, .main-slider[data-arts-theme-text=light] p, .main-slider[data-arts-theme-text=light] .button, .main-slider[data-arts-theme-text=light] span {
  filter: invert(0%);
}
.main-slider[data-arts-theme-text=light] span {
  color: #fff;
}
.main-slider .section-fullheight__inner_mobile {
  min-height: 800px;
  height: 100vh;
}
@media only screen and (max-width: 991px) {
  .main-slider .section-fullheight__inner_mobile {
    height: 100vh;
    min-height: 600px;
  }
}
.main-slider .slider-projects-fullscreen__images {
  transition: background-color 0.4s ease;
  background: #fff;
}
.main-slider .content_slider .slider__wrapper-button .button__title {
  font-size: calc(1 * (18 * 1px));
}
@media screen and (min-width: 320px) {
  .main-slider .content_slider .slider__wrapper-button .button__title {
    font-size: calc(1 * (18 * 1px + (20 - 18) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .main-slider .content_slider .slider__wrapper-button .button__title {
    font-size: calc(1 * (20 * 1px));
  }
}
.main-slider .style1 .content_slider {
  margin-left: calc(1 * (0 * 1px));
  position: relative;
  top: 100px;
  max-width: 700px;
}
@media screen and (min-width: 320px) {
  .main-slider .style1 .content_slider {
    margin-left: calc(1 * (0 * 1px + (150 - 0) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .main-slider .style1 .content_slider {
    margin-left: calc(1 * (150 * 1px));
  }
}
.main-slider .style2 .content_slider {
  max-width: 740px;
  margin: 0 auto;
  top: 100px;
  position: relative;
}
.main-slider .style2 .slider__wrapper-button {
  display: flex;
  justify-content: end;
  align-items: end;
}
@media only screen and (max-width: 576px) {
  .main-slider .style2 .slider__wrapper-button {
    justify-content: start;
  }
}
.main-slider .style2 p {
  padding-left: calc(1 * (0 * 1px));
  padding-right: calc(1 * (0 * 1px));
  margin-top: 30px;
}
@media screen and (min-width: 320px) {
  .main-slider .style2 p {
    padding-left: calc(1 * (0 * 1px + (100 - 0) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .main-slider .style2 p {
    padding-left: calc(1 * (100 * 1px));
  }
}
@media screen and (min-width: 320px) {
  .main-slider .style2 p {
    padding-right: calc(1 * (0 * 1px + (100 - 0) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .main-slider .style2 p {
    padding-right: calc(1 * (100 * 1px));
  }
}
.main-slider .style3 .content_slider {
  max-width: 860px;
  margin: 0 auto;
  top: 100px;
  position: relative;
}
.main-slider .style3 .content_slider .fix {
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 991px) {
  .main-slider .style3 .content_slider .fix {
    flex-direction: column;
  }
}
.main-slider .style3 .content_slider .fix h1 {
  max-width: 60%;
}
@media only screen and (max-width: 991px) {
  .main-slider .style3 .content_slider .fix h1 {
    max-width: 100%;
  }
}
.main-slider .style3 .content_slider .fix p {
  max-width: 40%;
  margin-top: 80px;
}
@media only screen and (max-width: 991px) {
  .main-slider .style3 .content_slider .fix p {
    max-width: 100%;
    margin-top: 20px;
  }
}
.main-slider .style3 .slider__wrapper-button {
  display: flex;
  justify-content: end;
  align-items: end;
}
@media only screen and (max-width: 576px) {
  .main-slider .style3 .slider__wrapper-button {
    justify-content: start;
  }
}

/* SLIDER BLOG */
.box-blog p {
  font-size: 18px;
}
.box-blog h4 {
  margin-bottom: 10px;
}
.box-blog.style-2 {
  padding: 40px 30px;
}
.box-blog.style-2 h4 {
  margin-top: 0px;
}
.box-blog.style-3 {
  padding: 0px 30px;
  position: relative;
}
.box-blog.style-4 {
  padding: 0px;
}
.box-blog.style-4 .flex-1 {
  min-height: 300px;
  height: 50%;
}
.box-blog.style-4 .flex-2 {
  min-height: 300px;
  height: 50%;
}
.box-blog .overlay {
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  z-index: 1;
}
.box-blog.bg-img {
  z-index: 1;
}
.box-blog.bg-img::before {
  content: "";
  opacity: 0.5;
  background: #000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1;
}
.box-blog.bg-img .date {
  background: var(--color-rose);
}
.box-blog.bg-img-2 {
  padding: 0px;
}
.box-blog.bg-img-2 a {
  height: 600px;
}
@media only screen and (max-width: 576px) {
  .box-blog.bg-img-2 a {
    height: 540px;
  }
}
.box-blog.bg-img-2 img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.box-blog .date {
  background: #000;
}
.box-blog .date.position-absolute {
  left: 20px;
  top: 20px;
}
.box-blog.bg-opacity .title {
  height: 100%;
}
.box-blog .categorie {
  color: var(--color-dark-1);
  font-size: 19px;
  font-size: calc(1 * (16 * 1px));
  font-weight: 600;
}
@media screen and (min-width: 320px) {
  .box-blog .categorie {
    font-size: calc(1 * (16 * 1px + (18 - 16) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .box-blog .categorie {
    font-size: calc(1 * (18 * 1px));
  }
}
.box-blog .arrow-in {
  width: 80px;
}
.box-blog .arrow-in svg {
  transition: all 0.4s ease;
}
.box-blog:hover .arrow-in svg {
  transition: all 0.4s ease;
  transform: rotate(-140deg);
}
.box-blog:hover .cls-1 {
  fill: #fff !important;
}

.tags a {
  display: table;
  line-height: 2;
  font-size: 13px !important;
  padding: 0px 4px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: 4px;
}

.section-blog-slider .swiper-slide .box {
  min-height: 600px;
  padding: 20px;
  margin-right: 0px;
  margin-top: 50px;
  margin-bottom: 50px;
  position: relative;
}
@media only screen and (max-width: 1199px) {
  .section-blog-slider .swiper-slide .box {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
@media only screen and (max-width: 576px) {
  .section-blog-slider .swiper-slide .box {
    margin-top: 30px;
    margin-bottom: 30px;
    min-height: 540px;
  }
}
.section-blog-slider .swiper-slide .box h5 a, .section-blog-slider .swiper-slide .box p a {
  text-decoration: underline;
}
.section-blog-slider .swiper-slide .box.style-2 {
  padding: 40px 40px 0px 40px;
}
.section-blog-slider .swiper-slide .box.style-2 h4 {
  margin-top: 0px;
}
.section-blog-slider .swiper-slide .box.style-2 .button {
  position: absolute;
  bottom: 0;
  left: 0;
}
.section-blog-slider .swiper-slide .box.style-3 {
  padding: 0px 30px;
  position: relative;
}
.section-blog-slider .swiper-slide .box.style-4 {
  padding: 0px;
}
.section-blog-slider .swiper-slide .box.style-4 .flex-1 {
  min-height: 300px;
  height: 50%;
}
.section-blog-slider .swiper-slide .box.style-4 .flex-2 {
  min-height: 300px;
  height: 50%;
  padding: 30px 30px 0px 30px;
}
.section-blog-slider .swiper-slide .box .overlay {
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  z-index: 1;
}
.section-blog-slider .swiper-slide .box.bg-img .date {
  background: var(--color-rose);
}
.section-blog-slider .swiper-slide .box.bg-img-2 {
  background: #F7F6F1;
  padding: 30px;
}
.section-blog-slider .swiper-slide .box.bg-img-2 .date {
  display: none;
}
.section-blog-slider .swiper-slide .box.bg-rose .date {
  background: #000;
}
.section-blog-slider .swiper-slide .box.bg-opacity .title {
  height: 100%;
}
.section-blog-slider .swiper-slide .box .date {
  margin-top: -20px;
  margin-left: -20px;
  padding: 12px 10px;
  font-size: 18px;
  line-height: 1.2;
  text-align: center;
  font-weight: 500;
}
.section-blog-slider .swiper-slide .box .categorie {
  font-size: 19px;
}
.section-blog-slider .swiper-slide .box .tags > div {
  display: block;
  line-height: 2;
}
.section-blog-slider .swiper-slide .box .tags > div a {
  border: 1px solid #fff;
  font-size: 13px;
  padding: 2px 4px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
}
.section-blog-slider .swiper-slide .box .arrow-in {
  width: 80px;
}
.section-blog-slider .swiper-slide .box .arrow-in svg {
  transition: all 0.4s ease;
}
.section-blog-slider .swiper-slide .box:hover .arrow-in svg {
  transition: all 0.4s ease;
  transform: rotate(-140deg);
}
.section-blog-slider .swiper-slide .box:hover .arrow-in .cls-1 {
  fill: #fff !important;
}

.section_slider__blog .fix-40 {
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 40px;
  padding-right: 60px;
}
@media only screen and (max-width: 1500px) {
  .section_slider__blog .fix-40 {
    padding-right: 30px;
  }
}
@media only screen and (max-width: 1360px) {
  .section_slider__blog .fix-40 {
    padding-right: 0px;
  }
}
@media only screen and (max-width: 576px) {
  .section_slider__blog .fix-40 {
    padding-left: 30px;
    padding-right: 30px;
  }
}
.section_slider__blog .view-all {
  padding-top: 50px;
  text-align: left !important;
}

/* ECOSISTEMA */	
.main-slider.ecosistema .section-fullheight__inner_mobile {
  height: auto !important;
}
@media only screen and (max-width: 991px) {
  .main-slider.ecosistema .section-fullheight__inner_mobile {
    min-height: 740px;
  }
}
@media only screen and (max-width: 767px) {
  .main-slider.ecosistema .section-fullheight__inner_mobile {
    min-height: 660px;
  }
}
.main-slider.ecosistema .section-fullheight__inner_mobile .circle {
  position: relative;
  right: -300px;
  top: 60px;
}
.main-slider.ecosistema .section-fullheight__inner_mobile .circle {
  max-width: 360px;
  max-height: 600px;
  overflow: hidden;
  margin: auto;
  border-radius: 200px;
  margin: 0 60px 0 0;
}
@media only screen and (max-width: 1360px) {
  .main-slider.ecosistema .section-fullheight__inner_mobile .circle {
    max-width: 320px;
    max-height: 560px;
    top: 80px;
    margin: 0 50px 0 0;
  }
}
@media only screen and (max-width: 1199px) {
  .main-slider.ecosistema .section-fullheight__inner_mobile .circle {
    max-width: 320px;
    max-height: 520px;
    top: 100px;
    margin: 0 40px 0 0;
  }
}
@media only screen and (max-width: 991px) {
  .main-slider.ecosistema .section-fullheight__inner_mobile .circle {
    max-width: 300px;
    max-height: 500px;
    margin: 0 30px 0 0;
    right: -200px;
    top: 140px;
  }
}
@media only screen and (max-width: 767px) {
  .main-slider.ecosistema .section-fullheight__inner_mobile .circle {
    right: 0;
    margin: auto;
    top: auto;
  }
}
@media only screen and (max-width: 991px) {
  .main-slider.ecosistema .section-fullheight__inner_mobile .circle:last-child {
    display: none !important;
  }
}
@media only screen and (max-width: 767px) {
  .main-slider.ecosistema .section-fullheight__inner_mobile .circle:first-child {
    display: none !important;
  }
}
.main-slider.ecosistema .content_slider {
  max-width: 460px;
  top: 30px;
  position: relative;
  margin-left: 80px;
}
@media only screen and (max-width: 767px) {
  .main-slider.ecosistema .content_slider {
    margin: 0 auto;
  }
}

/* NOSOTROS */	
.main-slider.nosotros {
  margin: 0px;
  border-bottom: 1px solid #000 !important;
}
.main-slider.nosotros .section-fullheight__inner_mobile {
  min-height: 800px;
  height: 100vh;
}
@media only screen and (max-width: 991px) {
  .main-slider.nosotros .section-fullheight__inner_mobile {
    height: 800px;
    min-height: 800px;
  }
}
.main-slider.nosotros .circle {
  max-width: 720px;
  max-height: 420px;
  overflow: hidden;
  margin: 0 auto;
  border-radius: 200px;
  top: 100px;
  position: relative;
}
@media only screen and (max-width: 1199px) {
  .main-slider.nosotros .circle {
    max-width: 680px;
    max-height: 400px;
  }
}
@media only screen and (max-width: 767px) {
  .main-slider.nosotros .circle {
    margin-left: 30px;
    margin-right: 30px;
  }
}
@media only screen and (max-width: 576px) {
  .main-slider.nosotros .circle {
    max-width: 340px;
    max-height: 560px;
    margin: auto;
    top: 100px;
  }
}
.main-slider.nosotros .content_slider {
  margin-left: 40px;
  position: relative;
  top: 100px;
  max-width: 700px;
}
@media only screen and (max-width: 1199px) {
  .main-slider.nosotros .content_slider {
    max-width: 660px;
  }
}
@media only screen and (max-width: 767px) {
  .main-slider.nosotros .content_slider {
    margin-left: 10px;
  }
}
@media only screen and (max-width: 576px) {
  .main-slider.nosotros .content_slider {
    margin-left: 0px;
    text-align: center;
  }
}

/* PROYECTOS*/	
@media (max-width: 500px) {
  .main-slider.proyectos .section-fullheight__inner_mobile {
    height: 880px;
    min-height: 880px;
  }
}
.main-slider.proyectos .section-fullheight__inner_mobile .circle {
  position: absolute;
  right: 0;
  left: 0;
  margin: 0 auto;
  margin-left: 80px;
}
.main-slider.proyectos .section-fullheight__inner_mobile .circle {
  max-width: 660px;
  max-height: 660px;
  overflow: hidden;
  margin: 0 auto;
  border-radius: 50%;
}
@media only screen and (max-width: 1500px) {
  .main-slider.proyectos .section-fullheight__inner_mobile .circle {
    max-width: 600px;
    max-height: 600px;
  }
}
@media only screen and (max-width: 1360px) {
  .main-slider.proyectos .section-fullheight__inner_mobile .circle {
    max-width: 580px;
    max-height: 580px;
  }
}
@media only screen and (max-width: 1199px) {
  .main-slider.proyectos .section-fullheight__inner_mobile .circle {
    max-width: 540px;
    max-height: 540px;
  }
}
@media only screen and (max-width: 991px) {
  .main-slider.proyectos .section-fullheight__inner_mobile .circle {
    max-width: 500px;
    max-height: 500px;
    margin-top: 160px;
  }
}
@media only screen and (max-width: 767px) {
  .main-slider.proyectos .section-fullheight__inner_mobile .circle {
    max-width: 400px;
    max-height: 400px;
    top: 300px;
    margin-top: 0px;
  }
}
@media only screen and (max-width: 576px) {
  .main-slider.proyectos .section-fullheight__inner_mobile .circle {
    max-width: 340px;
    max-height: 340px;
  }
}
.main-slider.proyectos .section-fullheight__inner_mobile .circle-dos {
  margin-right: 0px;
  right: -330px;
}
@media only screen and (max-width: 1360px) {
  .main-slider.proyectos .section-fullheight__inner_mobile .circle-dos {
    right: -340px;
  }
}
@media only screen and (max-width: 1199px) {
  .main-slider.proyectos .section-fullheight__inner_mobile .circle-dos {
    right: -350px;
  }
}
@media only screen and (max-width: 991px) {
  .main-slider.proyectos .section-fullheight__inner_mobile .circle-dos {
    right: -400px;
  }
}
@media only screen and (max-width: 767px) {
  .main-slider.proyectos .section-fullheight__inner_mobile .circle-dos {
    right: 0px;
    left: 0px;
    top: -200px;
    margin: 0 auto;
  }
}
.main-slider.proyectos .content_slider {
  max-width: 640px;
  top: 80px;
  position: relative;
  margin-left: 80px;
}
@media only screen and (max-width: 576px) {
  .main-slider.proyectos .content_slider {
    margin-left: 0px;
  }
}

/* BLOG */		
.main-slider.blog .section-fullheight__inner_mobile {
  height: 700px !important;
}
@media only screen and (max-width: 991px) {
  .main-slider.blog .section-fullheight__inner_mobile {
    height: 500px !important;
    min-height: 500px !important;
  }
}
@media only screen and (max-width: 576px) {
  .main-slider.blog .section-fullheight__inner_mobile {
    height: 400px !important;
    min-height: 400px !important;
  }
}
.main-slider.blog .style__1 .circle {
  max-width: 70%;
  max-height: 100%;
  overflow: hidden;
  margin: 0 0 0 auto;
  border-radius: 0px;
  top: 0px;
  position: relative;
}
@media only screen and (max-width: 991px) {
  .main-slider.blog .style__1 .circle {
    max-width: 100% !important;
  }
}
.main-slider.blog .style__1 .content_slider {
  position: relative;
  top: 0px;
  max-width: 700px;
  margin: auto 0;
  left: 100px;
}
@media only screen and (max-width: 1360px) {
  .main-slider.blog .style__1 .content_slider {
    left: 50px;
  }
}
@media only screen and (max-width: 1199px) {
  .main-slider.blog .style__1 .content_slider {
    left: 0px;
  }
}
.main-slider.blog .tags {
  position: absolute;
  bottom: 20px;
}
.main-slider.blog .tags ul {
  list-style: none;
}
.main-slider.blog .tags ul a {
  border: 1px solid var(--color-orange);
  color: var(--color-orange);
  font-size: 14px;
}

@media only screen and (max-width: 991px) {
  .main-slider.nosotros .hover-zoom__zoom, .main-slider.nosotros .hover-zoom__inner {
    filter: brightness(0.5);
  }
}

@media only screen and (max-width: 991px) {
  .main-slider.proyectos .hover-zoom__zoom, .main-slider.proyectos .hover-zoom__inner, .main-slider.ecosistema .hover-zoom__zoom, .main-slider.ecosistema .hover-zoom__inner {
    filter: opacity(0.7);
  }
}
.main-slider.proyectos .blend-mode, .main-slider.ecosistema .blend-mode {
  mix-blend-mode: normal;
}
.main-slider.proyectos h1, .main-slider.ecosistema h1 {
  filter: invert(0%);
}

#arrow_1 {
  width: 80px;
}
@media only screen and (max-width: 767px) {
  #arrow_1 {
    width: 70px;
  }
}
#arrow_1 .cls-1 {
  fill: none;
}
#arrow_1 .cls-2,
#arrow_1 .cls-3 {
  stroke: none;
}
#arrow_1 .cls-3 {
  fill: #fff;
}

.tabs {
  margin: 0px;
  padding: 0px;
  width: 100%;
  margin-bottom: 50px;
}
@media only screen and (max-width: 576px) {
  .tabs {
    margin-left: 60px;
  }
}
.tabs .tablinks {
  list-style: none;
  font-size: 18px;
  cursor: pointer;
  padding: 6px 15px;
  margin-right: 30px;
  font-weight: 500;
}
@media only screen and (max-width: 576px) {
  .tabs .tablinks {
    font-size: 16px;
  }
}
.tabs .tablinks.active {
  transition: all 1s ease;
  color: var(--color-rose);
  border: 1px solid var(--color-rose);
}

.tabs.style-2 {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  position: relative;
  overflow: visible;
  max-width: 900px;
  margin-bottom: 0px;
}
@media only screen and (max-width: 576px) {
  .tabs.style-2 {
    flex-direction: column;
    max-width: 100%;
    margin-left: 0;
  }
}
.tabs.style-2 .tablinks:first-child {
  padding-left: calc(1 * (20 * 1px));
}
@media screen and (min-width: 320px) {
  .tabs.style-2 .tablinks:first-child {
    padding-left: calc(1 * (20 * 1px + (200 - 20) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .tabs.style-2 .tablinks:first-child {
    padding-left: calc(1 * (200 * 1px));
  }
}
@media only screen and (max-width: 576px) {
  .tabs.style-2 .tablinks:first-child {
    padding-left: 30px;
  }
}
.tabs.style-2 .tablinks:last-child {
  text-align: center;
  width: calc(50% + 100px);
}
@media only screen and (max-width: 576px) {
  .tabs.style-2 .tablinks:last-child {
    width: 100%;
    text-align: left;
    padding-left: 30px;
    top: 0px;
  }
}
.tabs.style-2 .tablinks {
  list-style: none;
  font-weight: bold;
  cursor: pointer;
  padding: 25px 30px;
  margin: 0;
  background: var(--color-light-1);
  position: relative;
  width: 50%;
  text-align: left;
  transition: background 0.3s ease, color 0.3s ease;
  border: 1px solid var(--color-dark-1);
}
@media only screen and (max-width: 576px) {
  .tabs.style-2 .tablinks {
    width: 100%;
    padding: 20px 30px;
  }
}
.tabs.style-2 .tablinks:after {
  content: "";
  position: absolute;
  width: 120px;
  height: 100%;
  background: var(--color-light-1);
  top: 0;
  right: -80px;
  transform: skewX(40deg);
  z-index: 1;
  border-right: 1px solid var(--color-dark-1);
  border-bottom: 1px solid var(--color-dark-1);
  height: calc(100% + 1px);
  transition: background 0.3s ease;
}
@media only screen and (max-width: 576px) {
  .tabs.style-2 .tablinks:after {
    width: 60px;
    right: -30px;
    border-left: 1px solid var(--color-dark-1);
    top: -1px;
  }
}
.tabs.style-2 .tablinks:last-child {
  padding-left: 60px;
}
@media only screen and (max-width: 576px) {
  .tabs.style-2 .tablinks:last-child {
    padding-left: 30px;
  }
}
.tabs.style-2 .tablinks:last-child:after {
  width: 120px;
  right: -38.5px;
}
@media only screen and (max-width: 576px) {
  .tabs.style-2 .tablinks:last-child:after {
    width: 60px;
    right: -30px;
    border-left: 1px solid var(--color-dark-1);
  }
}
.tabs.style-2 .tablinks.active {
  background: var(--color-blue);
  color: white;
}
.tabs.style-2 .tablinks.active:after {
  background: var(--color-blue);
}
@media only screen and (max-width: 576px) {
  .tabs.style-2 .tablinks.active:after {
    background: var(--color-light-1);
    top: -1px;
  }
}

.wrapper_tabcontent {
  z-index: 1;
  position: relative;
  opacity: 1;
  padding: 0;
  overflow: hidden;
  transition: all 0.4s ease;
  top: 0;
  text-align: left;
}

.tabcontent {
  display: none;
}
.tabcontent.active {
  display: block;
}

@keyframes ticker {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-49%);
  }
}
.border-ticker {
  position: absolute !important;
  z-index: 99;
  height: 1px;
  width: 100%;
}
.border-ticker .section__headline {
  width: 100%;
  margin: 0 auto;
  background: #000;
}

.ticker-wrap {
  position: relative;
  z-index: 99;
  width: 100%;
  overflow: hidden;
  padding-left: 0%;
  border-bottom: 1px solid var(--color-dark-1);
}
.ticker-wrap.slider-top.fix {
  position: relative !important;
}
.ticker-wrap.slider-top {
  margin-top: 115px;
  padding-bottom: 1px;
  position: absolute;
}
@media only screen and (max-width: 576px) {
  .ticker-wrap.slider-top {
    margin-top: 80px;
  }
}
.ticker-wrap.slider-top .ticker {
  margin-top: 15px;
  margin-bottom: 10px;
}
.ticker-wrap.news .ticker__item {
  margin-right: 30px;
  position: relative;
}
.ticker-wrap.news .ticker {
  -webkit-animation-duration: 12s;
  animation-duration: 12s;
  margin: 15px 0px 10px 0px;
}
.ticker-wrap.news .svg {
  width: 30px;
  position: relative;
  top: -10px;
}
.ticker-wrap.news .divider {
  font-size: 60px;
  line-height: 0.8;
}

.ticker {
  display: inline-block;
  white-space: nowrap;
  animation: ticker 30s linear infinite;
}
.ticker__item {
  display: inline-block;
  padding: 0px;
  font-size: 20px;
  color: #fff;
  margin: 0;
}

.tira-vertical {
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  border: solid 1px;
  width: 4rem;
}
.tira-vertical .texto {
  padding: 0.5rem;
  writing-mode: vertical-lr;
  transform: rotate(180deg) translateY(0);
  height: max-content;
  animation: anim1 8s infinite linear;
}

.flex-row {
  flex-direction: row !important;
}

@keyframes anim1 {
  from {
    transform: rotate(180deg) translateY(0);
  }
  to {
    transform: rotate(180deg) translateY(16.66666%);
  }
}
.kareoke {
  touch-action: none;
  pointer-events: none;
  padding: 0px 20px;
}
.kareoke h3 {
  color: grey !important;
  margin-bottom: 40px;
  font-weight: 500;
  line-height: 1.3;
}
.kareoke h3::selection, .kareoke span::selection, .kareoke br::selection {
  background: rgba(0, 0, 0, 0);
  color: white;
}
.kareoke::selection {
  background: rgba(0, 0, 0, 0);
  color: white;
}

.pantalla {
  user-select: none;
  height: 100%;
  width: 100%;
  background-color: transparent;
  position: absolute;
  top: 0;
}

.fondo-miniatura {
  position: absolute;
  right: 40px;
  opacity: 0;
  transform: translateX(0);
}
.fondo-miniatura:nth-child(1) {
  animation: moverX 20s linear infinite;
  top: 100px;
  right: 50vw;
  max-width: 260px;
}
@media only screen and (max-width: 991px) {
  .fondo-miniatura:nth-child(1) {
    max-width: 240px;
  }
}
@media only screen and (max-width: 767px) {
  .fondo-miniatura:nth-child(1) {
    max-width: 220px;
  }
}
@media only screen and (max-width: 576px) {
  .fondo-miniatura:nth-child(1) {
    max-width: 200px;
  }
}
.fondo-miniatura:nth-child(2) {
  animation: moverX 25s linear infinite;
  top: -40px;
  right: 10vw;
  max-width: 100px;
}
@media only screen and (max-width: 991px) {
  .fondo-miniatura:nth-child(2) {
    max-width: 80px;
  }
}
@media only screen and (max-width: 576px) {
  .fondo-miniatura:nth-child(2) {
    top: -60px;
  }
}
.fondo-miniatura:nth-child(3) {
  animation: moverX 22s linear infinite;
  right: 30vw;
  bottom: -40px;
  max-width: 160px;
}
@media only screen and (max-width: 991px) {
  .fondo-miniatura:nth-child(3) {
    max-width: 140px;
  }
}
@media only screen and (max-width: 991px) {
  .fondo-miniatura:nth-child(3) {
    max-width: 120px;
  }
}
@media only screen and (max-width: 576px) {
  .fondo-miniatura:nth-child(3) {
    bottom: -60px;
    max-width: 100px;
  }
}
.fondo-miniatura:nth-child(4) {
  animation: moverX 16s linear infinite;
  top: 50%;
  right: -5vw;
  max-width: 180px;
}
@media only screen and (max-width: 991px) {
  .fondo-miniatura:nth-child(4) {
    max-width: 160px;
  }
}
@media only screen and (max-width: 767px) {
  .fondo-miniatura:nth-child(4) {
    max-width: 140px;
  }
}
@media only screen and (max-width: 576px) {
  .fondo-miniatura:nth-child(4) {
    max-width: 120px;
  }
}

@keyframes moverX {
  20% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
    transform: translateX(-25vw);
  }
}
.section-texto-creatividad {
  border-top: 1px solid #000;
}

.modal {
  max-width: none;
  max-height: 100vh;
  display: none;
  flex-direction: column;
  position: absolute;
  z-index: 1;
  top: 0;
  width: 100vw;
  height: 100vh;
  border: none;
  transform: scale(0.5);
  opacity: 0;
  margin: 0;
  padding: 0px;
  box-sizing: border-box;
  background-color: var(--color-dark-1);
  /* HEADER */ 
  /* CONTENT  */ 
  /* FICHA */ 	
}
.modal .circle-header {
  right: auto;
  left: calc(1 * (50 * 1px));
  top: calc(1 * (-200 * 1px));
  z-index: 1;
}
@media screen and (min-width: 320px) {
  .modal .circle-header {
    left: calc(1 * (50 * 1px + (200 - 50) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .modal .circle-header {
    left: calc(1 * (200 * 1px));
  }
}
@media screen and (min-width: 320px) {
  .modal .circle-header {
    top: calc(1 * (-200 * 1px + (-300 - -200) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .modal .circle-header {
    top: calc(1 * (-300 * 1px));
  }
}
.modal .circle-header .circle {
  max-width: calc(1 * (400 * 1px));
  max-height: calc(1 * (400 * 1px));
  border-radius: 50%;
}
@media screen and (min-width: 320px) {
  .modal .circle-header .circle {
    max-width: calc(1 * (400 * 1px + (700 - 400) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .modal .circle-header .circle {
    max-width: calc(1 * (700 * 1px));
  }
}
@media screen and (min-width: 320px) {
  .modal .circle-header .circle {
    max-height: calc(1 * (400 * 1px + (700 - 400) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .modal .circle-header .circle {
    max-height: calc(1 * (700 * 1px));
  }
}
.modal header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding-left: var(--gutter-horizontal);
  padding-right: var(--gutter-horizontal);
  position: sticky;
  top: 0;
  z-index: 999;
  background: var(--color-dark-1);
}
.modal header.fix::after {
  width: calc(100% - 30px);
  margin-right: 0;
}
.modal header::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 1px;
  background: var(--color-light-1);
  z-index: 999;
}
.modal header .logo {
  padding: 10px 0px;
  width: calc(1 * (90 * 1px));
}
@media screen and (min-width: 320px) {
  .modal header .logo {
    width: calc(1 * (90 * 1px + (110 - 90) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .modal header .logo {
    width: calc(1 * (110 * 1px));
  }
}
.modal header .cerrar-modal {
  font-size: 18px;
  padding-left: 40px;
  border: none;
  border-left: solid 1px var(--color-light-1);
  cursor: pointer;
  font-size: 16px;
  background: transparent;
  color: var(--color-light-1);
}
.modal header .cerrar-modal span {
  font-size: 22px;
  position: relative;
  top: 0px;
  padding-left: 10px;
  color: var(--color-light-1);
}
.modal .container {
  padding: 80px 20px;
}
@media only screen and (max-width: 767px) {
  .modal .container {
    padding: 60px 20px;
  }
}
.modal .section-modal-content {
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  z-index: 9;
}
.modal .section-modal-content .content-full {
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  text-align: left;
  padding: 40px 20px;
  position: sticky;
  top: 0;
}
.modal .section-modal-content .content-full .h2, .modal .section-modal-content .content-full p {
  color: #fff !important;
}
.modal .ficha {
  padding: 60px 40px 60px 40px;
  background-color: var(--color-blue);
  text-align: left;
  color: var(--color-dark-1);
}
.modal .ficha .content-right:first-child {
  border-top: 1px solid rgba(255, 255, 255, 0.9);
  padding-top: 20px;
}
.modal .ficha .content-right {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.9);
}
.modal .ficha .content-right .h6, .modal .ficha .content-right .breadcrumbs, .modal .ficha .content-right .blog-internal .editor p, .blog-internal .editor .modal .ficha .content-right p, .modal .ficha .content-right .filtros .todos, .filtros .modal .ficha .content-right .todos, .modal .ficha .content-right .filtros option, .filtros .modal .ficha .content-right option {
  margin-top: 0px;
  font-size: calc(1 * (20 * 1px));
  font-weight: bold;
  min-height: calc(1 * (26 * 1px));
}
@media screen and (min-width: 320px) {
  .modal .ficha .content-right .h6, .modal .ficha .content-right .breadcrumbs, .modal .ficha .content-right .blog-internal .editor p, .blog-internal .editor .modal .ficha .content-right p, .modal .ficha .content-right .filtros .todos, .filtros .modal .ficha .content-right .todos, .modal .ficha .content-right .filtros option, .filtros .modal .ficha .content-right option {
    font-size: calc(1 * (20 * 1px + (26 - 20) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .modal .ficha .content-right .h6, .modal .ficha .content-right .breadcrumbs, .modal .ficha .content-right .blog-internal .editor p, .blog-internal .editor .modal .ficha .content-right p, .modal .ficha .content-right .filtros .todos, .filtros .modal .ficha .content-right .todos, .modal .ficha .content-right .filtros option, .filtros .modal .ficha .content-right option {
    font-size: calc(1 * (26 * 1px));
  }
}
@media screen and (min-width: 320px) {
  .modal .ficha .content-right .h6, .modal .ficha .content-right .breadcrumbs, .modal .ficha .content-right .blog-internal .editor p, .blog-internal .editor .modal .ficha .content-right p, .modal .ficha .content-right .filtros .todos, .filtros .modal .ficha .content-right .todos, .modal .ficha .content-right .filtros option, .filtros .modal .ficha .content-right option {
    min-height: calc(1 * (26 * 1px + (30 - 26) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .modal .ficha .content-right .h6, .modal .ficha .content-right .breadcrumbs, .modal .ficha .content-right .blog-internal .editor p, .blog-internal .editor .modal .ficha .content-right p, .modal .ficha .content-right .filtros .todos, .filtros .modal .ficha .content-right .todos, .modal .ficha .content-right .filtros option, .filtros .modal .ficha .content-right option {
    min-height: calc(1 * (30 * 1px));
  }
}
.modal .ficha .content-right p, .modal .ficha .content-right a {
  font-size: calc(1 * (14 * 1px));
  margin-top: 0;
  margin-bottom: 5px;
  font-weight: 600;
  display: block;
  line-height: 1.7;
}
@media screen and (min-width: 320px) {
  .modal .ficha .content-right p, .modal .ficha .content-right a {
    font-size: calc(1 * (14 * 1px + (16 - 14) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .modal .ficha .content-right p, .modal .ficha .content-right a {
    font-size: calc(1 * (16 * 1px));
  }
}
.modal .ficha .content-right a {
  text-decoration: underline;
}
.modal .ficha .content-right.editor a {
  display: inline;
  text-decoration: underline;
}
.modal .modal-wraper-contenido {
  position: relative;
  flex: 1;
  overflow: auto;
}
.modal .modal-wraper-contenido .modal-contenido {
  position: absolute;
  width: 100%;
  min-height: calc(100vh - 364px);
  display: flex;
  opacity: 0;
  transition: all 0.2s ease;
  transform: translateY(-100%);
  background-color: var(--color-dark-1);
}
.modal .modal-wraper-contenido .modal-contenido.show {
  transform: translateY(0);
  opacity: 1;
  position: relative;
  z-index: 9;
}
.modal .modal-wraper-contenido .modal-contenido .modal-cotenido-right p {
  font-size: calc(var(--h6-min-font-size) * 1px);
  margin-top: 0px;
  color: var(--color-light-1);
}
@media screen and (min-width: 320px) {
  .modal .modal-wraper-contenido .modal-contenido .modal-cotenido-right p {
    font-size: calc(var(--h6-min-font-size) * 1px + (var(--h6-max-font-size) - var(--h6-min-font-size)) * ((100vw - 320px) / 1600));
  }
}
@media screen and (min-width: 1920px) {
  .modal .modal-wraper-contenido .modal-contenido .modal-cotenido-right p {
    font-size: calc(var(--h6-max-font-size) * 1px);
  }
}
.modal .modal-wraper-contenido .modal-contenido .modal-cotenido-right a {
  color: var(--color-blue);
  font-weight: 500;
}
.modal .modal-wraper-contenido .modal-contenido .modal-cotenido-right .web {
  color: var(--color-blue);
  font-weight: 500;
  font-size: 20px;
  margin-top: 40px;
  display: inline-block;
}
@media only screen and (max-width: 991px) {
  .modal .modal-wraper-contenido .modal-contenido .modal-cotenido-right .web {
    font-size: 18px;
  }
}
.modal .modal-wraper-contenido .modal-contenido .modal-cotenido-left .hashtag {
  margin-top: 30px;
  font-size: 18px;
  color: var(--color-light-1);
}
.modal .modal-wraper-contenido .modal-contenido .modal-cotenido-left .h3 p, .modal .modal-wraper-contenido .modal-contenido .modal-cotenido-left .comments-title p, .modal .modal-wraper-contenido .modal-contenido .modal-cotenido-left .comment-reply-title p {
  font-size: calc(var(--h2-min-font-size) * 1px);
  line-height: 1;
  margin-top: 0px;
  margin-bottom: 10px;
  font-weight: 500;
  color: var(--color-light-1);
}
@media screen and (min-width: 320px) {
  .modal .modal-wraper-contenido .modal-contenido .modal-cotenido-left .h3 p, .modal .modal-wraper-contenido .modal-contenido .modal-cotenido-left .comments-title p, .modal .modal-wraper-contenido .modal-contenido .modal-cotenido-left .comment-reply-title p {
    font-size: calc(var(--h2-min-font-size) * 1px + (var(--h2-max-font-size) - var(--h2-min-font-size)) * ((100vw - 320px) / 1600));
  }
}
@media screen and (min-width: 1920px) {
  .modal .modal-wraper-contenido .modal-contenido .modal-cotenido-left .h3 p, .modal .modal-wraper-contenido .modal-contenido .modal-cotenido-left .comments-title p, .modal .modal-wraper-contenido .modal-contenido .modal-cotenido-left .comment-reply-title p {
    font-size: calc(var(--h2-max-font-size) * 1px);
  }
}
@media only screen and (max-width: 767px) {
  .modal .modal-wraper-contenido .modal-contenido .modal-cotenido-left .h3 p, .modal .modal-wraper-contenido .modal-contenido .modal-cotenido-left .comments-title p, .modal .modal-wraper-contenido .modal-contenido .modal-cotenido-left .comment-reply-title p {
    font-size: 36px;
  }
}
.modal .modal-wraper-contenido .modal-contenido .modal-cotenido-left .h3 strong, .modal .modal-wraper-contenido .modal-contenido .modal-cotenido-left .comments-title strong, .modal .modal-wraper-contenido .modal-contenido .modal-cotenido-left .comment-reply-title strong, .modal .modal-wraper-contenido .modal-contenido .modal-cotenido-left .h3 b, .modal .modal-wraper-contenido .modal-contenido .modal-cotenido-left .comments-title b, .modal .modal-wraper-contenido .modal-contenido .modal-cotenido-left .comment-reply-title b {
  color: var(--color-blue);
  margin-top: 10px;
  display: block;
}
.modal .modal-wraper-contenido .modal-contenido .modal-cotenido-left .text-orange {
  color: #f60;
}
.modal .modal-nav {
  box-sizing: border-box;
  display: flex;
  padding-top: 50px;
  width: 100%;
  background-color: var(--color-dark-1);
}
@media only screen and (max-width: 767px) {
  .modal .modal-nav {
    display: block;
  }
}
.modal .modal-nav button {
  width: 50%;
  height: 10rem;
  padding: 0px 60px;
  background: none;
  color: white;
  font-size: calc(1 * (18 * 1px));
  border: solid 1px rgba(255, 255, 255, 0.6);
  border-width: 1px 0;
  cursor: pointer;
  transition: 0.5s all ease;
}
@media screen and (min-width: 320px) {
  .modal .modal-nav button {
    font-size: calc(1 * (18 * 1px + (20 - 18) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .modal .modal-nav button {
    font-size: calc(1 * (20 * 1px));
  }
}
@media only screen and (max-width: 767px) {
  .modal .modal-nav button {
    width: 100%;
  }
}
.modal .modal-nav button.modal-nav-next {
  border-width: 1px 0 1px 1px;
}
@media only screen and (max-width: 767px) {
  .modal .modal-nav button.modal-nav-next {
    border-width: 1px 0 1px 0px;
  }
}
.modal .modal-nav button:hover {
  color: var(--color-blue);
}
.modal .d-grid {
  margin-bottom: 20px;
}
.modal .d-grid p {
  padding: 0;
  margin: 0;
  font-size: 16px;
  margin-bottom: 4px;
}
.modal ul {
  list-style-type: "- ";
  list-style-position: inside;
  color: white;
  padding: 0;
}
.modal .centrar {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
}
.modal .contenido {
  max-width: 600px;
}
.modal .titulo-principal {
  font-size: 3.5rem;
  max-width: 650px;
  font-weight: 500;
  line-height: 4rem;
  margin: 4rem 0;
}
.modal[open] {
  display: flex;
  animation: mostrar-modal 0.2s forwards ease-out;
}
.modal.cerrar {
  animation: cerrar-modal 0.2s forwards ease-out;
}
.modal::backdrop {
  background: transparent;
}

@keyframes mostrar-modal {
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes cerrar-modal {
  from {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.5);
    opacity: 0;
  }
}
.modal.contacto .ticker-wrap {
  position: relative;
  border-bottom: 1px solid #000;
}
.modal.contacto .ticker-wrap .xxl {
  color: #000 !important;
}

:focus {
  outline: none;
}

.button {
  position: relative;
  display: inline-block;
  font-size: calc(1 * (16 * 1px));
  line-height: 1;
  font-weight: normal;
  white-space: nowrap;
  cursor: pointer;
  box-shadow: none;
  border-width: 2px;
  border-style: solid;
  padding: 13px 25px 15px 25px;
  font-weight: 500;
  color: #fff !important;
  background: var(--color-dark-1);
  transition: all 0.6s ease-in-out;
  transition-property: color, background-color, border-color;
}
@media screen and (min-width: 320px) {
  .button {
    font-size: calc(1 * (16 * 1px + (24 - 16) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .button {
    font-size: calc(1 * (24 * 1px));
  }
}
.button.white {
  border-color: var(--color-light-1);
}
.button.small, .button.comment-edit-link, .button.comment-reply-link, .button.comment-metadata, .button.slider__scrollbar-handle:before {
  font-size: 16px;
  padding: 12px 18px;
}
.button.small-width {
  font-size: calc(1 * (16 * 1px));
  width: 100%;
  display: flex;
  justify-content: end;
  padding: 18px 60px 18px 30px;
  clip-path: polygon(0 0, calc(100% - 50px) 0, 100% 100%, 0 100%);
}
@media screen and (min-width: 320px) {
  .button.small-width {
    font-size: calc(1 * (16 * 1px + (20 - 16) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .button.small-width {
    font-size: calc(1 * (20 * 1px));
  }
}
.button.small-width .button__label-hover {
  transform: translate(-20%, -50%);
  left: auto;
  right: 40px;
}
.button:focus {
  outline: none;
}
.button:hover .button__label-normal .button__title {
  transform: translate(0%, -100%);
  opacity: 0;
}
.button:hover .button__label-normal .button__icon {
  opacity: 0;
  transform: translate(100%, 0%);
}
.button:hover .button__label-hover .button__title {
  transform: translate(0%, 0%);
  opacity: 1;
}
.button:hover .button__label-hover .button__icon {
  opacity: 1;
  transform: translate(0%, 0%);
}

.button_icon {
  display: inline-flex;
  border: none;
}
.button_icon .button__label {
  padding: 0;
}

.button__label-hover {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.button__label-hover .button__title {
  transform: translate(0%, 100%);
  opacity: 0;
}
.button__label-hover .button__icon {
  transform: translate(-100%, 0%);
  opacity: 0;
}

.button__label-normal .button__title {
  transform: translate(0%, 0%);
  opacity: 1;
}

.button__title {
  display: inline-block;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
}

.button__label {
  display: block;
  padding: 18px 42px;
}

.button__icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
  font-size: 100%;
}

.button__icon_before {
  margin-right: 0.5em;
}

.button__icon_after {
  margin-left: 0.5em;
}
.button__icon_after.arrow-1 {
  background: url("../img/new/flecha_larga.svg") no-repeat center center;
  width: calc(1 * (40 * 1px));
  height: calc(1 * (10 * 1px));
}
@media screen and (min-width: 320px) {
  .button__icon_after.arrow-1 {
    width: calc(1 * (40 * 1px + (70 - 40) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .button__icon_after.arrow-1 {
    width: calc(1 * (70 * 1px));
  }
}
@media screen and (min-width: 320px) {
  .button__icon_after.arrow-1 {
    height: calc(1 * (10 * 1px + (14 - 10) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .button__icon_after.arrow-1 {
    height: calc(1 * (14 * 1px));
  }
}
.button__icon_after.arrow-2 {
  background: url("../img/new/fkecha_derecha_white.svg") no-repeat center center;
  width: calc(1 * (25 * 1px));
  height: calc(1 * (15 * 1px));
}
@media screen and (min-width: 320px) {
  .button__icon_after.arrow-2 {
    width: calc(1 * (25 * 1px + (25 - 25) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .button__icon_after.arrow-2 {
    width: calc(1 * (25 * 1px));
  }
}
@media screen and (min-width: 320px) {
  .button__icon_after.arrow-2 {
    height: calc(1 * (15 * 1px + (15 - 15) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .button__icon_after.arrow-2 {
    height: calc(1 * (15 * 1px));
  }
}

.button_fullwidth {
  width: 100%;
}

.button-fullwidth .button {
  width: 100%;
}

.knz-arrow-right:before {
  font-size: 14px;
}

.button.button_bordered {
  background-color: unset !important;
  color: var(--color-dark-1);
  border-width: 1px;
  border-style: solid;
}

.button.button_solid {
  border-color: transparent;
  color: var(--color-gray-3);
}
.button.button_solid.bg-dark-1 {
  color: #fff;
  background-color: var(--color-dark-1);
}
.button.button_solid.bg-dark-2 {
  color: #fff;
  background-color: var(--color-dark-2);
}
.button.button_solid.bg-dark-3 {
  color: #fff;
  background-color: var(--color-dark-3);
}
.button.button_solid.bg-dark-4 {
  color: #fff;
  background-color: var(--color-dark-4);
}
.button.button_solid.bg-light-1 {
  background-color: var(--color-light-1);
  color: var(--color-dark-1);
}
.button.button_solid.bg-light-2 {
  background-color: var(--color-light-2);
  color: var(--color-dark-1);
}
.button.button_solid.bg-light-3 {
  background-color: var(--color-light-3);
  color: var(--color-dark-1);
}
.button.button_solid.bg-light-4 {
  background-color: var(--color-light-4);
  color: var(--color-dark-1);
}
.button.button_solid.bg-white-1 {
  background-color: var(--color-border-light);
  color: var(--color-dark-1);
}
.button.button_solid.bg-gray-1 {
  background-color: var(--color-gray-1);
}
.button.button_solid.bg-gray-2 {
  background-color: var(--color-gray-2);
}
.button.button_solid.bg-gray-3 {
  background-color: var(--color-gray-3);
}

.button-circle {
  position: relative;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  width: calc(1 * (60 * 1px));
  height: calc(1 * (60 * 1px));
  padding: 0 !important;
  opacity: 0.85;
  transition-property: opacity, color, background-color, border-color;
}
@media screen and (min-width: 320px) {
  .button-circle {
    width: calc(1 * (60 * 1px + (90 - 60) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .button-circle {
    width: calc(1 * (90 * 1px));
  }
}
@media screen and (min-width: 320px) {
  .button-circle {
    height: calc(1 * (60 * 1px + (90 - 60) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .button-circle {
    height: calc(1 * (90 * 1px));
  }
}
.button-circle:hover {
  opacity: 1;
}

.cursorfollower .button-circle[data-arts-cursor]:hover {
  border-color: transparent !important;
}

.button-circle_medium {
  width: calc(1 * (90 * 1px));
  height: calc(1 * (90 * 1px));
}
@media screen and (min-width: 320px) {
  .button-circle_medium {
    width: calc(1 * (90 * 1px + (140 - 90) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .button-circle_medium {
    width: calc(1 * (140 * 1px));
  }
}
@media screen and (min-width: 320px) {
  .button-circle_medium {
    height: calc(1 * (90 * 1px + (140 - 90) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .button-circle_medium {
    height: calc(1 * (140 * 1px));
  }
}

.button-circle_big {
  width: calc(1 * (130 * 1px));
  height: calc(1 * (130 * 1px));
}
@media screen and (min-width: 320px) {
  .button-circle_big {
    width: calc(1 * (130 * 1px + (160 - 130) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .button-circle_big {
    width: calc(1 * (160 * 1px));
  }
}
@media screen and (min-width: 320px) {
  .button-circle_big {
    height: calc(1 * (130 * 1px + (160 - 130) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .button-circle_big {
    height: calc(1 * (160 * 1px));
  }
}

.button-circle__inner {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.button-circle__geometry {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid var(--color-border-dark);
  border-radius: 50%;
}

.button-circle__arrow {
  width: 30px;
  font-size: calc(12 * 1px);
}
@media screen and (min-width: 320px) {
  .button-circle__arrow {
    font-size: calc(12 * 1px + (18 - 12) * ((100vw - 320px) / 1600));
  }
}
@media screen and (min-width: 1920px) {
  .button-circle__arrow {
    font-size: calc(18 * 1px);
  }
}
.button-circle__arrow:before {
  width: 30px;
  margin: 0;
}

.button-circle__label {
  padding: 0 !important;
}

.button-circles {
  display: inline-flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 30px;
  height: 30px;
  padding: 0 !important;
}
.button-circles.block-counter {
  margin-left: 4px;
}
.button-circles .block-counter__counter {
  top: -12px;
  left: calc(100% + 6px);
}

.button-circles__circle {
  position: relative;
  width: 12px;
  height: 12px;
  outline: none;
  display: inline-block;
  border: 2px solid var(--color-border-dark);
  vertical-align: middle;
  border-radius: 100%;
}
.button-circles__circle:nth-child(1) {
  align-self: flex-start;
}
.button-circles__circle:nth-child(2) {
  align-self: flex-start;
}
.button-circles__circle:nth-child(3) {
  align-self: flex-end;
}
.button-circles__circle:nth-child(4) {
  align-self: flex-end;
}
.button-circles__circle svg {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  stroke-width: 12px;
  z-index: 50;
}
.button-circles__circle svg .circle {
  stroke: #fff;
}

@media screen and (max-width: 767px) {
  .button-circles {
    width: 30px;
    height: 30px;
  }
  .button-circles__circle {
    width: 12px;
    height: 12px;
  }
  .button-circles__circle svg {
    width: 12px;
    height: 12px;
  }
}
.card {
  width: 100%;
  height: 100%;
  position: relative;
}

.card__content ul, .card__content ol {
  padding-left: 0 !important;
}

.card__counter {
  position: absolute;
  right: 40px;
  bottom: 30px;
}

.card__arrow {
  margin-left: 1rem;
}

@media screen and (max-width: 991px) {
  .card__counter {
    position: absolute;
    right: 25px;
    bottom: 15px;
  }
}
.card-portfolio {
  overflow: hidden;
  border-radius: 4px;
}

.card-portfolio__content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-portfolio__content_small {
  padding: 60px;
}

.card-portfolio__wrapper-img img {
  transform: scale(1.03);
}

.categories {
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-size: 13px;
  font-weight: 500;
}
.categories li {
  display: inline-flex;
  flex-wrap: wrap;
  line-height: 1.6;
}
.categories li:not(:last-child):after {
  content: "/";
  color: var(--color-dark-1);
  display: inline-block;
  margin-left: 6px;
  margin-right: 5px;
  transition: all 0.6s ease-in-out;
}

.bg-dark-1 {
  background-color: var(--color-dark-1);
}

.bg-dark-2 {
  background-color: var(--color-dark-2);
}

.bg-dark-3 {
  background-color: var(--color-dark-3);
}

.bg-dark-4 {
  background-color: var(--color-dark-4);
}

.bg-light-1 {
  background-color: var(--color-light-1);
}

.bg-light-2 {
  background-color: var(--color-light-2);
}

.bg-light-3 {
  background-color: var(--color-light-3);
}

.bg-light-4 {
  background-color: var(--color-light-4);
}

.bg-white-1 {
  background-color: #fff;
}

.bg-gray-1 {
  background-color: var(--color-gray-1);
}

.bg-gray-2 {
  background-color: var(--color-gray-2);
}

.bg-gray-3 {
  background-color: var(--color-gray-3);
}

.bg-gray-4 {
  background-color: var(--color-gray-4);
}

.bg-portfolio-1 {
  background-color: var(--color-portfolio-1);
}

.bg-portfolio-2 {
  background-color: var(--color-portfolio-2);
}

.bg-portfolio-3 {
  background-color: var(--color-portfolio-3);
}

.bg-portfolio-4 {
  background-color: var(--color-portfolio-4);
}

.bg-portfolio-5 {
  background-color: var(--color-portfolio-5);
}

.bg-portfolio-6 {
  background-color: var(--color-portfolio-6);
}

.bg-portfolio-7 {
  background-color: var(--color-portfolio-7);
}

.bg-portfolio-8 {
  background-color: var(--color-portfolio-8);
}

.bg-portfolio-9 {
  background-color: var(--color-portfolio-9);
}

.bg-portfolio-10 {
  background-color: var(--color-portfolio-10);
}

.bg-portfolio-11 {
  background-color: var(--color-portfolio-11);
}

.bg-portfolio-12 {
  background-color: var(--color-portfolio-12);
}

.color-light-1 {
  color: var(--color-light-1) !important;
}

.color-light-2 {
  color: var(--color-light-2) !important;
}

.color-light-3 {
  color: var(--color-light-3) !important;
}

.color-light-4 {
  color: var(--color-light-4) !important;
}

.color-yellow {
  color: var(--color-yellow) !important;
}

.bg-yellow {
  background: var(--color-yellow) !important;
}

.color-dark-1 {
  color: var(--color-dark-1) !important;
}

.counter_circle {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  width: calc(1 * (220 * 1px));
  height: calc(1 * (220 * 1px));
}
@media screen and (min-width: 320px) {
  .counter_circle {
    width: calc(1 * (220 * 1px + (260 - 220) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .counter_circle {
    width: calc(1 * (260 * 1px));
  }
}
@media screen and (min-width: 320px) {
  .counter_circle {
    height: calc(1 * (220 * 1px + (260 - 220) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .counter_circle {
    height: calc(1 * (260 * 1px));
  }
}
.counter_circle .svg-circle {
  stroke-width: 2px;
  stroke: var(--color-border-dark);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.counter__number {
  position: relative;
  z-index: 50;
}

.counter__label {
  position: relative;
  z-index: 50;
  margin-top: 8px;
  white-space: nowrap;
  line-height: 1.2 !important;
}

.arts-cursor {
  color: var(--color-dark-1);
  display: none;
}

.arts-cursor__stroke-inner, .arts-cursor__stroke-outer {
  stroke-width: 2px;
  stroke: var(--color-dark-1);
}

.arts-cursor__label {
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0;
}

.cursor-progress {
  cursor: progress !important;
}
.cursor-progress * {
  cursor: progress !important;
}

.cursor-none {
  cursor: none !important;
}
.cursor-none * {
  cursor: none !important;
}

.is-dragging {
  cursor: none !important;
}
.is-dragging * {
  cursor: none !important;
}
.is-dragging .is-dragging__blocker {
  visibility: visible;
  opacity: 1;
  z-index: 100;
}

.is-dragging__blocker {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;
  transition-delay: 0.2s;
}

.feature__wrapper-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: calc(1 * (120 * 1px));
  height: calc(1 * (120 * 1px));
  border: 1px solid var(--color-border-dark);
  border-radius: 50%;
  padding: 30px;
}
@media screen and (min-width: 320px) {
  .feature__wrapper-icon {
    width: calc(1 * (120 * 1px + (220 - 120) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .feature__wrapper-icon {
    width: calc(1 * (220 * 1px));
  }
}
@media screen and (min-width: 320px) {
  .feature__wrapper-icon {
    height: calc(1 * (120 * 1px + (220 - 120) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .feature__wrapper-icon {
    height: calc(1 * (220 * 1px));
  }
}

.feature__header {
  display: block;
  width: 100%;
}

.feature__subheading {
  color: var(--color-gray-3);
}

.background-hover {
  position: relative;
  display: block;
}
.background-hover:hover *, .background-hover:hover *:after, .background-hover:hover *:before {
  color: #fff !important;
}
.background-hover:hover a {
  opacity: 0.9;
}
.background-hover:hover a:hover {
  opacity: 1;
}
.background-hover:hover .background-hover__image {
  opacity: 1;
}
.background-hover:hover .background-hover__overlay {
  opacity: 1;
}
.background-hover .background-hover__overlay {
  opacity: 0;
  transition: all 0.6s ease-in-out;
}

.background-hover__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.01;
  transition: all 0.6s ease-in-out;
}

.background-hover__content {
  position: relative;
  z-index: 50;
}

.background-hover__overlay {
  z-index: 10 !important;
}

.hover-zoom {
  backface-visibility: hidden;
}

.hover-zoom:hover:not(:focus) .hover-zoom__zoom {
  transform: scale(1);
}
.hover-zoom:hover:not(:focus) .hover-zoom__inner {
  transform: scale(1.4);
}

.hover-zoom_out:hover .hover-zoom__zoom {
  transform: scale(1) !important;
}
.hover-zoom_out:hover .hover-zoom__inner {
  transform: scale(0.95) !important;
}

.hover-zoom__inner {
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.hover-zoom__zoom, .hover-zoom__inner {
  transform-origin: center center;
  transition: transform 1s ease;
  will-change: transform;
  backface-visibility: hidden;
}

.hover-zoom__caption {
  transition: transform 1s ease;
}

.hover-zoom__zoomed {
  transform: scale(1.1);
}

.hover-zoom-underline .hover-zoom__zoom {
  transform: scale(1);
}
.hover-zoom-underline .hover-zoom__inner {
  transform: scale(1.4);
}
.hover-zoom-underline .underline-hover__target {
  background-position: 0% 100% !important;
  background-size: 100% 2px !important;
}
.hover-zoom-underline .underline-hover__target .arts-split-text__line {
  background-position: 0% 100% !important;
  background-size: 100% 2px !important;
}

.mask-reveal {
  position: relative;
  overflow: hidden;
}

.mask-reveal__layer {
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.underline, .underline-hover-self {
  display: inline;
  position: relative;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 1px;
}
.underline .arts-split-text__line, .underline-hover-self .arts-split-text__line {
  display: inline-block !important;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 1px;
  transition: background-size 0.4s ease;
}

.underline-hover-self {
  background-position: 100% 100%;
  background-size: 0% 2px;
  transition: background-size 0.4s ease, color 0.2s ease-in-out;
}
.underline-hover-self:hover {
  background-position: 0% 100%;
  background-size: 100% 1px;
}

.underline-hover:hover .underline-hover__target {
  background-position: 0% 100%;
  background-size: 100% 1px;
}
.underline-hover:hover .underline-hover__target .arts-split-text__line {
  background-position: 0% 100%;
  background-size: 100% 1px;
}

.underline-hover__target:not(.arts-split-text) {
  display: inline;
  position: relative;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: 0% 1px;
  transition: background-size 0.4s ease;
}

.underline-hover__target .arts-split-text__line {
  display: inline-block !important;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: 0% 1px;
  transition: background-size 0.4s ease;
}

.is-ajax-loading .underline, .is-ajax-loading .underline-hover-self {
  background-position: 100% 100% !important;
  background-size: 0% 1px !important;
}
.is-ajax-loading .underline .arts-split-text__line, .is-ajax-loading .underline-hover-self .arts-split-text__line {
  background-position: 100% 100% !important;
  background-size: 0% 1px !important;
}
.is-ajax-loading .underline-hover__target:not(.arts-split-text) {
  background-position: 100% 100% !important;
  background-size: 0% 1px !important;
}
.is-ajax-loading .underline-hover__target {
  background-position: 100% 100% !important;
  background-size: 0% 1px !important;
}
.is-ajax-loading .underline-hover__target .arts-split-text__line {
  background-position: 100% 100% !important;
  background-size: 0% 1px !important;
}

.figure-category {
  position: relative;
  display: flex;
  height: 0;
  Width: 100%;
  padding-bottom: 100%;
}

.figure-category__icon {
  font-size: calc(26 * 1px);
}
@media screen and (min-width: 320px) {
  .figure-category__icon {
    font-size: calc(26 * 1px + (64 - 26) * ((100vw - 320px) / 1600));
  }
}
@media screen and (min-width: 1920px) {
  .figure-category__icon {
    font-size: calc(64 * 1px);
  }
}

.figure-category__link {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  border-radius: 100%;
  border: 1px solid var(--color-border-dark);
  transition-property: opacity, color, background-color, border-color;
}
.figure-category__link:hover {
  border-color: var(--color-dark-1);
}

.cursorfollower .figure-category__link[data-arts-cursor]:hover {
  border-color: transparent;
}

.figure-image {
  display: block;
  margin-bottom: 0;
}

.figure-image__wrapper-img {
  position: relative;
  overflow: hidden;
}

.figure-image__caption {
  display: inline-block !important;
}

.figure-project {
  width: 100%;
}

.figure-project__wrapper-img {
  display: block;
}
.figure-project__wrapper-img img {
  transform: scale(1.1);
}

.figure-member_has-social:hover .figure-member__avatar img {
  transform: scale(1.1);
}
.figure-member_has-social:hover .figure-member__headline {
  width: 80px;
}
.figure-member_has-social:hover .figure-member__position {
  transition-delay: 50ms;
  transform: translateY(-30px);
  opacity: 0;
  visibility: hidden;
}
.figure-member_has-social:hover .figure-member__footer .figure-member__name {
  transform: translateY(-20px);
  opacity: 0;
  visibility: hidden;
  transition-delay: 0ms;
}
.figure-member_has-social:hover .figure-member__social li a {
  transform: translateY(0px);
  opacity: 1;
  visibility: visible;
}
.figure-member_has-social:hover .figure-member__social li:nth-child(1) a {
  transition-delay: 70ms;
}
.figure-member_has-social:hover .figure-member__social li:nth-child(2) a {
  transition-delay: 90ms;
}
.figure-member_has-social:hover .figure-member__social li:nth-child(3) a {
  transition-delay: 110ms;
}
.figure-member_has-social:hover .figure-member__social li:nth-child(4) a {
  transition-delay: 130ms;
}
.figure-member_has-social:hover .figure-member__social li:nth-child(5) a {
  transition-delay: 150ms;
}
.figure-member_has-social:hover .figure-member__social li:nth-child(6) a {
  transition-delay: 170ms;
}
.figure-member_has-social:hover .figure-member__social li:nth-child(7) a {
  transition-delay: 190ms;
}
.figure-member_has-social:hover .figure-member__social li:nth-child(8) a {
  transition-delay: 210ms;
}
.figure-member_has-social:hover .figure-member__social li:nth-child(9) a {
  transition-delay: 230ms;
}
.figure-member_has-social:hover .figure-member__social li:nth-child(10) a {
  transition-delay: 250ms;
}

.figure-member__avatar {
  overflow: hidden;
}
.figure-member__avatar img {
  transition: all 0.6s ease-in-out;
}

.figure-member__footer {
  position: relative;
}

.figure-member__name {
  margin-top: 0;
  margin-bottom: 0;
  transition: all 0.6s ease-in-out;
  transition-delay: 150ms;
}

.figure-member__position {
  transition: all 0.6s ease-in-out;
  transition-delay: 150ms;
}

.figure-member__headline {
  display: inline-block;
  width: 60px;
  height: 1px;
  vertical-align: middle;
  background-color: var(--color-gray-1);
  transition: all 0.6s ease-in-out;
}

.figure-member__social {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.figure-member__social li a {
  transform: translateY(30px);
  transition: all 0.6s ease-in-out;
  opacity: 0;
  visibility: hidden;
}
.figure-member__social li a:last-child {
  margin-right: 0;
}

.filter {
  position: relative;
}

.filter__inner {
  margin-top: -0.5em;
  margin-bottom: -0.5em;
  position: relative;
}

.filter__item {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  cursor: pointer;
  transition: all 0.6s ease-in-out;
  color: var(--color-dark-4);
  opacity: 0.5;
}
.filter__item:not(.filter__item_active):hover {
  opacity: 1;
}

.filter__item-inner {
  position: relative;
  display: inline-block;
}

.filter__item_active {
  opacity: 1;
  pointer-events: none;
}

.filter__underline {
  pointer-events: none;
  display: block;
  position: absolute;
  margin-top: 1.8em;
  top: 0;
  left: 0;
  background-color: var(--color-dark-4);
  width: 0;
  height: 2px;
}

.filter__item-label {
  display: inline-block;
}

@media screen and (max-width: 991px) {
  .filter .filter__item:first-child {
    padding-top: 0;
  }
  .filter .filter__item:last-child {
    padding-bottom: 0;
  }
}
.m-xsmall {
  margin: calc(1 * (var(--distance-min-xsmall) * 1px));
}
@media screen and (min-width: 320px) {
  .m-xsmall {
    margin: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .m-xsmall {
    margin: calc(1 * (var(--distance-max-xsmall) * 1px));
  }
}

.m-small {
  margin: calc(1 * (var(--distance-min-small) * 1px));
}
@media screen and (min-width: 320px) {
  .m-small {
    margin: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .m-small {
    margin: calc(1 * (var(--distance-max-small) * 1px));
  }
}

.m-medium {
  margin: calc(1 * (var(--distance-min-medium) * 1px));
}
@media screen and (min-width: 320px) {
  .m-medium {
    margin: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .m-medium {
    margin: calc(1 * (var(--distance-max-medium) * 1px));
  }
}

.m-large {
  margin: calc(1 * (var(--distance-min-large) * 1px));
}
@media screen and (min-width: 320px) {
  .m-large {
    margin: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .m-large {
    margin: calc(1 * (var(--distance-max-large) * 1px));
  }
}

.m-xlarge {
  margin: calc(1 * (var(--distance-min-xlarge) * 1px));
}
@media screen and (min-width: 320px) {
  .m-xlarge {
    margin: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .m-xlarge {
    margin: calc(1 * (var(--distance-max-xlarge) * 1px));
  }
}

.my-xsmall {
  margin-top: calc(1 * (var(--distance-min-xsmall) * 1px));
  margin-bottom: calc(1 * (var(--distance-min-xsmall) * 1px));
}
@media screen and (min-width: 320px) {
  .my-xsmall {
    margin-top: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .my-xsmall {
    margin-top: calc(1 * (var(--distance-max-xsmall) * 1px));
  }
}
@media screen and (min-width: 320px) {
  .my-xsmall {
    margin-bottom: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .my-xsmall {
    margin-bottom: calc(1 * (var(--distance-max-xsmall) * 1px));
  }
}

.my-small {
  margin-top: calc(1 * (var(--distance-min-small) * 1px));
  margin-bottom: calc(1 * (var(--distance-min-small) * 1px));
}
@media screen and (min-width: 320px) {
  .my-small {
    margin-top: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .my-small {
    margin-top: calc(1 * (var(--distance-max-small) * 1px));
  }
}
@media screen and (min-width: 320px) {
  .my-small {
    margin-bottom: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .my-small {
    margin-bottom: calc(1 * (var(--distance-max-small) * 1px));
  }
}

.my-medium {
  margin-top: calc(1 * (var(--distance-min-medium) * 1px));
  margin-bottom: calc(1 * (var(--distance-min-medium) * 1px));
}
@media screen and (min-width: 320px) {
  .my-medium {
    margin-top: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .my-medium {
    margin-top: calc(1 * (var(--distance-max-medium) * 1px));
  }
}
@media screen and (min-width: 320px) {
  .my-medium {
    margin-bottom: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .my-medium {
    margin-bottom: calc(1 * (var(--distance-max-medium) * 1px));
  }
}

.my-large {
  margin-top: calc(1 * (var(--distance-min-large) * 1px));
  margin-bottom: calc(1 * (var(--distance-min-large) * 1px));
}
@media screen and (min-width: 320px) {
  .my-large {
    margin-top: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .my-large {
    margin-top: calc(1 * (var(--distance-max-large) * 1px));
  }
}
@media screen and (min-width: 320px) {
  .my-large {
    margin-bottom: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .my-large {
    margin-bottom: calc(1 * (var(--distance-max-large) * 1px));
  }
}

.my-xlarge {
  margin-top: calc(1 * (var(--distance-min-xlarge) * 1px));
  margin-bottom: calc(1 * (var(--distance-min-xlarge) * 1px));
}
@media screen and (min-width: 320px) {
  .my-xlarge {
    margin-top: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .my-xlarge {
    margin-top: calc(1 * (var(--distance-max-xlarge) * 1px));
  }
}
@media screen and (min-width: 320px) {
  .my-xlarge {
    margin-bottom: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .my-xlarge {
    margin-bottom: calc(1 * (var(--distance-max-xlarge) * 1px));
  }
}

.mx-xsmall {
  margin-left: calc(1 * (var(--distance-min-xsmall) * 1px));
  margin-right: calc(1 * (var(--distance-min-xsmall) * 1px));
}
@media screen and (min-width: 320px) {
  .mx-xsmall {
    margin-left: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .mx-xsmall {
    margin-left: calc(1 * (var(--distance-max-xsmall) * 1px));
  }
}
@media screen and (min-width: 320px) {
  .mx-xsmall {
    margin-right: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .mx-xsmall {
    margin-right: calc(1 * (var(--distance-max-xsmall) * 1px));
  }
}

.mx-small {
  margin-left: calc(1 * (var(--distance-min-small) * 1px));
  margin-right: calc(1 * (var(--distance-min-small) * 1px));
}
@media screen and (min-width: 320px) {
  .mx-small {
    margin-left: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .mx-small {
    margin-left: calc(1 * (var(--distance-max-small) * 1px));
  }
}
@media screen and (min-width: 320px) {
  .mx-small {
    margin-right: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .mx-small {
    margin-right: calc(1 * (var(--distance-max-small) * 1px));
  }
}

.mx-medium {
  margin-left: calc(1 * (var(--distance-min-medium) * 1px));
  margin-right: calc(1 * (var(--distance-min-medium) * 1px));
}
@media screen and (min-width: 320px) {
  .mx-medium {
    margin-left: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .mx-medium {
    margin-left: calc(1 * (var(--distance-max-medium) * 1px));
  }
}
@media screen and (min-width: 320px) {
  .mx-medium {
    margin-right: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .mx-medium {
    margin-right: calc(1 * (var(--distance-max-medium) * 1px));
  }
}

.mx-large {
  margin-left: calc(1 * (var(--distance-min-large) * 1px));
  margin-right: calc(1 * (var(--distance-min-large) * 1px));
}
@media screen and (min-width: 320px) {
  .mx-large {
    margin-left: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .mx-large {
    margin-left: calc(1 * (var(--distance-max-large) * 1px));
  }
}
@media screen and (min-width: 320px) {
  .mx-large {
    margin-right: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .mx-large {
    margin-right: calc(1 * (var(--distance-max-large) * 1px));
  }
}

.mx-xlarge {
  margin-left: calc(1 * (var(--distance-min-xlarge) * 1px));
  margin-right: calc(1 * (var(--distance-min-xlarge) * 1px));
}
@media screen and (min-width: 320px) {
  .mx-xlarge {
    margin-left: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .mx-xlarge {
    margin-left: calc(1 * (var(--distance-max-xlarge) * 1px));
  }
}
@media screen and (min-width: 320px) {
  .mx-xlarge {
    margin-right: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .mx-xlarge {
    margin-right: calc(1 * (var(--distance-max-xlarge) * 1px));
  }
}

.mt-xsmall {
  margin-top: calc(1 * (var(--distance-min-xsmall) * 1px));
}
@media screen and (min-width: 320px) {
  .mt-xsmall {
    margin-top: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .mt-xsmall {
    margin-top: calc(1 * (var(--distance-max-xsmall) * 1px));
  }
}

.mt-small {
  margin-top: calc(1 * (var(--distance-min-small) * 1px));
}
@media screen and (min-width: 320px) {
  .mt-small {
    margin-top: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .mt-small {
    margin-top: calc(1 * (var(--distance-max-small) * 1px));
  }
}

.mt-medium {
  margin-top: calc(1 * (var(--distance-min-medium) * 1px));
}
@media screen and (min-width: 320px) {
  .mt-medium {
    margin-top: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .mt-medium {
    margin-top: calc(1 * (var(--distance-max-medium) * 1px));
  }
}

.mt-large {
  margin-top: calc(1 * (var(--distance-min-large) * 1px));
}
@media screen and (min-width: 320px) {
  .mt-large {
    margin-top: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .mt-large {
    margin-top: calc(1 * (var(--distance-max-large) * 1px));
  }
}

.mt-xlarge {
  margin-top: calc(1 * (var(--distance-min-xlarge) * 1px));
}
@media screen and (min-width: 320px) {
  .mt-xlarge {
    margin-top: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .mt-xlarge {
    margin-top: calc(1 * (var(--distance-max-xlarge) * 1px));
  }
}

.mr-xsmall {
  margin-right: calc(1 * (var(--distance-min-xsmall) * 1px));
}
@media screen and (min-width: 320px) {
  .mr-xsmall {
    margin-right: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .mr-xsmall {
    margin-right: calc(1 * (var(--distance-max-xsmall) * 1px));
  }
}

.mr-small {
  margin-right: calc(1 * (var(--distance-min-small) * 1px));
}
@media screen and (min-width: 320px) {
  .mr-small {
    margin-right: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .mr-small {
    margin-right: calc(1 * (var(--distance-max-small) * 1px));
  }
}

.mr-medium {
  margin-right: calc(1 * (var(--distance-min-medium) * 1px));
}
@media screen and (min-width: 320px) {
  .mr-medium {
    margin-right: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .mr-medium {
    margin-right: calc(1 * (var(--distance-max-medium) * 1px));
  }
}

.mr-large {
  margin-right: calc(1 * (var(--distance-min-large) * 1px));
}
@media screen and (min-width: 320px) {
  .mr-large {
    margin-right: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .mr-large {
    margin-right: calc(1 * (var(--distance-max-large) * 1px));
  }
}

.mr-xlarge {
  margin-right: calc(1 * (var(--distance-min-xlarge) * 1px));
}
@media screen and (min-width: 320px) {
  .mr-xlarge {
    margin-right: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .mr-xlarge {
    margin-right: calc(1 * (var(--distance-max-xlarge) * 1px));
  }
}

.mb-xsmall {
  margin-bottom: calc(1 * (var(--distance-min-xsmall) * 1px));
}
@media screen and (min-width: 320px) {
  .mb-xsmall {
    margin-bottom: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .mb-xsmall {
    margin-bottom: calc(1 * (var(--distance-max-xsmall) * 1px));
  }
}

.mb-small {
  margin-bottom: calc(1 * (var(--distance-min-small) * 1px));
}
@media screen and (min-width: 320px) {
  .mb-small {
    margin-bottom: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .mb-small {
    margin-bottom: calc(1 * (var(--distance-max-small) * 1px));
  }
}

.mb-medium {
  margin-bottom: calc(1 * (var(--distance-min-medium) * 1px));
}
@media screen and (min-width: 320px) {
  .mb-medium {
    margin-bottom: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .mb-medium {
    margin-bottom: calc(1 * (var(--distance-max-medium) * 1px));
  }
}

.mb-large {
  margin-bottom: calc(1 * (var(--distance-min-large) * 1px));
}
@media screen and (min-width: 320px) {
  .mb-large {
    margin-bottom: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .mb-large {
    margin-bottom: calc(1 * (var(--distance-max-large) * 1px));
  }
}

.mb-xlarge {
  margin-bottom: calc(1 * (var(--distance-min-xlarge) * 1px));
}
@media screen and (min-width: 320px) {
  .mb-xlarge {
    margin-bottom: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .mb-xlarge {
    margin-bottom: calc(1 * (var(--distance-max-xlarge) * 1px));
  }
}

.ml-xsmall {
  margin-left: calc(1 * (var(--distance-min-xsmall) * 1px));
}
@media screen and (min-width: 320px) {
  .ml-xsmall {
    margin-left: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .ml-xsmall {
    margin-left: calc(1 * (var(--distance-max-xsmall) * 1px));
  }
}

.ml-small {
  margin-left: calc(1 * (var(--distance-min-small) * 1px));
}
@media screen and (min-width: 320px) {
  .ml-small {
    margin-left: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .ml-small {
    margin-left: calc(1 * (var(--distance-max-small) * 1px));
  }
}

.ml-medium {
  margin-left: calc(1 * (var(--distance-min-medium) * 1px));
}
@media screen and (min-width: 320px) {
  .ml-medium {
    margin-left: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .ml-medium {
    margin-left: calc(1 * (var(--distance-max-medium) * 1px));
  }
}

.ml-large {
  margin-left: calc(1 * (var(--distance-min-large) * 1px));
}
@media screen and (min-width: 320px) {
  .ml-large {
    margin-left: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .ml-large {
    margin-left: calc(1 * (var(--distance-max-large) * 1px));
  }
}

.ml-xlarge {
  margin-left: calc(1 * (var(--distance-min-xlarge) * 1px));
}
@media screen and (min-width: 320px) {
  .ml-xlarge {
    margin-left: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .ml-xlarge {
    margin-left: calc(1 * (var(--distance-max-xlarge) * 1px));
  }
}

.mt-minus-xsmall {
  margin-top: calc(-1 * (var(--distance-min-xsmall) * 1px));
}
@media screen and (min-width: 320px) {
  .mt-minus-xsmall {
    margin-top: calc(-1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .mt-minus-xsmall {
    margin-top: calc(-1 * (var(--distance-max-xsmall) * 1px));
  }
}

.mt-minus-small {
  margin-top: calc(-1 * (var(--distance-min-small) * 1px));
}
@media screen and (min-width: 320px) {
  .mt-minus-small {
    margin-top: calc(-1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .mt-minus-small {
    margin-top: calc(-1 * (var(--distance-max-small) * 1px));
  }
}

.mt-minus-medium {
  margin-top: calc(-1 * (var(--distance-min-medium) * 1px));
}
@media screen and (min-width: 320px) {
  .mt-minus-medium {
    margin-top: calc(-1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .mt-minus-medium {
    margin-top: calc(-1 * (var(--distance-max-medium) * 1px));
  }
}

.mt-minus-large {
  margin-top: calc(-1 * (var(--distance-min-large) * 1px));
}
@media screen and (min-width: 320px) {
  .mt-minus-large {
    margin-top: calc(-1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .mt-minus-large {
    margin-top: calc(-1 * (var(--distance-max-large) * 1px));
  }
}

.mt-minus-xlarge {
  margin-top: calc(-1 * (var(--distance-min-xlarge) * 1px));
}
@media screen and (min-width: 320px) {
  .mt-minus-xlarge {
    margin-top: calc(-1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .mt-minus-xlarge {
    margin-top: calc(-1 * (var(--distance-max-xlarge) * 1px));
  }
}

.mr-minus-xsmall {
  margin-right: calc(-1 * (var(--distance-min-xsmall) * 1px));
}
@media screen and (min-width: 320px) {
  .mr-minus-xsmall {
    margin-right: calc(-1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .mr-minus-xsmall {
    margin-right: calc(-1 * (var(--distance-max-xsmall) * 1px));
  }
}

.mr-minus-small {
  margin-right: calc(-1 * (var(--distance-min-small) * 1px));
}
@media screen and (min-width: 320px) {
  .mr-minus-small {
    margin-right: calc(-1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .mr-minus-small {
    margin-right: calc(-1 * (var(--distance-max-small) * 1px));
  }
}

.mr-minus-medium {
  margin-right: calc(-1 * (var(--distance-min-medium) * 1px));
}
@media screen and (min-width: 320px) {
  .mr-minus-medium {
    margin-right: calc(-1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .mr-minus-medium {
    margin-right: calc(-1 * (var(--distance-max-medium) * 1px));
  }
}

.mr-minus-large {
  margin-right: calc(-1 * (var(--distance-min-large) * 1px));
}
@media screen and (min-width: 320px) {
  .mr-minus-large {
    margin-right: calc(-1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .mr-minus-large {
    margin-right: calc(-1 * (var(--distance-max-large) * 1px));
  }
}

.mr-minus-xlarge {
  margin-right: calc(-1 * (var(--distance-min-xlarge) * 1px));
}
@media screen and (min-width: 320px) {
  .mr-minus-xlarge {
    margin-right: calc(-1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .mr-minus-xlarge {
    margin-right: calc(-1 * (var(--distance-max-xlarge) * 1px));
  }
}

.mb-minus-xsmall {
  margin-bottom: calc(-1 * (var(--distance-min-xsmall) * 1px));
}
@media screen and (min-width: 320px) {
  .mb-minus-xsmall {
    margin-bottom: calc(-1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .mb-minus-xsmall {
    margin-bottom: calc(-1 * (var(--distance-max-xsmall) * 1px));
  }
}

.mb-minus-small {
  margin-bottom: calc(-1 * (var(--distance-min-small) * 1px));
}
@media screen and (min-width: 320px) {
  .mb-minus-small {
    margin-bottom: calc(-1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .mb-minus-small {
    margin-bottom: calc(-1 * (var(--distance-max-small) * 1px));
  }
}

.mb-minus-medium {
  margin-bottom: calc(-1 * (var(--distance-min-medium) * 1px));
}
@media screen and (min-width: 320px) {
  .mb-minus-medium {
    margin-bottom: calc(-1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .mb-minus-medium {
    margin-bottom: calc(-1 * (var(--distance-max-medium) * 1px));
  }
}

.mb-minus-large {
  margin-bottom: calc(-1 * (var(--distance-min-large) * 1px));
}
@media screen and (min-width: 320px) {
  .mb-minus-large {
    margin-bottom: calc(-1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .mb-minus-large {
    margin-bottom: calc(-1 * (var(--distance-max-large) * 1px));
  }
}

.mb-minus-xlarge {
  margin-bottom: calc(-1 * (var(--distance-min-xlarge) * 1px));
}
@media screen and (min-width: 320px) {
  .mb-minus-xlarge {
    margin-bottom: calc(-1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .mb-minus-xlarge {
    margin-bottom: calc(-1 * (var(--distance-max-xlarge) * 1px));
  }
}

.ml-minus-xsmall {
  margin-left: calc(-1 * (var(--distance-min-xsmall) * 1px));
}
@media screen and (min-width: 320px) {
  .ml-minus-xsmall {
    margin-left: calc(-1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .ml-minus-xsmall {
    margin-left: calc(-1 * (var(--distance-max-xsmall) * 1px));
  }
}

.ml-minus-small {
  margin-left: calc(-1 * (var(--distance-min-small) * 1px));
}
@media screen and (min-width: 320px) {
  .ml-minus-small {
    margin-left: calc(-1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .ml-minus-small {
    margin-left: calc(-1 * (var(--distance-max-small) * 1px));
  }
}

.ml-minus-medium {
  margin-left: calc(-1 * (var(--distance-min-medium) * 1px));
}
@media screen and (min-width: 320px) {
  .ml-minus-medium {
    margin-left: calc(-1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .ml-minus-medium {
    margin-left: calc(-1 * (var(--distance-max-medium) * 1px));
  }
}

.ml-minus-large {
  margin-left: calc(-1 * (var(--distance-min-large) * 1px));
}
@media screen and (min-width: 320px) {
  .ml-minus-large {
    margin-left: calc(-1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .ml-minus-large {
    margin-left: calc(-1 * (var(--distance-max-large) * 1px));
  }
}

.ml-minus-xlarge {
  margin-left: calc(-1 * (var(--distance-min-xlarge) * 1px));
}
@media screen and (min-width: 320px) {
  .ml-minus-xlarge {
    margin-left: calc(-1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .ml-minus-xlarge {
    margin-left: calc(-1 * (var(--distance-max-xlarge) * 1px));
  }
}

.p-xsmall {
  padding: calc(1 * (var(--distance-min-xsmall) * 1px));
}
@media screen and (min-width: 320px) {
  .p-xsmall {
    padding: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .p-xsmall {
    padding: calc(1 * (var(--distance-max-xsmall) * 1px));
  }
}

.p-small {
  padding: calc(1 * (var(--distance-min-small) * 1px));
}
@media screen and (min-width: 320px) {
  .p-small {
    padding: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .p-small {
    padding: calc(1 * (var(--distance-max-small) * 1px));
  }
}

.p-medium {
  padding: calc(1 * (var(--distance-min-medium) * 1px));
}
@media screen and (min-width: 320px) {
  .p-medium {
    padding: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .p-medium {
    padding: calc(1 * (var(--distance-max-medium) * 1px));
  }
}

.p-large {
  padding: calc(1 * (var(--distance-min-large) * 1px));
}
@media screen and (min-width: 320px) {
  .p-large {
    padding: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .p-large {
    padding: calc(1 * (var(--distance-max-large) * 1px));
  }
}

.p-xlarge {
  padding: calc(1 * (var(--distance-min-xlarge) * 1px));
}
@media screen and (min-width: 320px) {
  .p-xlarge {
    padding: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .p-xlarge {
    padding: calc(1 * (var(--distance-max-xlarge) * 1px));
  }
}

.py-xsmall {
  padding-top: calc(1 * (var(--distance-min-xsmall) * 1px));
  padding-bottom: calc(1 * (var(--distance-min-xsmall) * 1px));
}
@media screen and (min-width: 320px) {
  .py-xsmall {
    padding-top: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .py-xsmall {
    padding-top: calc(1 * (var(--distance-max-xsmall) * 1px));
  }
}
@media screen and (min-width: 320px) {
  .py-xsmall {
    padding-bottom: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .py-xsmall {
    padding-bottom: calc(1 * (var(--distance-max-xsmall) * 1px));
  }
}

.py-small {
  padding-top: calc(1 * (var(--distance-min-small) * 1px));
  padding-bottom: calc(1 * (var(--distance-min-small) * 1px));
}
@media screen and (min-width: 320px) {
  .py-small {
    padding-top: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .py-small {
    padding-top: calc(1 * (var(--distance-max-small) * 1px));
  }
}
@media screen and (min-width: 320px) {
  .py-small {
    padding-bottom: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .py-small {
    padding-bottom: calc(1 * (var(--distance-max-small) * 1px));
  }
}

.py-medium {
  padding-top: calc(1 * (var(--distance-min-medium) * 1px));
  padding-bottom: calc(1 * (var(--distance-min-medium) * 1px));
}
@media screen and (min-width: 320px) {
  .py-medium {
    padding-top: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .py-medium {
    padding-top: calc(1 * (var(--distance-max-medium) * 1px));
  }
}
@media screen and (min-width: 320px) {
  .py-medium {
    padding-bottom: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .py-medium {
    padding-bottom: calc(1 * (var(--distance-max-medium) * 1px));
  }
}

.py-large {
  padding-top: calc(1 * (var(--distance-min-large) * 1px));
  padding-bottom: calc(1 * (var(--distance-min-large) * 1px));
}
@media screen and (min-width: 320px) {
  .py-large {
    padding-top: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .py-large {
    padding-top: calc(1 * (var(--distance-max-large) * 1px));
  }
}
@media screen and (min-width: 320px) {
  .py-large {
    padding-bottom: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .py-large {
    padding-bottom: calc(1 * (var(--distance-max-large) * 1px));
  }
}

.py-xlarge {
  padding-top: calc(1 * (var(--distance-min-xlarge) * 1px));
  padding-bottom: calc(1 * (var(--distance-min-xlarge) * 1px));
}
@media screen and (min-width: 320px) {
  .py-xlarge {
    padding-top: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .py-xlarge {
    padding-top: calc(1 * (var(--distance-max-xlarge) * 1px));
  }
}
@media screen and (min-width: 320px) {
  .py-xlarge {
    padding-bottom: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .py-xlarge {
    padding-bottom: calc(1 * (var(--distance-max-xlarge) * 1px));
  }
}

.px-xsmall {
  padding-left: calc(1 * (var(--distance-min-xsmall) * 1px));
  padding-right: calc(1 * (var(--distance-min-xsmall) * 1px));
}
@media screen and (min-width: 320px) {
  .px-xsmall {
    padding-left: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .px-xsmall {
    padding-left: calc(1 * (var(--distance-max-xsmall) * 1px));
  }
}
@media screen and (min-width: 320px) {
  .px-xsmall {
    padding-right: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .px-xsmall {
    padding-right: calc(1 * (var(--distance-max-xsmall) * 1px));
  }
}

.px-small {
  padding-left: calc(1 * (var(--distance-min-small) * 1px));
  padding-right: calc(1 * (var(--distance-min-small) * 1px));
}
@media screen and (min-width: 320px) {
  .px-small {
    padding-left: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .px-small {
    padding-left: calc(1 * (var(--distance-max-small) * 1px));
  }
}
@media screen and (min-width: 320px) {
  .px-small {
    padding-right: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .px-small {
    padding-right: calc(1 * (var(--distance-max-small) * 1px));
  }
}

.px-medium {
  padding-left: calc(1 * (var(--distance-min-medium) * 1px));
  padding-right: calc(1 * (var(--distance-min-medium) * 1px));
}
@media screen and (min-width: 320px) {
  .px-medium {
    padding-left: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .px-medium {
    padding-left: calc(1 * (var(--distance-max-medium) * 1px));
  }
}
@media screen and (min-width: 320px) {
  .px-medium {
    padding-right: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .px-medium {
    padding-right: calc(1 * (var(--distance-max-medium) * 1px));
  }
}

.px-large {
  padding-left: calc(1 * (var(--distance-min-large) * 1px));
  padding-right: calc(1 * (var(--distance-min-large) * 1px));
}
@media screen and (min-width: 320px) {
  .px-large {
    padding-left: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .px-large {
    padding-left: calc(1 * (var(--distance-max-large) * 1px));
  }
}
@media screen and (min-width: 320px) {
  .px-large {
    padding-right: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .px-large {
    padding-right: calc(1 * (var(--distance-max-large) * 1px));
  }
}

.px-xlarge {
  padding-left: calc(1 * (var(--distance-min-xlarge) * 1px));
  padding-right: calc(1 * (var(--distance-min-xlarge) * 1px));
}
@media screen and (min-width: 320px) {
  .px-xlarge {
    padding-left: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .px-xlarge {
    padding-left: calc(1 * (var(--distance-max-xlarge) * 1px));
  }
}
@media screen and (min-width: 320px) {
  .px-xlarge {
    padding-right: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .px-xlarge {
    padding-right: calc(1 * (var(--distance-max-xlarge) * 1px));
  }
}

.pt-xsmall {
  padding-top: calc(1 * (var(--distance-min-xsmall) * 1px));
}
@media screen and (min-width: 320px) {
  .pt-xsmall {
    padding-top: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .pt-xsmall {
    padding-top: calc(1 * (var(--distance-max-xsmall) * 1px));
  }
}

.pt-small {
  padding-top: calc(1 * (var(--distance-min-small) * 1px));
}
@media screen and (min-width: 320px) {
  .pt-small {
    padding-top: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .pt-small {
    padding-top: calc(1 * (var(--distance-max-small) * 1px));
  }
}

.pt-medium {
  padding-top: calc(1 * (var(--distance-min-medium) * 1px));
}
@media screen and (min-width: 320px) {
  .pt-medium {
    padding-top: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .pt-medium {
    padding-top: calc(1 * (var(--distance-max-medium) * 1px));
  }
}

.pt-large {
  padding-top: calc(1 * (var(--distance-min-large) * 1px));
}
@media screen and (min-width: 320px) {
  .pt-large {
    padding-top: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .pt-large {
    padding-top: calc(1 * (var(--distance-max-large) * 1px));
  }
}

.pt-xlarge {
  padding-top: calc(1 * (var(--distance-min-xlarge) * 1px));
}
@media screen and (min-width: 320px) {
  .pt-xlarge {
    padding-top: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .pt-xlarge {
    padding-top: calc(1 * (var(--distance-max-xlarge) * 1px));
  }
}

.pr-xsmall {
  padding-right: calc(1 * (var(--distance-min-xsmall) * 1px));
}
@media screen and (min-width: 320px) {
  .pr-xsmall {
    padding-right: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .pr-xsmall {
    padding-right: calc(1 * (var(--distance-max-xsmall) * 1px));
  }
}

.pr-small {
  padding-right: calc(1 * (var(--distance-min-small) * 1px));
}
@media screen and (min-width: 320px) {
  .pr-small {
    padding-right: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .pr-small {
    padding-right: calc(1 * (var(--distance-max-small) * 1px));
  }
}

.pr-medium {
  padding-right: calc(1 * (var(--distance-min-medium) * 1px));
}
@media screen and (min-width: 320px) {
  .pr-medium {
    padding-right: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .pr-medium {
    padding-right: calc(1 * (var(--distance-max-medium) * 1px));
  }
}

.pr-large {
  padding-right: calc(1 * (var(--distance-min-large) * 1px));
}
@media screen and (min-width: 320px) {
  .pr-large {
    padding-right: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .pr-large {
    padding-right: calc(1 * (var(--distance-max-large) * 1px));
  }
}

.pr-xlarge {
  padding-right: calc(1 * (var(--distance-min-xlarge) * 1px));
}
@media screen and (min-width: 320px) {
  .pr-xlarge {
    padding-right: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .pr-xlarge {
    padding-right: calc(1 * (var(--distance-max-xlarge) * 1px));
  }
}

.pb-xsmall {
  padding-bottom: calc(1 * (var(--distance-min-xsmall) * 1px));
}
@media screen and (min-width: 320px) {
  .pb-xsmall {
    padding-bottom: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .pb-xsmall {
    padding-bottom: calc(1 * (var(--distance-max-xsmall) * 1px));
  }
}

.pb-small {
  padding-bottom: calc(1 * (var(--distance-min-small) * 1px));
}
@media screen and (min-width: 320px) {
  .pb-small {
    padding-bottom: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .pb-small {
    padding-bottom: calc(1 * (var(--distance-max-small) * 1px));
  }
}

.pb-medium {
  padding-bottom: calc(1 * (var(--distance-min-medium) * 1px));
}
@media screen and (min-width: 320px) {
  .pb-medium {
    padding-bottom: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .pb-medium {
    padding-bottom: calc(1 * (var(--distance-max-medium) * 1px));
  }
}

.pb-large {
  padding-bottom: calc(1 * (var(--distance-min-large) * 1px));
}
@media screen and (min-width: 320px) {
  .pb-large {
    padding-bottom: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .pb-large {
    padding-bottom: calc(1 * (var(--distance-max-large) * 1px));
  }
}

.pb-xlarge {
  padding-bottom: calc(1 * (var(--distance-min-xlarge) * 1px));
}
@media screen and (min-width: 320px) {
  .pb-xlarge {
    padding-bottom: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .pb-xlarge {
    padding-bottom: calc(1 * (var(--distance-max-xlarge) * 1px));
  }
}

.pl-xsmall {
  padding-left: calc(1 * (var(--distance-min-xsmall) * 1px));
}
@media screen and (min-width: 320px) {
  .pl-xsmall {
    padding-left: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .pl-xsmall {
    padding-left: calc(1 * (var(--distance-max-xsmall) * 1px));
  }
}

.pl-small {
  padding-left: calc(1 * (var(--distance-min-small) * 1px));
}
@media screen and (min-width: 320px) {
  .pl-small {
    padding-left: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .pl-small {
    padding-left: calc(1 * (var(--distance-max-small) * 1px));
  }
}

.pl-medium {
  padding-left: calc(1 * (var(--distance-min-medium) * 1px));
}
@media screen and (min-width: 320px) {
  .pl-medium {
    padding-left: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .pl-medium {
    padding-left: calc(1 * (var(--distance-max-medium) * 1px));
  }
}

.pl-large {
  padding-left: calc(1 * (var(--distance-min-large) * 1px));
}
@media screen and (min-width: 320px) {
  .pl-large {
    padding-left: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .pl-large {
    padding-left: calc(1 * (var(--distance-max-large) * 1px));
  }
}

.pl-xlarge {
  padding-left: calc(1 * (var(--distance-min-xlarge) * 1px));
}
@media screen and (min-width: 320px) {
  .pl-xlarge {
    padding-left: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .pl-xlarge {
    padding-left: calc(1 * (var(--distance-max-xlarge) * 1px));
  }
}

.offset_bottom {
  z-index: 60;
}

.pt-small.offset_bottom {
  padding-top: 0;
}
.pt-small.offset_bottom .section-offset__content, .pt-small.offset_bottom > .elementor-container {
  transform: translateY(calc(1 * var(--distance-min-small) * 1px ));
}
@media only screen and (min-width: 320px) and (max-width: 1920px) {
  .pt-small.offset_bottom .section-offset__content, .pt-small.offset_bottom > .elementor-container {
    transform: translateY(calc(1 * ((var(--distance-min-small) * 1px) + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 320px) / 1600))));
  }
}
@media only screen and (min-width: 1920px) {
  .pt-small.offset_bottom .section-offset__content, .pt-small.offset_bottom > .elementor-container {
    transform: translateY(calc(1 * var(--distance-max-small) * 1px ));
  }
}
.pt-small.offset_bottom.mb-small {
  margin-bottom: calc(1 * (var(--distance-min-small) * 1px));
}
@media screen and (min-width: 320px) {
  .pt-small.offset_bottom.mb-small {
    margin-bottom: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .pt-small.offset_bottom.mb-small {
    margin-bottom: calc(1 * (var(--distance-max-small) * 1px));
  }
}
.pt-small.offset_bottom.mb-medium {
  margin-bottom: calc(1 * (var(--distance-min-medium) * 1px));
}
@media screen and (min-width: 320px) {
  .pt-small.offset_bottom.mb-medium {
    margin-bottom: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .pt-small.offset_bottom.mb-medium {
    margin-bottom: calc(1 * (var(--distance-max-medium) * 1px));
  }
}
.pt-small.offset_bottom.mb-large {
  margin-bottom: calc(1 * (var(--distance-min-large) * 1px));
}
@media screen and (min-width: 320px) {
  .pt-small.offset_bottom.mb-large {
    margin-bottom: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .pt-small.offset_bottom.mb-large {
    margin-bottom: calc(1 * (var(--distance-max-large) * 1px));
  }
}

.pt-medium.offset_bottom {
  padding-top: 0;
}
.pt-medium.offset_bottom .section-offset__content, .pt-medium.offset_bottom > .elementor-container {
  transform: translateY(calc(1 * var(--distance-min-medium) * 1px ));
}
@media only screen and (min-width: 320px) and (max-width: 1920px) {
  .pt-medium.offset_bottom .section-offset__content, .pt-medium.offset_bottom > .elementor-container {
    transform: translateY(calc(1 * ((var(--distance-min-medium) * 1px) + (var(--distance-max-medium) - var(--distance-min-medium)) * ((100vw - 320px) / 1600))));
  }
}
@media only screen and (min-width: 1920px) {
  .pt-medium.offset_bottom .section-offset__content, .pt-medium.offset_bottom > .elementor-container {
    transform: translateY(calc(1 * var(--distance-max-medium) * 1px ));
  }
}
.pt-medium.offset_bottom.mb-small {
  margin-bottom: calc(1 * (var(--distance-min-small) * 1px));
}
@media screen and (min-width: 320px) {
  .pt-medium.offset_bottom.mb-small {
    margin-bottom: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .pt-medium.offset_bottom.mb-small {
    margin-bottom: calc(1 * (var(--distance-max-small) * 1px));
  }
}
.pt-medium.offset_bottom.mb-medium {
  margin-bottom: calc(1 * (var(--distance-min-medium) * 1px));
}
@media screen and (min-width: 320px) {
  .pt-medium.offset_bottom.mb-medium {
    margin-bottom: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .pt-medium.offset_bottom.mb-medium {
    margin-bottom: calc(1 * (var(--distance-max-medium) * 1px));
  }
}
.pt-medium.offset_bottom.mb-large {
  margin-bottom: calc(1 * (var(--distance-min-large) * 1px));
}
@media screen and (min-width: 320px) {
  .pt-medium.offset_bottom.mb-large {
    margin-bottom: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .pt-medium.offset_bottom.mb-large {
    margin-bottom: calc(1 * (var(--distance-max-large) * 1px));
  }
}

.pt-large.offset_bottom {
  padding-top: 0;
}
.pt-large.offset_bottom .section-offset__content, .pt-large.offset_bottom > .elementor-container {
  transform: translateY(calc(1 * var(--distance-min-medium) * 1px ));
}
@media only screen and (min-width: 320px) and (max-width: 1920px) {
  .pt-large.offset_bottom .section-offset__content, .pt-large.offset_bottom > .elementor-container {
    transform: translateY(calc(1 * ((var(--distance-min-medium) * 1px) + (var(--distance-max-large) - var(--distance-min-medium)) * ((100vw - 320px) / 1600))));
  }
}
@media only screen and (min-width: 1920px) {
  .pt-large.offset_bottom .section-offset__content, .pt-large.offset_bottom > .elementor-container {
    transform: translateY(calc(1 * var(--distance-max-large) * 1px ));
  }
}
.pt-large.offset_bottom.mb-small {
  margin-bottom: calc(1 * (var(--distance-min-small) * 1px));
}
@media screen and (min-width: 320px) {
  .pt-large.offset_bottom.mb-small {
    margin-bottom: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .pt-large.offset_bottom.mb-small {
    margin-bottom: calc(1 * (var(--distance-max-small) * 1px));
  }
}
.pt-large.offset_bottom.mb-medium {
  margin-bottom: calc(1 * (var(--distance-min-medium) * 1px));
}
@media screen and (min-width: 320px) {
  .pt-large.offset_bottom.mb-medium {
    margin-bottom: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .pt-large.offset_bottom.mb-medium {
    margin-bottom: calc(1 * (var(--distance-max-medium) * 1px));
  }
}
.pt-large.offset_bottom.mb-large {
  margin-bottom: calc(1 * (var(--distance-min-large) * 1px));
}
@media screen and (min-width: 320px) {
  .pt-large.offset_bottom.mb-large {
    margin-bottom: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .pt-large.offset_bottom.mb-large {
    margin-bottom: calc(1 * (var(--distance-max-large) * 1px));
  }
}

.pb-small.offset_top {
  padding-bottom: 0;
}
.pb-small.offset_top .section-offset__content, .pb-small.offset_top > .elementor-container {
  transform: translateY(calc(-1 * var(--distance-min-small) * 1px ));
}
@media only screen and (min-width: 320px) and (max-width: 1920px) {
  .pb-small.offset_top .section-offset__content, .pb-small.offset_top > .elementor-container {
    transform: translateY(calc(-1 * ((var(--distance-min-small) * 1px) + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 320px) / 1600))));
  }
}
@media only screen and (min-width: 1920px) {
  .pb-small.offset_top .section-offset__content, .pb-small.offset_top > .elementor-container {
    transform: translateY(calc(-1 * var(--distance-max-small) * 1px ));
  }
}
.pb-small.offset_top.mt-small {
  margin-top: calc(1 * (var(--distance-min-small) * 1px));
}
@media screen and (min-width: 320px) {
  .pb-small.offset_top.mt-small {
    margin-top: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .pb-small.offset_top.mt-small {
    margin-top: calc(1 * (var(--distance-max-small) * 1px));
  }
}
.pb-small.offset_top.mt-medium {
  margin-top: calc(1 * (var(--distance-min-medium) * 1px));
}
@media screen and (min-width: 320px) {
  .pb-small.offset_top.mt-medium {
    margin-top: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .pb-small.offset_top.mt-medium {
    margin-top: calc(1 * (var(--distance-max-medium) * 1px));
  }
}
.pb-small.offset_top.mt-large {
  margin-top: calc(1 * (var(--distance-min-large) * 1px));
}
@media screen and (min-width: 320px) {
  .pb-small.offset_top.mt-large {
    margin-top: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .pb-small.offset_top.mt-large {
    margin-top: calc(1 * (var(--distance-max-large) * 1px));
  }
}

.pb-medium.offset_top {
  padding-bottom: 0;
}
.pb-medium.offset_top .section-offset__content, .pb-medium.offset_top > .elementor-container {
  transform: translateY(calc(-1 * var(--distance-min-medium) * 1px ));
}
@media only screen and (min-width: 320px) and (max-width: 1920px) {
  .pb-medium.offset_top .section-offset__content, .pb-medium.offset_top > .elementor-container {
    transform: translateY(calc(-1 * ((var(--distance-min-medium) * 1px) + (var(--distance-max-medium) - var(--distance-min-medium)) * ((100vw - 320px) / 1600))));
  }
}
@media only screen and (min-width: 1920px) {
  .pb-medium.offset_top .section-offset__content, .pb-medium.offset_top > .elementor-container {
    transform: translateY(calc(-1 * var(--distance-max-medium) * 1px ));
  }
}
.pb-medium.offset_top.mt-small {
  margin-top: calc(1 * (var(--distance-min-small) * 1px));
}
@media screen and (min-width: 320px) {
  .pb-medium.offset_top.mt-small {
    margin-top: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .pb-medium.offset_top.mt-small {
    margin-top: calc(1 * (var(--distance-max-small) * 1px));
  }
}
.pb-medium.offset_top.mt-medium {
  margin-top: calc(1 * (var(--distance-min-medium) * 1px));
}
@media screen and (min-width: 320px) {
  .pb-medium.offset_top.mt-medium {
    margin-top: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .pb-medium.offset_top.mt-medium {
    margin-top: calc(1 * (var(--distance-max-medium) * 1px));
  }
}
.pb-medium.offset_top.mt-large {
  margin-top: calc(1 * (var(--distance-min-large) * 1px));
}
@media screen and (min-width: 320px) {
  .pb-medium.offset_top.mt-large {
    margin-top: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .pb-medium.offset_top.mt-large {
    margin-top: calc(1 * (var(--distance-max-large) * 1px));
  }
}

.pb-large.offset_top {
  padding-bottom: 0;
}
.pb-large.offset_top .section-offset__content, .pb-large.offset_top > .elementor-container {
  transform: translateY(calc(-1 * var(--distance-min-large) * 1px ));
}
@media only screen and (min-width: 320px) and (max-width: 1920px) {
  .pb-large.offset_top .section-offset__content, .pb-large.offset_top > .elementor-container {
    transform: translateY(calc(-1 * ((var(--distance-min-large) * 1px) + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 320px) / 1600))));
  }
}
@media only screen and (min-width: 1920px) {
  .pb-large.offset_top .section-offset__content, .pb-large.offset_top > .elementor-container {
    transform: translateY(calc(-1 * var(--distance-max-large) * 1px ));
  }
}
.pb-large.offset_top.mt-small {
  margin-top: calc(1 * (var(--distance-min-small) * 1px));
}
@media screen and (min-width: 320px) {
  .pb-large.offset_top.mt-small {
    margin-top: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .pb-large.offset_top.mt-small {
    margin-top: calc(1 * (var(--distance-max-small) * 1px));
  }
}
.pb-large.offset_top.mt-medium {
  margin-top: calc(1 * (var(--distance-min-medium) * 1px));
}
@media screen and (min-width: 320px) {
  .pb-large.offset_top.mt-medium {
    margin-top: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .pb-large.offset_top.mt-medium {
    margin-top: calc(1 * (var(--distance-max-medium) * 1px));
  }
}
.pb-large.offset_top.mt-large {
  margin-top: calc(1 * (var(--distance-min-large) * 1px));
}
@media screen and (min-width: 320px) {
  .pb-large.offset_top.mt-large {
    margin-top: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .pb-large.offset_top.mt-large {
    margin-top: calc(1 * (var(--distance-max-large) * 1px));
  }
}

.top-offset.top-offset_xsmall {
  top: calc(1 * (var(--distance-min-xsmall) * 1px));
}
@media screen and (min-width: 320px) {
  .top-offset.top-offset_xsmall {
    top: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .top-offset.top-offset_xsmall {
    top: calc(1 * (var(--distance-max-xsmall) * 1px));
  }
}
.top-offset.top-offset_small {
  top: calc(1 * (var(--distance-min-small) * 1px));
}
@media screen and (min-width: 320px) {
  .top-offset.top-offset_small {
    top: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .top-offset.top-offset_small {
    top: calc(1 * (var(--distance-max-small) * 1px));
  }
}
.top-offset.top-offset_medium {
  top: calc(1 * (var(--distance-min-medium) * 1px));
}
@media screen and (min-width: 320px) {
  .top-offset.top-offset_medium {
    top: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .top-offset.top-offset_medium {
    top: calc(1 * (var(--distance-max-medium) * 1px));
  }
}
.top-offset.top-offset_large {
  top: calc(1 * (var(--distance-min-large) * 1px));
}
@media screen and (min-width: 320px) {
  .top-offset.top-offset_large {
    top: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .top-offset.top-offset_large {
    top: calc(1 * (var(--distance-max-large) * 1px));
  }
}
.top-offset.top-offset_xlarge {
  top: calc(1 * (var(--distance-min-xlarge) * 1px));
}
@media screen and (min-width: 320px) {
  .top-offset.top-offset_xlarge {
    top: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .top-offset.top-offset_xlarge {
    top: calc(1 * (var(--distance-max-xlarge) * 1px));
  }
}

.form {
  width: 100%;
}

.form__submit {
  margin-top: 1.5em;
}

.form__col {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.form__col_submit {
  margin-top: 1.5em;
  margin-bottom: 0;
}

.form__error, span.wpcf7-not-valid-tip {
  display: block;
  font-size: 12px;
  color: red;
  text-align: left;
  margin-top: 4px;
}

.form__heading {
  margin-top: 0;
  margin-bottom: 1em;
}

div.wpcf7-validation-errors, div.wpcf7-acceptance-missing {
  border-color: red;
  padding: 15px;
  margin: 1.5em 0 0;
  display: none !important;
}

@media only screen and (max-width: 992px) {
  .form__col_submit {
    text-align: left;
  }
}
.gmap {
  width: 100%;
  height: 100%;
}

.gmap__container {
  width: 100%;
  height: 100%;
}

.grid {
  overflow: hidden;
}

.grid__sizer {
  padding: 0 !important;
  margin: 0 !important;
  height: 0 !important;
}

.grid__item {
  display: block;
  width: 100%;
}

.grid_filtered .js-grid__item {
  height: auto !important;
}

@media screen and (max-width: 1360px) {
  .grid {
    margin-left: auto;
    margin-right: auto;
  }
}
@media only screen and (min-width: 992px) {
  .grid__item_desktop-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .grid__item_desktop-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .grid__item_desktop-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .grid__item_desktop-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .grid__item_tablet-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .grid__item_tablet-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .grid__item_tablet-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .grid__item_tablet-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}
@media only screen and (max-width: 767px) {
  .grid__item_mobile-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .grid__item_mobile-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .grid__item_mobile-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .grid__item_mobile-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}
.grid_fluid-1 {
  margin: -1vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-1 {
    margin: -1em;
  }
}

.grid__item_fluid-1 {
  padding: 1vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-1 {
    padding: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-1-fancy:nth-of-type(3) {
    margin-top: 2vw;
  }
}

.grid_fluid-2 {
  margin: -2vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-2 {
    margin: -1em;
  }
}

.grid__item_fluid-2 {
  padding: 2vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-2 {
    padding: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-2-fancy:nth-of-type(3) {
    margin-top: 4vw;
  }
}

.grid_fluid-3 {
  margin: -3vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-3 {
    margin: -1em;
  }
}

.grid__item_fluid-3 {
  padding: 3vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-3 {
    padding: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-3-fancy:nth-of-type(3) {
    margin-top: 6vw;
  }
}

.grid_fluid-4 {
  margin: -4vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-4 {
    margin: -1em;
  }
}

.grid__item_fluid-4 {
  padding: 4vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-4 {
    padding: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-4-fancy:nth-of-type(3) {
    margin-top: 8vw;
  }
}

.grid_fluid-5 {
  margin: -5vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-5 {
    margin: -1em;
  }
}

.grid__item_fluid-5 {
  padding: 5vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-5 {
    padding: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-5-fancy:nth-of-type(3) {
    margin-top: 10vw;
  }
}

.grid_fluid-6 {
  margin: -6vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-6 {
    margin: -1em;
  }
}

.grid__item_fluid-6 {
  padding: 6vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-6 {
    padding: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-6-fancy:nth-of-type(3) {
    margin-top: 12vw;
  }
}

.grid_fluid-7 {
  margin: -7vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-7 {
    margin: -1em;
  }
}

.grid__item_fluid-7 {
  padding: 7vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-7 {
    padding: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-7-fancy:nth-of-type(3) {
    margin-top: 14vw;
  }
}

.grid_fluid-8 {
  margin: -8vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-8 {
    margin: -1em;
  }
}

.grid__item_fluid-8 {
  padding: 8vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-8 {
    padding: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-8-fancy:nth-of-type(3) {
    margin-top: 16vw;
  }
}

.grid_fluid-9 {
  margin: -9vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-9 {
    margin: -1em;
  }
}

.grid__item_fluid-9 {
  padding: 9vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-9 {
    padding: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-9-fancy:nth-of-type(3) {
    margin-top: 18vw;
  }
}

.grid_fluid-10 {
  margin: -10vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-10 {
    margin: -1em;
  }
}

.grid__item_fluid-10 {
  padding: 10vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-10 {
    padding: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-10-fancy:nth-of-type(3) {
    margin-top: 20vw;
  }
}

.grid_fluid-11 {
  margin: -11vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-11 {
    margin: -1em;
  }
}

.grid__item_fluid-11 {
  padding: 11vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-11 {
    padding: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-11-fancy:nth-of-type(3) {
    margin-top: 22vw;
  }
}

.grid_fluid-12 {
  margin: -12vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-12 {
    margin: -1em;
  }
}

.grid__item_fluid-12 {
  padding: 12vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-12 {
    padding: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-12-fancy:nth-of-type(3) {
    margin-top: 24vw;
  }
}

.grid_fluid-13 {
  margin: -13vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-13 {
    margin: -1em;
  }
}

.grid__item_fluid-13 {
  padding: 13vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-13 {
    padding: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-13-fancy:nth-of-type(3) {
    margin-top: 26vw;
  }
}

.grid_fluid-14 {
  margin: -14vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-14 {
    margin: -1em;
  }
}

.grid__item_fluid-14 {
  padding: 14vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-14 {
    padding: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-14-fancy:nth-of-type(3) {
    margin-top: 28vw;
  }
}

.grid_fluid-15 {
  margin: -15vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-15 {
    margin: -1em;
  }
}

.grid__item_fluid-15 {
  padding: 15vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-15 {
    padding: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-15-fancy:nth-of-type(3) {
    margin-top: 30vw;
  }
}

.grid_fluid-16 {
  margin: -16vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-16 {
    margin: -1em;
  }
}

.grid__item_fluid-16 {
  padding: 16vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-16 {
    padding: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-16-fancy:nth-of-type(3) {
    margin-top: 32vw;
  }
}

.grid_fluid-17 {
  margin: -17vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-17 {
    margin: -1em;
  }
}

.grid__item_fluid-17 {
  padding: 17vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-17 {
    padding: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-17-fancy:nth-of-type(3) {
    margin-top: 34vw;
  }
}

.grid_fluid-18 {
  margin: -18vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-18 {
    margin: -1em;
  }
}

.grid__item_fluid-18 {
  padding: 18vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-18 {
    padding: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-18-fancy:nth-of-type(3) {
    margin-top: 36vw;
  }
}

.grid_fluid-19 {
  margin: -19vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-19 {
    margin: -1em;
  }
}

.grid__item_fluid-19 {
  padding: 19vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-19 {
    padding: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-19-fancy:nth-of-type(3) {
    margin-top: 38vw;
  }
}

.grid_fluid-20 {
  margin: -20vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-20 {
    margin: -1em;
  }
}

.grid__item_fluid-20 {
  padding: 20vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-20 {
    padding: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-20-fancy:nth-of-type(3) {
    margin-top: 40vw;
  }
}

.grid_fluid-v-1 {
  margin-top: -1vw;
  margin-bottom: -1vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-v-1 {
    margin-top: -1em;
    margin-bottom: -1em;
  }
}

.grid__item_fluid-v-1 {
  padding-top: 1vw;
  padding-bottom: 1vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-v-1 {
    padding-top: 1em;
    padding-bottom: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-1-fancy:nth-of-type(3) {
    margin-top: 2vw;
  }
}

.grid_fluid-v-2 {
  margin-top: -2vw;
  margin-bottom: -2vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-v-2 {
    margin-top: -1em;
    margin-bottom: -1em;
  }
}

.grid__item_fluid-v-2 {
  padding-top: 2vw;
  padding-bottom: 2vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-v-2 {
    padding-top: 1em;
    padding-bottom: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-2-fancy:nth-of-type(3) {
    margin-top: 4vw;
  }
}

.grid_fluid-v-3 {
  margin-top: -3vw;
  margin-bottom: -3vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-v-3 {
    margin-top: -1em;
    margin-bottom: -1em;
  }
}

.grid__item_fluid-v-3 {
  padding-top: 3vw;
  padding-bottom: 3vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-v-3 {
    padding-top: 1em;
    padding-bottom: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-3-fancy:nth-of-type(3) {
    margin-top: 6vw;
  }
}

.grid_fluid-v-4 {
  margin-top: -4vw;
  margin-bottom: -4vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-v-4 {
    margin-top: -1em;
    margin-bottom: -1em;
  }
}

.grid__item_fluid-v-4 {
  padding-top: 4vw;
  padding-bottom: 4vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-v-4 {
    padding-top: 1em;
    padding-bottom: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-4-fancy:nth-of-type(3) {
    margin-top: 8vw;
  }
}

.grid_fluid-v-5 {
  margin-top: -5vw;
  margin-bottom: -5vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-v-5 {
    margin-top: -1em;
    margin-bottom: -1em;
  }
}

.grid__item_fluid-v-5 {
  padding-top: 5vw;
  padding-bottom: 5vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-v-5 {
    padding-top: 1em;
    padding-bottom: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-5-fancy:nth-of-type(3) {
    margin-top: 10vw;
  }
}

.grid_fluid-v-6 {
  margin-top: -6vw;
  margin-bottom: -6vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-v-6 {
    margin-top: -1em;
    margin-bottom: -1em;
  }
}

.grid__item_fluid-v-6 {
  padding-top: 6vw;
  padding-bottom: 6vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-v-6 {
    padding-top: 1em;
    padding-bottom: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-6-fancy:nth-of-type(3) {
    margin-top: 12vw;
  }
}

.grid_fluid-v-7 {
  margin-top: -7vw;
  margin-bottom: -7vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-v-7 {
    margin-top: -1em;
    margin-bottom: -1em;
  }
}

.grid__item_fluid-v-7 {
  padding-top: 7vw;
  padding-bottom: 7vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-v-7 {
    padding-top: 1em;
    padding-bottom: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-7-fancy:nth-of-type(3) {
    margin-top: 14vw;
  }
}

.grid_fluid-v-8 {
  margin-top: -8vw;
  margin-bottom: -8vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-v-8 {
    margin-top: -1em;
    margin-bottom: -1em;
  }
}

.grid__item_fluid-v-8 {
  padding-top: 8vw;
  padding-bottom: 8vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-v-8 {
    padding-top: 1em;
    padding-bottom: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-8-fancy:nth-of-type(3) {
    margin-top: 16vw;
  }
}

.grid_fluid-v-9 {
  margin-top: -9vw;
  margin-bottom: -9vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-v-9 {
    margin-top: -1em;
    margin-bottom: -1em;
  }
}

.grid__item_fluid-v-9 {
  padding-top: 9vw;
  padding-bottom: 9vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-v-9 {
    padding-top: 1em;
    padding-bottom: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-9-fancy:nth-of-type(3) {
    margin-top: 18vw;
  }
}

.grid_fluid-v-10 {
  margin-top: -10vw;
  margin-bottom: -10vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-v-10 {
    margin-top: -1em;
    margin-bottom: -1em;
  }
}

.grid__item_fluid-v-10 {
  padding-top: 10vw;
  padding-bottom: 10vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-v-10 {
    padding-top: 1em;
    padding-bottom: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-10-fancy:nth-of-type(3) {
    margin-top: 20vw;
  }
}

.grid_fluid-v-11 {
  margin-top: -11vw;
  margin-bottom: -11vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-v-11 {
    margin-top: -1em;
    margin-bottom: -1em;
  }
}

.grid__item_fluid-v-11 {
  padding-top: 11vw;
  padding-bottom: 11vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-v-11 {
    padding-top: 1em;
    padding-bottom: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-11-fancy:nth-of-type(3) {
    margin-top: 22vw;
  }
}

.grid_fluid-v-12 {
  margin-top: -12vw;
  margin-bottom: -12vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-v-12 {
    margin-top: -1em;
    margin-bottom: -1em;
  }
}

.grid__item_fluid-v-12 {
  padding-top: 12vw;
  padding-bottom: 12vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-v-12 {
    padding-top: 1em;
    padding-bottom: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-12-fancy:nth-of-type(3) {
    margin-top: 24vw;
  }
}

.grid_fluid-v-13 {
  margin-top: -13vw;
  margin-bottom: -13vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-v-13 {
    margin-top: -1em;
    margin-bottom: -1em;
  }
}

.grid__item_fluid-v-13 {
  padding-top: 13vw;
  padding-bottom: 13vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-v-13 {
    padding-top: 1em;
    padding-bottom: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-13-fancy:nth-of-type(3) {
    margin-top: 26vw;
  }
}

.grid_fluid-v-14 {
  margin-top: -14vw;
  margin-bottom: -14vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-v-14 {
    margin-top: -1em;
    margin-bottom: -1em;
  }
}

.grid__item_fluid-v-14 {
  padding-top: 14vw;
  padding-bottom: 14vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-v-14 {
    padding-top: 1em;
    padding-bottom: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-14-fancy:nth-of-type(3) {
    margin-top: 28vw;
  }
}

.grid_fluid-v-15 {
  margin-top: -15vw;
  margin-bottom: -15vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-v-15 {
    margin-top: -1em;
    margin-bottom: -1em;
  }
}

.grid__item_fluid-v-15 {
  padding-top: 15vw;
  padding-bottom: 15vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-v-15 {
    padding-top: 1em;
    padding-bottom: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-15-fancy:nth-of-type(3) {
    margin-top: 30vw;
  }
}

.grid_fluid-v-16 {
  margin-top: -16vw;
  margin-bottom: -16vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-v-16 {
    margin-top: -1em;
    margin-bottom: -1em;
  }
}

.grid__item_fluid-v-16 {
  padding-top: 16vw;
  padding-bottom: 16vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-v-16 {
    padding-top: 1em;
    padding-bottom: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-16-fancy:nth-of-type(3) {
    margin-top: 32vw;
  }
}

.grid_fluid-v-17 {
  margin-top: -17vw;
  margin-bottom: -17vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-v-17 {
    margin-top: -1em;
    margin-bottom: -1em;
  }
}

.grid__item_fluid-v-17 {
  padding-top: 17vw;
  padding-bottom: 17vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-v-17 {
    padding-top: 1em;
    padding-bottom: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-17-fancy:nth-of-type(3) {
    margin-top: 34vw;
  }
}

.grid_fluid-v-18 {
  margin-top: -18vw;
  margin-bottom: -18vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-v-18 {
    margin-top: -1em;
    margin-bottom: -1em;
  }
}

.grid__item_fluid-v-18 {
  padding-top: 18vw;
  padding-bottom: 18vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-v-18 {
    padding-top: 1em;
    padding-bottom: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-18-fancy:nth-of-type(3) {
    margin-top: 36vw;
  }
}

.grid_fluid-v-19 {
  margin-top: -19vw;
  margin-bottom: -19vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-v-19 {
    margin-top: -1em;
    margin-bottom: -1em;
  }
}

.grid__item_fluid-v-19 {
  padding-top: 19vw;
  padding-bottom: 19vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-v-19 {
    padding-top: 1em;
    padding-bottom: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-19-fancy:nth-of-type(3) {
    margin-top: 38vw;
  }
}

.grid_fluid-v-20 {
  margin-top: -20vw;
  margin-bottom: -20vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-v-20 {
    margin-top: -1em;
    margin-bottom: -1em;
  }
}

.grid__item_fluid-v-20 {
  padding-top: 20vw;
  padding-bottom: 20vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-v-20 {
    padding-top: 1em;
    padding-bottom: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-20-fancy:nth-of-type(3) {
    margin-top: 40vw;
  }
}

.grid_fluid-h-1 {
  margin-left: -1vw;
  margin-right: -1vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-h-1 {
    margin-left: -1em;
    margin-right: -1em;
  }
}

.grid__item_fluid-h-1 {
  padding-left: 1vw;
  padding-right: 1vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-h-1 {
    padding-left: 1em;
    padding-right: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-1-fancy:nth-of-type(3) {
    margin-top: 2vw;
  }
}

.grid_fluid-h-2 {
  margin-left: -2vw;
  margin-right: -2vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-h-2 {
    margin-left: -1em;
    margin-right: -1em;
  }
}

.grid__item_fluid-h-2 {
  padding-left: 2vw;
  padding-right: 2vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-h-2 {
    padding-left: 1em;
    padding-right: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-2-fancy:nth-of-type(3) {
    margin-top: 4vw;
  }
}

.grid_fluid-h-3 {
  margin-left: -3vw;
  margin-right: -3vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-h-3 {
    margin-left: -1em;
    margin-right: -1em;
  }
}

.grid__item_fluid-h-3 {
  padding-left: 3vw;
  padding-right: 3vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-h-3 {
    padding-left: 1em;
    padding-right: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-3-fancy:nth-of-type(3) {
    margin-top: 6vw;
  }
}

.grid_fluid-h-4 {
  margin-left: -4vw;
  margin-right: -4vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-h-4 {
    margin-left: -1em;
    margin-right: -1em;
  }
}

.grid__item_fluid-h-4 {
  padding-left: 4vw;
  padding-right: 4vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-h-4 {
    padding-left: 1em;
    padding-right: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-4-fancy:nth-of-type(3) {
    margin-top: 8vw;
  }
}

.grid_fluid-h-5 {
  margin-left: -5vw;
  margin-right: -5vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-h-5 {
    margin-left: -1em;
    margin-right: -1em;
  }
}

.grid__item_fluid-h-5 {
  padding-left: 5vw;
  padding-right: 5vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-h-5 {
    padding-left: 1em;
    padding-right: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-5-fancy:nth-of-type(3) {
    margin-top: 10vw;
  }
}

.grid_fluid-h-6 {
  margin-left: -6vw;
  margin-right: -6vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-h-6 {
    margin-left: -1em;
    margin-right: -1em;
  }
}

.grid__item_fluid-h-6 {
  padding-left: 6vw;
  padding-right: 6vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-h-6 {
    padding-left: 1em;
    padding-right: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-6-fancy:nth-of-type(3) {
    margin-top: 12vw;
  }
}

.grid_fluid-h-7 {
  margin-left: -7vw;
  margin-right: -7vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-h-7 {
    margin-left: -1em;
    margin-right: -1em;
  }
}

.grid__item_fluid-h-7 {
  padding-left: 7vw;
  padding-right: 7vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-h-7 {
    padding-left: 1em;
    padding-right: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-7-fancy:nth-of-type(3) {
    margin-top: 14vw;
  }
}

.grid_fluid-h-8 {
  margin-left: -8vw;
  margin-right: -8vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-h-8 {
    margin-left: -1em;
    margin-right: -1em;
  }
}

.grid__item_fluid-h-8 {
  padding-left: 8vw;
  padding-right: 8vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-h-8 {
    padding-left: 1em;
    padding-right: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-8-fancy:nth-of-type(3) {
    margin-top: 16vw;
  }
}

.grid_fluid-h-9 {
  margin-left: -9vw;
  margin-right: -9vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-h-9 {
    margin-left: -1em;
    margin-right: -1em;
  }
}

.grid__item_fluid-h-9 {
  padding-left: 9vw;
  padding-right: 9vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-h-9 {
    padding-left: 1em;
    padding-right: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-9-fancy:nth-of-type(3) {
    margin-top: 18vw;
  }
}

.grid_fluid-h-10 {
  margin-left: -10vw;
  margin-right: -10vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-h-10 {
    margin-left: -1em;
    margin-right: -1em;
  }
}

.grid__item_fluid-h-10 {
  padding-left: 10vw;
  padding-right: 10vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-h-10 {
    padding-left: 1em;
    padding-right: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-10-fancy:nth-of-type(3) {
    margin-top: 20vw;
  }
}

.grid_fluid-h-11 {
  margin-left: -11vw;
  margin-right: -11vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-h-11 {
    margin-left: -1em;
    margin-right: -1em;
  }
}

.grid__item_fluid-h-11 {
  padding-left: 11vw;
  padding-right: 11vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-h-11 {
    padding-left: 1em;
    padding-right: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-11-fancy:nth-of-type(3) {
    margin-top: 22vw;
  }
}

.grid_fluid-h-12 {
  margin-left: -12vw;
  margin-right: -12vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-h-12 {
    margin-left: -1em;
    margin-right: -1em;
  }
}

.grid__item_fluid-h-12 {
  padding-left: 12vw;
  padding-right: 12vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-h-12 {
    padding-left: 1em;
    padding-right: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-12-fancy:nth-of-type(3) {
    margin-top: 24vw;
  }
}

.grid_fluid-h-13 {
  margin-left: -13vw;
  margin-right: -13vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-h-13 {
    margin-left: -1em;
    margin-right: -1em;
  }
}

.grid__item_fluid-h-13 {
  padding-left: 13vw;
  padding-right: 13vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-h-13 {
    padding-left: 1em;
    padding-right: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-13-fancy:nth-of-type(3) {
    margin-top: 26vw;
  }
}

.grid_fluid-h-14 {
  margin-left: -14vw;
  margin-right: -14vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-h-14 {
    margin-left: -1em;
    margin-right: -1em;
  }
}

.grid__item_fluid-h-14 {
  padding-left: 14vw;
  padding-right: 14vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-h-14 {
    padding-left: 1em;
    padding-right: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-14-fancy:nth-of-type(3) {
    margin-top: 28vw;
  }
}

.grid_fluid-h-15 {
  margin-left: -15vw;
  margin-right: -15vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-h-15 {
    margin-left: -1em;
    margin-right: -1em;
  }
}

.grid__item_fluid-h-15 {
  padding-left: 15vw;
  padding-right: 15vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-h-15 {
    padding-left: 1em;
    padding-right: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-15-fancy:nth-of-type(3) {
    margin-top: 30vw;
  }
}

.grid_fluid-h-16 {
  margin-left: -16vw;
  margin-right: -16vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-h-16 {
    margin-left: -1em;
    margin-right: -1em;
  }
}

.grid__item_fluid-h-16 {
  padding-left: 16vw;
  padding-right: 16vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-h-16 {
    padding-left: 1em;
    padding-right: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-16-fancy:nth-of-type(3) {
    margin-top: 32vw;
  }
}

.grid_fluid-h-17 {
  margin-left: -17vw;
  margin-right: -17vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-h-17 {
    margin-left: -1em;
    margin-right: -1em;
  }
}

.grid__item_fluid-h-17 {
  padding-left: 17vw;
  padding-right: 17vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-h-17 {
    padding-left: 1em;
    padding-right: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-17-fancy:nth-of-type(3) {
    margin-top: 34vw;
  }
}

.grid_fluid-h-18 {
  margin-left: -18vw;
  margin-right: -18vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-h-18 {
    margin-left: -1em;
    margin-right: -1em;
  }
}

.grid__item_fluid-h-18 {
  padding-left: 18vw;
  padding-right: 18vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-h-18 {
    padding-left: 1em;
    padding-right: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-18-fancy:nth-of-type(3) {
    margin-top: 36vw;
  }
}

.grid_fluid-h-19 {
  margin-left: -19vw;
  margin-right: -19vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-h-19 {
    margin-left: -1em;
    margin-right: -1em;
  }
}

.grid__item_fluid-h-19 {
  padding-left: 19vw;
  padding-right: 19vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-h-19 {
    padding-left: 1em;
    padding-right: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-19-fancy:nth-of-type(3) {
    margin-top: 38vw;
  }
}

.grid_fluid-h-20 {
  margin-left: -20vw;
  margin-right: -20vw;
}
@media only screen and (max-width: 767px) {
  .grid_fluid-h-20 {
    margin-left: -1em;
    margin-right: -1em;
  }
}

.grid__item_fluid-h-20 {
  padding-left: 20vw;
  padding-right: 20vw;
}
@media only screen and (max-width: 767px) {
  .grid__item_fluid-h-20 {
    padding-left: 1em;
    padding-right: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .grid:not(.grid_filtered) .grid__item_fluid-20-fancy:nth-of-type(3) {
    margin-top: 40vw;
  }
}

.list-backgrounds_striped .list-backgrounds__item:nth-of-type(odd) {
  background-color: var(--color-light-2);
}
.list-backgrounds_striped .list-backgrounds__item:nth-of-type(even) {
  background-color: var(--color-light-1);
}

.list-backgrounds__item {
  position: relative;
  overflow: hidden;
}

.list-backgrounds__wrapper-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.list-backgrounds__wrapper-img > * {
  height: 100%;
}
.list-backgrounds__wrapper-img img {
  transform: scale(1.03);
}

.list-backgrounds__overlay {
  z-index: 1 !important;
}

.list-backgrounds__works-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.list-backgrounds__works-list li {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  transition: all 0.6s ease-in-out;
}
.list-backgrounds__works-list li:first-child {
  margin-top: 0;
}
.list-backgrounds__works-list li:last-child {
  margin-bottom: 0;
}

.list-backgrounds__overlay {
  transition: all 0.6s ease-in-out;
}

.list-backgrounds__item_hover .list-backgrounds__overlay {
  opacity: 0.6;
}

[data-arts-theme-text=light] .list-backgrounds_striped .list-backgrounds__item:nth-of-type(even) {
  background-color: #191919;
}
[data-arts-theme-text=light] .list-backgrounds_striped .list-backgrounds__item:nth-of-type(odd) {
  background-color: #202020;
}

.list-dots {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.list-dots li {
  display: inline-block;
  vertical-align: middle;
  margin-top: 0.5em;
}
.list-dots li:not(:last-child):after {
  content: "";
  display: inline-block;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: var(--color-gray-2);
  vertical-align: middle;
  margin-left: 2.5em;
  margin-right: 2.5em;
  transition: transform 0.6s ease-in-out;
}

@media screen and (max-width: 991px) {
  .list-dots li {
    display: block;
  }
  .list-dots li:first-child {
    margin-top: 0;
  }
  .list-dots li:not(:last-child):after {
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: 1em;
    margin-bottom: 1em;
  }
}
.logo {
  position: relative;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
}

.logo__wrapper-img {
  position: relative;
  align-items: center;
  flex: 1 0 auto;
  height: 100%;
  margin-right: 15px;
}
.logo__wrapper-img img {
  transition: all 0.6s ease-in-out;
  display: block;
}
@media only screen and (max-width: 767px) {
  .logo__wrapper-img img {
    width: 90px;
  }
}

.logo__text-title {
  display: block;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.3;
  color: var(--paragraph-color-dark);
}

.logo__text-tagline {
  display: block;
  font-style: italic;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.3;
}

.logo__img-secondary {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
}

.logo__description {
  margin-top: 1em;
}

.pswp__button {
  outline: none;
}
.pswp__button:focus {
  outline: none;
}

.pswp__button--arrow--left, .pswp__button--arrow--right {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  opacity: 1;
}
.pswp__button--arrow--left:before, .pswp__button--arrow--right:before {
  display: none;
}

.pswp__button--arrow--left {
  left: 20px;
}

.pswp__button--arrow--right {
  right: 20px;
}

.pswp__top-bar {
  padding: 15px 15px 0;
}

.pswp__counter {
  left: 25px;
  top: 15px;
}

.pswp__wrapper-embed {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 120px;
}
.pswp__wrapper-embed iframe {
  width: 100%;
  height: 100%;
}
.pswp__wrapper-embed video {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 991px) {
  .pswp__counter {
    top: 0px;
    left: 10px;
  }
  .pswp__top-bar {
    padding: 0;
  }
  .pswp__button--arrow--left, .pswp__button--arrow--right {
    width: auto;
    height: auto;
    margin-top: 0;
  }
  .pswp__button--arrow--left {
    left: 15px;
  }
  .pswp__button--arrow--right {
    right: 15px;
  }
  .pswp__wrapper-embed {
    padding: 120px 40px;
  }
  .pswp__wrapper-embed iframe {
    width: 100%;
    height: 100%;
  }
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  backface-visibility: hidden;
  pointer-events: none;
}

.overlay_dark {
  background-color: rgba(0, 0, 0, 0.6);
}

.overlay_light {
  background-color: rgba(255, 255, 255, 0.9);
}

.overlay_dark-10 {
  background-color: rgba(0, 0, 0, 0.1);
}

.overlay_dark-20 {
  background-color: rgba(0, 0, 0, 0.2);
}

.overlay_dark-30 {
  background-color: rgba(0, 0, 0, 0.3);
}

.overlay_dark-40 {
  background-color: rgba(0, 0, 0, 0.4);
}

.overlay_dark-50 {
  background-color: rgba(0, 0, 0, 0.5);
}

.overlay_dark-60 {
  background-color: rgba(0, 0, 0, 0.6);
}

.overlay_dark-70 {
  background-color: rgba(0, 0, 0, 0.7);
}

.overlay_dark-80 {
  background-color: rgba(0, 0, 0, 0.8);
}

.overlay_dark-90 {
  background-color: rgba(0, 0, 0, 0.9);
}

.overlay_light-10 {
  background-color: rgba(255, 255, 255, 0.1);
}

.overlay_light-20 {
  background-color: rgba(255, 255, 255, 0.2);
}

.overlay_light-30 {
  background-color: rgba(255, 255, 255, 0.3);
}

.overlay_light-40 {
  background-color: rgba(255, 255, 255, 0.4);
}

.overlay_light-50 {
  background-color: rgba(255, 255, 255, 0.5);
}

.overlay_light-60 {
  background-color: rgba(255, 255, 255, 0.6);
}

.overlay_light-70 {
  background-color: rgba(255, 255, 255, 0.7);
}

.overlay_light-80 {
  background-color: rgba(255, 255, 255, 0.8);
}

.overlay_light-90 {
  background-color: rgba(255, 255, 255, 0.9);
}

.touchevents [data-arts-horizontal-scroll=wrapper] {
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
}

.section {
  position: relative;
}

.section-dynamic-background {
  transition: background-color 0.4s ease;
}

.section_z-100 {
  z-index: 100;
}

.section__content {
  position: relative;
  z-index: 60;
}

.section__headline {
  display: block;
  width: calc(1 * (60 * 1px));
  height: 1px;
  background-color: #000;
}
@media screen and (min-width: 320px) {
  .section__headline {
    width: calc(1 * (60 * 1px + (200 - 60) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .section__headline {
    width: calc(1 * (200 * 1px));
  }
}

.section__headline_left, .section__headline_right {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: 2em 0 0;
  z-index: 60;
  width: calc(1 * (0 * 1px));
}
@media screen and (min-width: 320px) {
  .section__headline_left, .section__headline_right {
    width: calc(1 * (0 * 1px + (200 - 0) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .section__headline_left, .section__headline_right {
    width: calc(1 * (200 * 1px));
  }
}
.section__headline_left + *, .section__headline_right + * {
  margin-top: 0;
}

.container .section__headline_left {
  left: calc(100% - (100vw + 1120px) / 2 - var(--bs-gutter-x));
}
.container .section__headline_right {
  right: calc(100% - (100vw + 1120px) / 2 - var(--bs-gutter-x));
}

.text-start .section__headline {
  margin-left: 0;
  margin-right: auto;
}

.text-center .section__headline {
  margin-left: auto;
  margin-right: auto;
}

.text-end .section__headline {
  margin-left: auto;
  margin-right: 0;
}

.section__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 0;
}

.section__bg-container-right {
  max-width: 100%;
  width: calc(100% - (100vw - 1140px) / 2 - 20px);
  margin-left: auto;
}

.section__bg-container-left {
  max-width: 100%;
  width: calc(100% - (100vw - 1140px) / 2 - 20px);
  margin-right: auto;
}

@media screen and (max-width: 1500px) {
  .section__headline_left, .section__headline_right {
    width: calc(1 * (0 * 1px));
  }
}
@media screen and (max-width: 1500px) and (min-width: 320px) {
  .section__headline_left, .section__headline_right {
    width: calc(1 * (0 * 1px + (100 - 0) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (max-width: 1500px) and (min-width: 1920px) {
  .section__headline_left, .section__headline_right {
    width: calc(1 * (100 * 1px));
  }
}
@media screen and (max-width: 1500px) {
  .section__bg-container-right, .section__bg-container-left {
    width: 100%;
  }
}
@media screen and (max-width: 1500px) {
  .section__headline_left, .section__headline_right {
    display: none !important;
  }
}
@media screen and (max-width: 991px) {
  .section-fullheight__inner_mobile-auto {
    min-height: 0;
    height: 100%;
    display: block;
  }
}
.section-fullheight {
  display: flex;
}

.section-fullheight__inner {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
}

.section-fullheight__inner_mobile {
  height: calc(var(--fix-bar-vh, 1vh) * 100);
  min-height: 0vh;
}

.section-fullheight__header {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
  max-width: 80%;
  z-index: 0;
}

@media screen and (max-width: 991px) {
  .section-fullheight__header {
    position: relative;
    top: auto;
    left: auto;
    max-width: 100%;
  }
  .section-fullheight__inner_mobile-auto {
    height: auto !important;
    min-height: 0 !important;
  }
}
@media screen and (min-width: 990px) {
  .section-fullheight__header {
    padding-top: 0 !important;
  }
}
.section_h-100 {
  height: 100px;
  max-height: 100vh;
}

.section_h-200 {
  height: 200px;
  max-height: 100vh;
}

.section_h-300 {
  height: 300px;
  max-height: 100vh;
}

.section_h-400 {
  height: 400px;
  max-height: 100vh;
}

.section_h-500 {
  height: 500px;
  max-height: 100vh;
}

.section_h-600 {
  height: 600px;
  max-height: 100vh;
}

.section_h-700 {
  height: 700px;
  max-height: 100vh;
}

.section_h-800 {
  height: 800px;
  max-height: 100vh;
}

.section_h-900 {
  height: 900px;
  max-height: 100vh;
}

.section_h-100vh {
  height: 100vh;
}

.section_w-container-right {
  max-width: 100%;
  width: calc(100% - (100vw - 1140px) / 2 - 20px);
  margin-left: auto;
  text-align: left;
}

.section_w-container-left {
  max-width: 100%;
  width: calc(100% - (100vw - 1140px) / 2 - 20px);
  margin-right: auto;
  text-align: right;
}

@media screen and (max-width: 1500px) {
  .section_w-container-right, .section_w-container-left {
    width: 100%;
    text-align: center;
  }
  .section_w-container-right .section-image__caption-vertical-left, .section_w-container-right .section-image__caption-vertical-right, .section_w-container-left .section-image__caption-vertical-left, .section_w-container-left .section-image__caption-vertical-right {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    transform: none;
    padding: 0 20px;
    margin-top: 1em;
    text-align: center;
    width: 100%;
  }
  .section_w-container-right .section-image__caption.text-start, .section_w-container-right .section-image__caption.text-center, .section_w-container-right .section-image__caption.text-end, .section_w-container-left .section-image__caption.text-start, .section_w-container-left .section-image__caption.text-center, .section_w-container-left .section-image__caption.text-end {
    text-align: center !important;
  }
}
@media screen and (max-width: 767px) {
  .section_h-100 {
    max-height: 70vh;
  }
  .section_h-200 {
    max-height: 70vh;
  }
  .section_h-300 {
    max-height: 70vh;
  }
  .section_h-400 {
    max-height: 70vh;
  }
  .section_h-500 {
    max-height: 70vh;
  }
  .section_h-600 {
    max-height: 70vh;
  }
  .section_h-700 {
    max-height: 70vh;
  }
  .section_h-800 {
    max-height: 70vh;
  }
  .section_h-900 {
    max-height: 70vh;
  }
}
.section-offset__content {
  position: relative;
  width: 100%;
  z-index: 50;
}

.slider {
  max-width: 100%;
  width: 100%;
  height: 100%;
}
.slider .swiper-wrapper > .swiper-slide:first-of-type .slider__slide-border {
  display: none;
}
.slider img {
  user-select: none;
  pointer-events: none;
}

.slider__wrapper-button {
  display: inline-block;
}

.slider_vertical-centered .swiper-wrapper {
  align-items: center !important;
}

.slider__slide-border {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: var(--color-border-light);
}

.slider__overlay {
  z-index: 10;
}

.slider__thumb-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 0;
  transition: all 0.6s ease-in-out;
}

.slider__thumb-content {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 40px;
  z-index: 60;
}

.slider__thumb-heading {
  margin-top: 0;
  margin-bottom: 0;
  color: var(--color-gray-3) !important;
  transition: all 0.6s ease-in-out;
}

.slider__thumb {
  position: relative;
  background-color: #fff;
  transition: background-color 0.3s ease;
  overflow: hidden;
}
.slider__thumb.swiper-slide-thumb-active {
  background-color: #f0f0f0;
}
.slider__thumb.swiper-slide-thumb-active .slider__thumb-heading {
  color: var(--color-dark-1) !important;
}

.slider__counter {
  line-height: 1;
  font-weight: 700 !important;
}

.slider__counter_current {
  margin-bottom: 1em;
  color: var(--color-dark-4);
}

.slider__counter_total {
  color: var(--color-gray-1);
}

.slider__arrow {
  width: 50px;
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: none;
  transition: color 0.3s ease;
}
.slider__arrow.swiper-button-disabled {
  user-select: none;
  opacity: 0.3;
  pointer-events: none !important;
}

.slider__wrapper-arrows {
  height: 24px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 0;
  padding-right: 0;
}

.slider__wrapper-arrows_right {
  margin-right: -16px;
}

.slider__wrapper-arrows_vertical {
  width: 24px;
  height: 120px;
  flex-direction: column;
  margin-left: auto;
}

.slider__slide-centered {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.slider__scrollbar {
  position: relative;
  display: inline-block;
  background-color: var(--color-gray-4);
  transition: all 0.6s ease-in-out;
  z-index: 50;
}

.slider__scrollbar_vertical {
  height: 200px;
  width: 2px;
  margin-top: auto;
  margin-bottom: auto;
}

.slider__scrollbar_horizontal {
  width: 200px;
  height: 2px;
  margin-top: auto;
  margin-bottom: auto;
}
.slider__scrollbar_horizontal .slider__scrollbar-handle {
  height: 100%;
}
.slider__scrollbar_horizontal .slider__scrollbar-handle:before {
  top: auto;
  transform: translateX(-50%);
  top: 0;
  left: 50%;
  right: auto;
  margin: 8px 0 0;
}

.slider__scrollbar_horizontal_long {
  width: 360px;
}
.slider__scrollbar_horizontal_long .slider__scrollbar-handle:before {
  display: none;
}

.slider__scrollbar-handle {
  position: relative;
  background-color: var(--color-gray-3);
  color: var(--color-gray-3);
  transition: all 0.6s ease-in-out;
}
.slider__scrollbar-handle:before {
  content: attr(data-content);
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 100%;
  white-space: nowrap;
  margin-right: 20px;
}

.slider__wrapper-scrollbar {
  z-index: 50;
}

.slider__text {
  max-width: 500px;
}

.swiper-wrapper_transition {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
}

.slider__arrow_absolute {
  position: absolute;
  z-index: 60;
}

.container .slider__arrow_left {
  transform: translateX(-150%) translateY(-50%);
  left: 0;
}
.container .slider__arrow_right {
  transform: translateX(150%) translateY(-50%);
  right: 0;
}

.slider__arrow_left {
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  left: var(--gutter-horizontal);
}

.slider__arrow_right {
  top: 50%;
  transform: translateX(50%) translateY(-50%);
  right: var(--gutter-horizontal);
}

@media screen and (max-width: 1500px) {
  .slider__counter_current {
    margin-bottom: 1em;
  }
}
@media screen and (max-width: 991px) {
  .slider__scrollbar_vertical {
    height: 140px;
  }
  .slider__scrollbar_horizontal {
    width: 140px;
  }
  .slider__scrollbar-handle:before {
    font-size: 11px !important;
    margin-right: 10px;
  }
  .slider__wrapper-arrows_vertical {
    width: 80px;
    height: 80px;
  }
  .slider__wrapper-arrows {
    width: 80px;
  }
}
.slider__dots {
  display: inline-flex;
}
.slider__dots .slider__dot:first-child {
  margin-left: 0;
}
.slider__dots .slider__dot:last-child {
  margin-left: 0;
}

.slider__dots_vertical {
  flex-direction: column;
  height: auto;
  vertical-align: initial;
}
.slider__dots_vertical .slider__dot {
  margin: 4px 0;
}

.slider__dot {
  position: relative;
  width: 32px;
  height: 32px;
  margin: 0 6px;
  cursor: pointer;
  outline: none;
  display: inline-block;
  border-radius: 100%;
  vertical-align: middle;
  z-index: 50;
}
.slider__dot svg {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  stroke-width: 4px;
  z-index: 50;
}
.slider__dot svg .circle {
  stroke: rgb(104, 104, 104);
}
.slider__dot:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background-color: rgba(104, 104, 104, 0.5);
  transition: all 1s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.slider__dot:hover:after {
  background-color: rgb(104, 104, 104);
}

.slider__dot_active {
  pointer-events: none;
}
.slider__dot_active:after {
  background-color: rgb(104, 104, 104);
}

@media screen and (max-width: 767px) {
  .slider__dot {
    width: 20px;
    height: 20px;
    margin: 0 4px;
  }
  .slider__dot svg {
    stroke-width: 8px;
    width: 20px;
    height: 20px;
  }
  .slider__dots_vertical .slider__dot {
    margin: 4px 0;
  }
}
.social {
  display: inline-block;
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.social .social__item {
  display: inline-block;
  transition: none;
  margin-bottom: 0;
  margin-right: 15px;
}
@media screen and (max-width: 1360px) {
  .social .social__item {
    margin-right: 15px;
  }
}
@media screen and (max-width: 1199px) {
  .social .social__item {
    margin-right: 8px;
  }
}
.social .social__item a {
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-size: 20px;
  border-radius: 50%;
  background-color: #fff;
  color: var(--color-dark-1);
}
@media screen and (max-width: 1199px) {
  .social .social__item a {
    font-size: 18px;
    width: 38px;
    height: 38px;
  }
}
.social .social__item a:hover {
  background: transparent;
  color: #fff;
}

.smooth-scroll {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

[data-arts-os-animation]:not([data-arts-os-animation=animated]) > *, [data-arts-os-animation][data-arts-os-animation-name]:not([data-arts-os-animation=animated]) {
  opacity: 0;
  visibility: hidden;
  pointer-events: none !important;
}
[data-arts-os-animation]:not([data-arts-os-animation=animated]) > * *, [data-arts-os-animation][data-arts-os-animation-name]:not([data-arts-os-animation=animated]) * {
  pointer-events: none !important;
}

body.elementor-editor-active [data-arts-os-animation] {
  opacity: 1;
  visibility: visible;
}

[data-arts-scroll-fixed] {
  width: 100% !important;
  left: 0 !important;
  top: 0 !important;
}

.swatch__figure {
  display: inline-block;
  border-radius: 50%;
}

.swatch__figure_color {
  width: 120px;
  height: 120px;
}

.swatch__header {
  display: block;
  width: 100%;
}

.swatch__subheading {
  color: var(--color-gray-3);
}

[data-arts-theme-text=light] {
  color: var(--color-gray-2);
}
[data-arts-theme-text=light] .xxl {
  color: var(--xxl-color-light);
}
[data-arts-theme-text=light] .xl, [data-arts-theme-text=light] .slider__counter {
  color: var(--xl-color-light);
}
[data-arts-theme-text=light] h1, [data-arts-theme-text=light] .h1 {
  color: var(--h1-color-light);
}
[data-arts-theme-text=light] h2, [data-arts-theme-text=light] .h2 {
  color: var(--h2-color-light);
}
[data-arts-theme-text=light] h3, [data-arts-theme-text=light] .h3, [data-arts-theme-text=light] .comments-title, [data-arts-theme-text=light] .comment-reply-title {
  color: var(--h3-color-light);
}
[data-arts-theme-text=light] h4, [data-arts-theme-text=light] .h4, [data-arts-theme-text=light] .blog-internal .editor .col-12 > p:first-of-type:not(blockquote p), .blog-internal .editor [data-arts-theme-text=light] .col-12 > p:first-of-type:not(blockquote p), [data-arts-theme-text=light] .new-style a h2, .new-style a [data-arts-theme-text=light] h2, [data-arts-theme-text=light] .new-style summary h2, .new-style summary [data-arts-theme-text=light] h2, [data-arts-theme-text=light] details a h2, details a [data-arts-theme-text=light] h2, [data-arts-theme-text=light] details summary h2, details summary [data-arts-theme-text=light] h2, [data-arts-theme-text=light] .tabs.style-2 .tablinks, .tabs.style-2 [data-arts-theme-text=light] .tablinks {
  color: var(--h4-color-light);
}
[data-arts-theme-text=light] h5, [data-arts-theme-text=light] .h5, [data-arts-theme-text=light] .blog-internal .editor .col-12 > p:nth-of-type(2):not(blockquote p), .blog-internal .editor [data-arts-theme-text=light] .col-12 > p:nth-of-type(2):not(blockquote p), [data-arts-theme-text=light] .content-number .text-inside, .content-number [data-arts-theme-text=light] .text-inside {
  color: var(--h5-color-light);
}
[data-arts-theme-text=light] h6, [data-arts-theme-text=light] .h6, [data-arts-theme-text=light] .breadcrumbs, [data-arts-theme-text=light] .blog-internal .editor p, .blog-internal .editor [data-arts-theme-text=light] p, [data-arts-theme-text=light] .filtros .todos, .filtros [data-arts-theme-text=light] .todos, [data-arts-theme-text=light] .filtros option, .filtros [data-arts-theme-text=light] option {
  color: var(--h6-color-light);
}
[data-arts-theme-text=light] p, [data-arts-theme-text=light] .paragraph {
  color: var(--paragraph-color-light);
}
[data-arts-theme-text=light] .subheading, [data-arts-theme-text=light] .block-counter__counter {
  color: var(--subheading-color-light);
}
[data-arts-theme-text=light] blockquote, [data-arts-theme-text=light] .blockquote {
  color: var(--blockquote-color-light);
}
[data-arts-theme-text=light] blockquote p, [data-arts-theme-text=light] .blockquote p {
  color: var(--blockquote-color-light);
}
[data-arts-theme-text=light] blockquote cite, [data-arts-theme-text=light] .blockquote cite {
  color: var(--blockquote-color-light);
}
[data-arts-theme-text=light] a, [data-arts-theme-text=light] .button {
  color: var(--color-light-1);
}
[data-arts-theme-text=light] a:hover, [data-arts-theme-text=light] .button:hover {
  color: #fff;
}
[data-arts-theme-text=light] .button.button_bordered:hover {
  border-color: #fff;
}
[data-arts-theme-text=light] .header__label {
  color: #fff;
}
[data-arts-theme-text=light] .header__overlay-menu-back:hover {
  color: var(--color-gray-1);
}
[data-arts-theme-text=light] .breadcrumbs li:not(:last-child):after, [data-arts-theme-text=light] .categories li:not(:last-child):after {
  color: #fff;
}
[data-arts-theme-text=light] .slider__scrollbar {
  background-color: var(--color-gray-3);
}
[data-arts-theme-text=light] .slider__scrollbar-handle {
  background-color: #fff;
  color: #fff;
}
[data-arts-theme-text=light] .slider__counter_current {
  color: #fff;
}
[data-arts-theme-text=light] .slider__arrow:hover {
  color: #fff;
}
[data-arts-theme-text=light] .slider__dot svg .circle {
  stroke: #fff;
}
[data-arts-theme-text=light] .slider__dot {
  border-color: var(--color-border-light);
}
[data-arts-theme-text=light] .slider__dot:after {
  background-color: var(--color-border-light);
}
[data-arts-theme-text=light] .slider__dot:hover:after {
  background-color: #fff;
}
[data-arts-theme-text=light] .slider__dot_active:after {
  background-color: #fff;
}
[data-arts-theme-text=light] .section__headline {
  background-color: #fff;
}
[data-arts-theme-text=light] .post-meta li:not(:last-child):after {
  color: #dadada;
}

table {
  border-collapse: collapse;
  width: 100%;
}

thead th {
  border-bottom: 1px solid var(--color-gray-1);
  padding-bottom: 0.5em;
}

th {
  padding: 1em;
  text-align: left;
}

tr {
  border-bottom: 1px solid var(--color-border-dark);
}

td {
  padding: 1em;
}

th:first-child, td:first-child {
  padding-left: 0;
}

th:last-child, td:last-child {
  padding-right: 0;
}

.table_no-outer-borders {
  table-layout: fixed;
  border-collapse: collapse;
  border-style: hidden;
}
.table_no-outer-borders tr {
  border-collapse: collapse;
  border-style: hidden;
  border: 1px solid var(--color-border-dark);
}
.table_no-outer-borders td {
  padding: 2em;
  border-collapse: collapse;
  border: 1px solid var(--color-border-dark);
}

@media screen and (max-width: 576px) {
  .table-xs-flex {
    display: block;
  }
  .table-xs-flex tbody, .table-xs-flex tr, .table-xs-flex td {
    display: block;
  }
  .table-xs-flex.table_no-outer-borders tr {
    border: none;
  }
  .table-xs-flex.table_no-outer-borders td {
    padding: 2em;
    border-left: none;
    border-right: none;
    border-top: none;
  }
  .table-xs-flex.table_no-outer-borders tr:first-child td:first-child {
    margin-top: -2em;
  }
  .table-xs-flex.table_no-outer-borders tr:last-child td:last-child {
    border-bottom: none;
    margin-bottom: -2em;
  }
}
.transition-curtain {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: scaleY(0);
  transform-origin: bottom center;
  margin: auto;
  z-index: 400;
  pointer-events: none;
}

h1, h2, h3 {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

h4, h5, h6 {
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}

.xxl {
  font-family: var(--font-primary);
  font-weight: normal;
  line-height: var(--xxl-line-height);
  font-size: calc(var(--xxl-min-font-size) * 1px);
  color: var(--xxl-color-dark);
  letter-spacing: calc(var(--xxl-letter-spacing) * 1px);
}
@media screen and (min-width: 320px) {
  .xxl {
    font-size: calc(var(--xxl-min-font-size) * 1px + (var(--xxl-max-font-size) - var(--xxl-min-font-size)) * ((100vw - 320px) / 1600));
  }
}
@media screen and (min-width: 1920px) {
  .xxl {
    font-size: calc(var(--xxl-max-font-size) * 1px);
  }
}

.xl, .slider__counter {
  font-family: var(--font-primary);
  font-weight: normal;
  line-height: var(--xl-line-height);
  font-size: calc(var(--xl-min-font-size) * 1px);
  color: var(--xl-color-dark);
  letter-spacing: calc(var(--xl-letter-spacing) * 1px);
}
@media screen and (min-width: 320px) {
  .xl, .slider__counter {
    font-size: calc(var(--xl-min-font-size) * 1px + (var(--xl-max-font-size) - var(--xl-min-font-size)) * ((100vw - 320px) / 1600));
  }
}
@media screen and (min-width: 1920px) {
  .xl, .slider__counter {
    font-size: calc(var(--xl-max-font-size) * 1px);
  }
}

h1, .h1 {
  font-family: var(--font-primary);
  font-weight: 500;
  line-height: var(--h1-line-height);
  font-size: calc(var(--h1-min-font-size) * 1px);
  color: var(--h1-color-dark);
  letter-spacing: calc(var(--h1-letter-spacing) * 1px);
}
@media screen and (min-width: 320px) {
  h1, .h1 {
    font-size: calc(var(--h1-min-font-size) * 1px + (var(--h1-max-font-size) - var(--h1-min-font-size)) * ((100vw - 320px) / 1600));
  }
}
@media screen and (min-width: 1920px) {
  h1, .h1 {
    font-size: calc(var(--h1-max-font-size) * 1px);
  }
}

h2, .h2 {
  font-family: var(--font-primary);
  font-weight: normal;
  line-height: var(--h2-line-height);
  font-size: calc(var(--h2-min-font-size) * 1px);
  color: var(--h2-color-dark);
  letter-spacing: calc(var(--h2-letter-spacing) * 1px);
}
@media screen and (min-width: 320px) {
  h2, .h2 {
    font-size: calc(var(--h2-min-font-size) * 1px + (var(--h2-max-font-size) - var(--h2-min-font-size)) * ((100vw - 320px) / 1600));
  }
}
@media screen and (min-width: 1920px) {
  h2, .h2 {
    font-size: calc(var(--h2-max-font-size) * 1px);
  }
}

h3, .h3, .comments-title, .comment-reply-title {
  font-family: var(--font-primary);
  font-weight: normal;
  line-height: var(--h3-line-height);
  font-size: calc(var(--h3-min-font-size) * 1px);
  color: var(--h3-color-dark);
  letter-spacing: calc(var(--h3-letter-spacing) * 1px);
}
@media screen and (min-width: 320px) {
  h3, .h3, .comments-title, .comment-reply-title {
    font-size: calc(var(--h3-min-font-size) * 1px + (var(--h3-max-font-size) - var(--h3-min-font-size)) * ((100vw - 320px) / 1600));
  }
}
@media screen and (min-width: 1920px) {
  h3, .h3, .comments-title, .comment-reply-title {
    font-size: calc(var(--h3-max-font-size) * 1px);
  }
}

h4, .h4, .blog-internal .editor .col-12 > p:first-of-type:not(blockquote p), .new-style a h2, .new-style summary h2, details a h2, details summary h2, .tabs.style-2 .tablinks {
  font-family: var(--font-primary);
  font-weight: bold;
  line-height: var(--h4-line-height);
  font-size: calc(var(--h4-min-font-size) * 1px);
  color: var(--h4-color-dark);
  letter-spacing: calc(var(--h4-letter-spacing) * 1px);
}
@media screen and (min-width: 320px) {
  h4, .h4, .blog-internal .editor .col-12 > p:first-of-type:not(blockquote p), .new-style a h2, .new-style summary h2, details a h2, details summary h2, .tabs.style-2 .tablinks {
    font-size: calc(var(--h4-min-font-size) * 1px + (var(--h4-max-font-size) - var(--h4-min-font-size)) * ((100vw - 320px) / 1600));
  }
}
@media screen and (min-width: 1920px) {
  h4, .h4, .blog-internal .editor .col-12 > p:first-of-type:not(blockquote p), .new-style a h2, .new-style summary h2, details a h2, details summary h2, .tabs.style-2 .tablinks {
    font-size: calc(var(--h4-max-font-size) * 1px);
  }
}

h5, .h5, .blog-internal .editor .col-12 > p:nth-of-type(2):not(blockquote p), .content-number .text-inside {
  font-family: var(--font-primary);
  font-weight: normal;
  line-height: var(--h5-line-height);
  font-size: calc(var(--h5-min-font-size) * 1px);
  color: var(--h5-color-dark);
  letter-spacing: calc(var(--h5-letter-spacing) * 1px);
}
@media screen and (min-width: 320px) {
  h5, .h5, .blog-internal .editor .col-12 > p:nth-of-type(2):not(blockquote p), .content-number .text-inside {
    font-size: calc(var(--h5-min-font-size) * 1px + (var(--h5-max-font-size) - var(--h5-min-font-size)) * ((100vw - 320px) / 1600));
  }
}
@media screen and (min-width: 1920px) {
  h5, .h5, .blog-internal .editor .col-12 > p:nth-of-type(2):not(blockquote p), .content-number .text-inside {
    font-size: calc(var(--h5-max-font-size) * 1px);
  }
}

h6, .h6, .breadcrumbs, .blog-internal .editor p, .filtros .todos, .filtros option {
  font-family: var(--font-primary);
  font-weight: normal;
  line-height: var(--h6-line-height);
  font-size: calc(var(--h6-min-font-size) * 1px);
  color: var(--h6-color-dark);
  letter-spacing: calc(var(--h6-letter-spacing) * 1px);
}
@media screen and (min-width: 320px) {
  h6, .h6, .breadcrumbs, .blog-internal .editor p, .filtros .todos, .filtros option {
    font-size: calc(var(--h6-min-font-size) * 1px + (var(--h6-max-font-size) - var(--h6-min-font-size)) * ((100vw - 320px) / 1600));
  }
}
@media screen and (min-width: 1920px) {
  h6, .h6, .breadcrumbs, .blog-internal .editor p, .filtros .todos, .filtros option {
    font-size: calc(var(--h6-max-font-size) * 1px);
  }
}

p {
  margin-top: 1em;
  margin-bottom: 1em;
  font-weight: 400;
  font-size: calc(var(--paragraph-min-font-size) * 1px);
  line-height: var(--paragraph-line-height);
  letter-spacing: calc(var(--paragraph-letter-spacing) * 1px);
  color: var(--paragraph-color-dark);
}
@media screen and (min-width: 320px) {
  p {
    font-size: calc(var(--paragraph-min-font-size) * 1px + (var(--paragraph-max-font-size) - var(--paragraph-min-font-size)) * ((100vw - 320px) / 1600));
  }
}
@media screen and (min-width: 1920px) {
  p {
    font-size: calc(var(--paragraph-max-font-size) * 1px);
  }
}

.paragraph {
  font-weight: 400;
  font-size: calc(var(--paragraph-min-font-size) * 1px);
  line-height: var(--paragraph-line-height);
  letter-spacing: calc(var(--paragraph-letter-spacing) * 1px);
  color: var(--paragraph-color-dark);
}
@media screen and (min-width: 320px) {
  .paragraph {
    font-size: calc(var(--paragraph-min-font-size) * 1px + (var(--paragraph-max-font-size) - var(--paragraph-min-font-size)) * ((100vw - 320px) / 1600));
  }
}
@media screen and (min-width: 1920px) {
  .paragraph {
    font-size: calc(var(--paragraph-max-font-size) * 1px);
  }
}

blockquote, .blockquote {
  display: block;
  font-size: calc(var(--blockquote-min-font-size) * 1px);
  line-height: 1.5;
  letter-spacing: calc(var(--blockquote-letter-spacing) * 1px);
  margin-top: 2em;
  margin-bottom: 2em;
  color: var(--blockquote-color-dark);
}
@media screen and (min-width: 320px) {
  blockquote, .blockquote {
    font-size: calc(var(--blockquote-min-font-size) * 1px + (var(--blockquote-max-font-size) - var(--blockquote-min-font-size)) * ((100vw - 320px) / 1600));
  }
}
@media screen and (min-width: 1920px) {
  blockquote, .blockquote {
    font-size: calc(var(--blockquote-max-font-size) * 1px);
  }
}
blockquote p, .blockquote p {
  margin-top: 0;
  font-size: calc(var(--blockquote-min-font-size) * 1px);
  line-height: 1.5;
  font-weight: 500;
  color: var(--blockquote-color-dark);
}
@media screen and (min-width: 320px) {
  blockquote p, .blockquote p {
    font-size: calc(var(--blockquote-min-font-size) * 1px + (var(--blockquote-max-font-size) - var(--blockquote-min-font-size)) * ((100vw - 320px) / 1600));
  }
}
@media screen and (min-width: 1920px) {
  blockquote p, .blockquote p {
    font-size: calc(var(--blockquote-max-font-size) * 1px);
  }
}
blockquote p:last-child, blockquote p:last-of-type, .blockquote p:last-child, .blockquote p:last-of-type {
  margin-bottom: 0;
}
blockquote cite, .blockquote cite {
  display: block;
  margin-top: 1em;
  font-size: calc(var(--paragraph-min-font-size) * 1px);
  line-height: var(--paragraph-line-height);
  color: var(--paragraph-color-dark);
  font-style: normal;
  font-weight: 700;
}
@media screen and (min-width: 320px) {
  blockquote cite, .blockquote cite {
    font-size: calc(var(--paragraph-min-font-size) * 1px + (var(--paragraph-max-font-size) - var(--paragraph-min-font-size)) * ((100vw - 320px) / 1600));
  }
}
@media screen and (min-width: 1920px) {
  blockquote cite, .blockquote cite {
    font-size: calc(var(--paragraph-max-font-size) * 1px);
  }
}

blockquote {
  padding: 60px;
  background: var(--color-orange);
  position: relative;
}
@media only screen and (max-width: 991px) {
  blockquote {
    padding: 40px 60px 40px 40px;
  }
}
blockquote *, blockquote p {
  color: var(--color-dark-1);
}
blockquote:before {
  content: "";
  position: absolute;
  top: -1px;
  right: 0;
  width: 100px;
  height: 100px;
  background: var(--color-light-1);
  clip-path: polygon(0 0, 100% 0, 100% 100%);
}
blockquote p {
  width: 100% !important;
}

small, .small, .comment-edit-link, .comment-reply-link, .comment-metadata, .slider__scrollbar-handle:before {
  font-weight: 500;
  font-size: 16px;
}

.small-2 {
  font-size: 15px;
  font-weight: 500;
}

.small-3 {
  font-size: 14px;
  font-weight: 500;
}

.subheading, .block-counter__counter {
  font-weight: normal;
  line-height: var(--subheading-light-height);
  font-size: calc(var(--subheading-min-font-size) * 1px);
  color: var(--subheading-color-dark);
  letter-spacing: calc(var(--subheading-letter-spacing) * 1px);
}
@media screen and (min-width: 320px) {
  .subheading, .block-counter__counter {
    font-size: calc(var(--subheading-min-font-size) * 1px + (var(--subheading-max-font-size) - var(--subheading-min-font-size)) * ((100vw - 320px) / 1600));
  }
}
@media screen and (min-width: 1920px) {
  .subheading, .block-counter__counter {
    font-size: calc(var(--subheading-max-font-size) * 1px);
  }
}

.has-drop-cap:not(:focus):not(.has-drop-cap_split):first-letter {
  float: left;
  font-size: calc(var(--dropcap-min-font-size) * 1px);
  color: var(--dropcap-color-dark);
  line-height: 0.7;
  font-style: normal;
  margin: 0.15em 0.25em 0 0;
  font-weight: 100;
}
@media screen and (min-width: 320px) {
  .has-drop-cap:not(:focus):not(.has-drop-cap_split):first-letter {
    font-size: calc(var(--dropcap-min-font-size) * 1px + (var(--dropcap-max-font-size) - var(--dropcap-min-font-size)) * ((100vw - 320px) / 1600));
  }
}
@media screen and (min-width: 1920px) {
  .has-drop-cap:not(:focus):not(.has-drop-cap_split):first-letter {
    font-size: calc(var(--dropcap-max-font-size) * 1px);
  }
}
.has-drop-cap:not(:focus):not(.has-drop-cap_split):first-letter:after {
  content: "";
  display: table;
  clear: both;
}
.has-drop-cap:not(:focus):not(.has-drop-cap_split):first-letter:after {
  content: "";
  display: table;
  clear: both;
}

.arts-split-text:not(.js-arts-split-text) .has-drop-cap > div:first-child {
  display: inline-block !important;
  float: left;
}
.arts-split-text:not(.js-arts-split-text) .has-drop-cap > div:first-child:after {
  content: "";
  display: table;
  clear: both;
}
.arts-split-text:not(.js-arts-split-text) .has-drop-cap:first-letter {
  margin: unset;
  float: unset;
  font-size: unset;
  text-transform: unset;
}

.arts-split-text__line .overflow.d-inline-block {
  vertical-align: top;
}

.split-li-wrapper {
  display: inline-block;
  vertical-align: middle;
}

.has-split-list-items li:before {
  transform-origin: left center;
  transform: scaleX(0);
}
.has-split-list-items li:after {
  transform-origin: center center;
  transform: scale(0);
}

.has-split-list-items_animated li:before, .has-split-list-items_animated li:after {
  transition: transform 1s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-delay: 0.3s;
  transform: scale(1);
}

.strong {
  font-weight: bold;
}

.em {
  font-style: italic;
}

@media screen and (max-width: 576px) {
  blockquote {
    padding-left: 1em;
  }
}
.fw-400 {
  font-weight: 400;
}

.overflow {
  position: relative;
  overflow: hidden;
}
.overflow.d-inline-block {
  vertical-align: middle;
}

.overflow-initial {
  overflow: initial;
}

.position-relative {
  position: relative;
}

.position-fixed {
  position: fixed;
}

.of-cover {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  font-family: "object-fit: cover;";
}

.of-contain {
  width: 100%;
  height: 100%;
  object-fit: contain;
  font-family: "object-fit: contain;";
}

.hidden {
  opacity: 0;
  visibility: hidden;
}

.no-gutters {
  padding-left: 0;
  padding-right: 0;
}

.grayscale {
  filter: grayscale(70%);
}

.w-100 {
  width: 100% !important;
}

.w-100vh {
  width: 100vh !important;
}

.w-100vw {
  width: 100vw !important;
}

.h-100 {
  height: 100% !important;
}

.h-100vh {
  height: 100vh !important;
}

.h-100vw {
  height: 100vw !important;
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.backgroundblendmode .blend-difference {
  mix-blend-mode: difference;
  color: #fff;
}
.backgroundblendmode .blend-difference a {
  color: #fff;
}
.backgroundblendmode .blend-difference a:hover {
  opacity: 0.7;
}

.hidden_absolute {
  position: absolute;
  top: -9999px;
  left: -9999px;
  opacity: 0;
  visibility: hidden;
}

#js-webgl {
  display: none;
}

.d-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.d-flex-top {
  display: flex;
  align-items: center;
  justify-content: start;
}
@media only screen and (max-width: 767px) {
  .d-flex-top {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.d-flex-bottom {
  display: flex;
  align-items: center;
  justify-content: end;
}
@media only screen and (max-width: 767px) {
  .d-flex-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.z-50 {
  z-index: 50;
}

.z-100 {
  z-index: 100;
}

.z-500 {
  z-index: 500;
}

.z-1000 {
  z-index: 1000;
}

.block-circle {
  border-radius: 100%;
}

.block-counter {
  padding-left: 0.75em;
  padding-right: 0.75em;
  position: relative;
}

.block-counter__counter {
  position: absolute;
  top: -6px;
  left: calc(100% - 6px);
  letter-spacing: 0;
  white-space: nowrap;
}

.color-rose {
  color: var(--color-rose) !important;
}

.color-orange {
  color: var(--color-orange) !important;
}

.bg-rose {
  background: var(--color-rose);
  color: #fff !important;
}

.bg-blue {
  background: var(--color-blue);
  color: #fff !important;
}

.bg-orange {
  background: var(--color-orange);
  color: #fff !important;
}

.bg-degraded-1 {
  background: var(--color-dark-1);
}

.bg-degraded-2 {
  background: var(--color-dark-1);
}

.bg-cover {
  background-size: cover;
}

.view-all {
  color: var(--color-rose) !important;
  text-transform: uppercase;
  text-decoration: underline;
  font-size: 15px;
  font-weight: 600;
}

.strong_orange b, .strong_orange strong {
  color: var(--color-orange);
}

.strong_rose.underline b {
  text-decoration: underline;
  color: var(--color-rose);
}
.strong_rose b {
  color: var(--color-rose);
}

.section__headline.horizontal {
  width: 1px !important;
  height: 100%;
}

b, strong {
  font-weight: bold;
}

@media screen and (min-width: 992px) {
  .h-lg-100 {
    height: 100% !important;
  }
  .h-lg-100vh {
    height: 100vh !important;
  }
  .h-lg-100vh-fix-bar {
    height: calc(var(--fix-bar-vh, 1vh) * 100) !important;
  }
  .h-lg-100vw {
    width: 100vw !important;
  }
  .row-wide {
    margin-left: -50px;
    margin-right: -50px;
  }
  .col-wide {
    padding-left: 50px;
    padding-right: 50px;
  }
}
.pointer-events-none {
  pointer-events: none !important;
}
.pointer-events-none *:not(.pointer-events-auto) {
  pointer-events: none !important;
}

.pointer-events-auto, .pointer-events-none .pointer-events-auto {
  pointer-events: auto !important;
}
.pointer-events-auto *, .pointer-events-none .pointer-events-auto * {
  pointer-events: auto !important;
}

.swiper-slide:not(.swiper-slide-active) .pointer-events-auto, .swiper-slide:not(.swiper-slide-active) .pointer-events-none .pointer-events-auto {
  pointer-events: none !important;
}
.swiper-slide:not(.swiper-slide-active) .pointer-events-auto *, .swiper-slide:not(.swiper-slide-active) .pointer-events-none .pointer-events-auto * {
  pointer-events: none !important;
}

.border-radius-100 {
  border-radius: 100%;
}

.grayscale {
  filter: grayscale(70%);
}

.border-none {
  border: none !important;
}

.widget {
  margin-bottom: 2em;
  font-size: 16px;
}
.widget p {
  font-size: 16px;
}
.widget select {
  width: 100%;
  max-width: 100%;
}
.widget ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: left;
}
.widget ul li {
  display: block;
  margin-bottom: 0.5em;
}
.widget ul li:last-child {
  margin-bottom: 0;
}
.widget ul ul {
  width: 100%;
  padding-left: 1em;
  margin-top: 1em;
}
.widget ul .sub-menu {
  padding-left: 1em;
  margin-top: 1em;
}

.widget:not(.widget_kinsey_cta) p:last-of-type {
  margin-bottom: 0;
}

.widgettitle {
  display: block;
  font-size: 16px;
  font-weight: 500;
  color: var(--color-dark-1);
  line-height: var(--paragraph-line-height);
  margin-top: 0;
  margin-bottom: 1em;
}
.widgettitle:after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: var(--color-dark-1);
  margin-bottom: 0.5em;
}

.widget-area_no-margin-last-widget > *:last-child {
  margin-bottom: 0;
}

.widget_archive ul li {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.widget_archive ul li span {
  font-size: 14px;
  line-height: 1;
}

#wp-calendar {
  width: 100%;
  text-align: center;
}
#wp-calendar thead {
  border-bottom: 1px solid var(--color-border-dark);
}
#wp-calendar caption {
  caption-side: top;
  width: 100%;
  text-align: center;
  padding-top: 0;
  padding-bottom: 10px;
}
#wp-calendar th {
  font-weight: 600;
  padding: 5px;
  text-align: center;
}
#wp-calendar td {
  padding: 5px;
}
#wp-calendar td#next {
  text-align: right;
}
#wp-calendar td#prev {
  text-align: left;
}
#wp-calendar tbody a:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  z-index: -1;
  transition: all 0.6s ease-in-out;
}
#wp-calendar tr {
  border-bottom: none;
}
#wp-calendar a {
  display: inline-block;
  position: relative;
  z-index: 50;
}

.wp-calendar-nav {
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 100%;
}

.widget_categories ul li {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.widget_categories ul li span {
  font-size: 14px;
  line-height: 1;
}

.widget_kinsey_menu_inline ul li {
  display: inline-block;
  margin-bottom: 0;
}
.widget_kinsey_menu_inline ul li a {
  padding: 4px;
}
.widget_kinsey_menu_inline ul li a:before {
  display: none;
}

@media screen and (max-width: 991px) {
  .widget_kinsey_menu_inline .menu {
    text-align: center;
  }
}
.widget_polylang {
  display: inline-block;
  font-size: 14px;
}
.widget_polylang select {
  width: auto;
  display: inline-block;
  padding: 5px 25px 5px 15px;
}
.widget_polylang ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.widget_polylang ul li {
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 0;
}

.widget_rss .rsswidget {
  font-weight: 500;
}
.widget_rss .rss-date {
  display: block;
  width: 100%;
  font-size: 13px;
  margin-top: 0.5em;
  margin-bottom: 1em;
}
.widget_rss .rssSummary {
  margin-top: 1em;
  margin-bottom: 1em;
}
.widget_rss ul > li {
  border-bottom: 1px solid var(--color-border-dark);
  padding-bottom: 1em;
}
.widget_rss ul > li:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.widget_recent_comments ul li {
  padding: 20px 0;
  margin-bottom: 0 !important;
  border-bottom: 1px solid var(--color-border-dark);
}
.widget_recent_comments ul li:first-child {
  padding-top: 0;
}
.widget_recent_comments ul li:last-child {
  padding-bottom: 0;
  border-bottom: none;
}
.widget_recent_comments ul li a {
  font-weight: bold;
}
.widget_recent_comments .comment-author-link a {
  font-weight: normal;
}

.widget_recent_entries .post-date {
  display: block;
  width: 100%;
  font-size: 13px;
  font-weight: 500;
  margin-top: 0.25em;
  color: var(--color-gray-3);
}
.widget_recent_entries ul li {
  padding: 20px 0;
  margin-bottom: 0 !important;
  border-bottom: 1px solid var(--color-border-dark);
}
.widget_recent_entries ul li a {
  font-weight: 500;
  line-height: 1.5;
}
.widget_recent_entries ul li a span {
  display: inline-block;
}
.widget_recent_entries ul li:first-child {
  padding-top: 0;
}
.widget_recent_entries ul li:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

.widget_icl_lang_sel_widget {
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
  font-size: 14px;
}
.widget_icl_lang_sel_widget .wpml-ls-legacy-dropdown {
  width: auto;
  border: 1px solid var(--color-border-dark);
  border-radius: 2px;
  padding: 2px 5px;
}
.widget_icl_lang_sel_widget .wpml-ls-legacy-dropdown a {
  border: none;
  background: transparent;
}
.widget_icl_lang_sel_widget .wpml-ls-legacy-dropdown a:hover, .widget_icl_lang_sel_widget .wpml-ls-legacy-dropdown a:focus {
  opacity: 1;
}
.widget_icl_lang_sel_widget .wpml-ls-legacy-dropdown a:hover, .widget_icl_lang_sel_widget .wpml-ls-legacy-dropdown a:focus, .widget_icl_lang_sel_widget .wpml-ls-legacy-dropdown .wpml-ls-current-language:hover > a {
  background: transparent;
}
.widget_icl_lang_sel_widget .wpml-ls-sub-menu {
  border-top: none;
}
.widget_icl_lang_sel_widget .wpml-ls-legacy-list-horizontal {
  padding: 2px 5px;
}
.widget_icl_lang_sel_widget .wpml-ls-legacy-list-horizontal .wpml-ls-item {
  display: inline-block;
  margin-bottom: 0;
}

.lang-switch-no-padding-right .widget_icl_lang_sel_widget .wpml-ls-legacy-list-horizontal {
  padding-right: 0;
}

.author {
  display: flex;
  width: 100%;
}

.author__name {
  font-size: 13px;
  line-height: 1.2;
  font-weight: 500;
  color: var(--color-gray-3);
}

.author__avatar {
  overflow: hidden;
  border-radius: 50%;
  margin-right: 1em;
}

.gallery {
  margin-top: 2em;
  margin-bottom: 2em;
}

.gallery-item {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  width: 100%;
}

.gallery-columns-2 .gallery-item {
  max-width: 50%;
}

.gallery-columns-3 .gallery-item {
  max-width: 33.33%;
}

.gallery-columns-4 .gallery-item {
  max-width: 25%;
}

.gallery-columns-5 .gallery-item {
  max-width: 20%;
}

.gallery-columns-6 .gallery-item {
  max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
  max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
  max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
  max-width: 11.11%;
}

.gallery-caption {
  display: block;
}

.figure-post.hover-zoom-underline .figure-post__media-link {
  transform: scale(1.05);
}

.figure-post_card {
  overflow: hidden;
  border: 1px solid var(--color-border-dark);
  border-radius: 4px;
}
.figure-post_card:hover {
  border-color: transparent;
}

.figure-post__media {
  overflow: hidden;
  position: relative;
}

.figure-post__date {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  will-change: transform;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(1 * (70 * 1px));
  height: calc(1 * (70 * 1px));
  z-index: 50;
  text-align: center;
}
@media screen and (min-width: 320px) {
  .figure-post__date {
    width: calc(1 * (70 * 1px + (100 - 70) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .figure-post__date {
    width: calc(1 * (100 * 1px));
  }
}
@media screen and (min-width: 320px) {
  .figure-post__date {
    height: calc(1 * (70 * 1px + (100 - 70) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .figure-post__date {
    height: calc(1 * (100 * 1px));
  }
}

.text-center .figure-post__date {
  right: 0;
  margin: 0 auto;
}

.figure-post__date_centered {
  right: 0;
  margin: 0 auto;
}

.figure-post__date-day {
  display: block;
  font-weight: normal;
  line-height: 1;
}

.figure-post__date-month {
  display: block;
  line-height: 1;
}

.figure-post__media-link {
  display: block;
  transition: all 0.6s ease-in-out;
  transform-origin: center center;
}

.figure-post__date_small {
  width: calc(1 * (60 * 1px));
  height: calc(1 * (60 * 1px));
}
@media screen and (min-width: 320px) {
  .figure-post__date_small {
    width: calc(1 * (60 * 1px + (80 - 60) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .figure-post__date_small {
    width: calc(1 * (80 * 1px));
  }
}
@media screen and (min-width: 320px) {
  .figure-post__date_small {
    height: calc(1 * (60 * 1px + (80 - 60) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .figure-post__date_small {
    height: calc(1 * (80 * 1px));
  }
}

.figure-post__header a.underline-hover__target {
  transition-property: background-size, color;
}

@media screen and (max-width: 576px) {
  .figure-post_card {
    padding: 20px !important;
  }
}
.pagination {
  border-top: 2px solid var(--color-border-dark);
  padding: 10px 0 0;
  font-weight: 500;
}
.pagination .nav-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pagination .nav-links__container {
  margin-left: auto;
  margin-right: auto;
}
.pagination .page-numbers {
  display: inline-block;
  line-height: 1;
  padding: 12px 20px;
  background-image: none;
  vertical-align: middle;
  transition-property: color;
}
.pagination .page-numbers.prev {
  font-size: 24px;
  font-weight: normal;
  margin-left: -20px;
}
.pagination .page-numbers.next {
  font-size: 24px;
  font-weight: normal;
  margin-right: -20px;
}
.pagination .page-numbers:not(a) {
  color: var(--color-dark-4);
}
.pagination .page-numbers.current {
  color: var(--color-dark-4);
}

.page-links {
  border-top: 2px solid var(--color-border-dark);
  padding: 10px 0 0;
}
.page-links .page-number {
  display: inline-block;
  line-height: 1;
  font-weight: 600;
  padding: 0 15px;
  border: none;
}
.page-links .post-page-numbers:not(a) {
  color: var(--color-dark-4);
}

.comments-pagination {
  text-align: center;
}
.comments-pagination .page-numbers {
  display: inline-block;
  line-height: 1;
  font-weight: 600;
  padding: 0 10px;
  transition-property: color;
}

@media screen and (max-width: 991px) {
  .pagination {
    padding: 8px 0 0;
  }
  .page-links {
    padding: 8px 0 0;
  }
}
.post__tags {
  border-top: 1px solid var(--color-border-dark);
  margin-top: 2em;
  padding-top: 2em;
}

.post__content > *:first-child:not(.section__headline), .post__comments > *:first-child:not(.section__headline), .section-content__heading > *:first-child:not(.section__headline), .section-content__text > *:first-child:not(.section__headline), .content > *:first-child:not(.section__headline) {
  margin-top: 0;
}
.post__content > *:last-child:not(.section__headline), .post__comments > *:last-child:not(.section__headline), .section-content__heading > *:last-child:not(.section__headline), .section-content__text > *:last-child:not(.section__headline), .content > *:last-child:not(.section__headline) {
  margin-bottom: 0;
}
.post__content ul, .post__comments ul, .section-content__heading ul, .section-content__text ul, .content ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-bottom: 1.5em;
}
.post__content ul li, .post__comments ul li, .section-content__heading ul li, .section-content__text ul li, .content ul li {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
}
.post__content ul ul, .post__content ul ol, .post__comments ul ul, .post__comments ul ol, .section-content__heading ul ul, .section-content__heading ul ol, .section-content__text ul ul, .section-content__text ul ol, .content ul ul, .content ul ol {
  padding-left: 1em;
}
.post__content ul:not(.wp-block-gallery), .post__comments ul:not(.wp-block-gallery), .section-content__heading ul:not(.wp-block-gallery), .section-content__text ul:not(.wp-block-gallery), .content ul:not(.wp-block-gallery) {
  padding-left: 1.5em;
}
.post__content ul:not(.wp-block-gallery) li:before, .post__comments ul:not(.wp-block-gallery) li:before, .section-content__heading ul:not(.wp-block-gallery) li:before, .section-content__text ul:not(.wp-block-gallery) li:before, .content ul:not(.wp-block-gallery) li:before {
  content: "";
  display: inline-block;
  width: 1em;
  height: 2px;
  vertical-align: middle;
  margin-right: 0.5em;
  background-color: var(--color-gray-1);
}
.post__content ul:not(.wp-block-gallery) li > span, .post__comments ul:not(.wp-block-gallery) li > span, .section-content__heading ul:not(.wp-block-gallery) li > span, .section-content__text ul:not(.wp-block-gallery) li > span, .content ul:not(.wp-block-gallery) li > span {
  vertical-align: top !important;
}
.post__content ol:not(.comment-list), .post__comments ol:not(.comment-list), .section-content__heading ol:not(.comment-list), .section-content__text ol:not(.comment-list), .content ol:not(.comment-list) {
  margin-bottom: 24px;
  padding-left: 1.25em;
}
.post__content ol:not(.comment-list) li, .post__comments ol:not(.comment-list) li, .section-content__heading ol:not(.comment-list) li, .section-content__text ol:not(.comment-list) li, .content ol:not(.comment-list) li {
  display: list-item;
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}
.post__content ol:not(.comment-list) li > span, .post__comments ol:not(.comment-list) li > span, .section-content__heading ol:not(.comment-list) li > span, .section-content__text ol:not(.comment-list) li > span, .content ol:not(.comment-list) li > span {
  vertical-align: top !important;
}
.post__content ol:not(.comment-list) ul, .post__content ol:not(.comment-list) ol, .post__comments ol:not(.comment-list) ul, .post__comments ol:not(.comment-list) ol, .section-content__heading ol:not(.comment-list) ul, .section-content__heading ol:not(.comment-list) ol, .section-content__text ol:not(.comment-list) ul, .section-content__text ol:not(.comment-list) ol, .content ol:not(.comment-list) ul, .content ol:not(.comment-list) ol {
  padding-left: 1.25em;
}

.post__content > ul, .comment-content > ul, .section-content__heading > ul, .section-content__text > ul {
  padding-left: 0 !important;
}

.comments-title, .comment-reply-title {
  margin-top: 0;
  margin-bottom: 0;
}

.comment-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  padding-left: 0 !important;
}
.comment-list > li {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}
.comment-list > li ol.children {
  list-style-type: none;
  padding: 0;
  margin: 0;
  padding-left: 4%;
}
.comment-list > li ol.children li {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}
.comment-list > li ol.children li:last-child {
  padding-bottom: 0;
}
.comment-list > li:not(:last-child) {
  border-bottom: 1px solid var(--color-border-dark);
}
.comment-list > ol {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.comment-author {
  max-width: 100px;
  margin-right: 2em;
  flex: 0 0 auto;
}
.comment-author .avatar {
  position: relative;
  width: 100%;
  max-width: 80px;
  max-height: 80px;
  border-radius: 100%;
  display: block;
}

.comment-meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5em;
}

.comment-metadata {
  margin-left: 10px;
}
.comment-metadata a {
  border-bottom: none !important;
}

.comment-body {
  display: flex;
}
.comment-body .fn {
  font-family: var(--font-primary);
  font-weight: 700;
  line-height: var(--h5-line-height);
  font-size: calc(var(--h5-min-font-size) * 1px);
  color: var(--h5-color-dark);
}
@media screen and (min-width: 320px) {
  .comment-body .fn {
    font-size: calc(var(--h5-min-font-size) * 1px + (var(--h5-max-font-size) - var(--h5-min-font-size)) * ((100vw - 320px) / 1600));
  }
}
@media screen and (min-width: 1920px) {
  .comment-body .fn {
    font-size: calc(var(--h5-max-font-size) * 1px);
  }
}

.comment-content {
  width: 100%;
}

.reply {
  display: inline-block;
  line-height: 1;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.comment-reply-link {
  border-bottom: none !important;
  margin-right: 24px;
}
.comment-reply-link:before {
  content: "\e15e";
  font-family: "Material Icons";
  text-transform: none;
  margin-right: 8px;
}

.comment-respond {
  margin-top: 1em;
}

.comment-edit-link {
  color: var(--color-gray-3);
  border-bottom: none !important;
}
.comment-edit-link:before {
  content: "\e3c9";
  font-family: "Material Icons";
  text-transform: none;
  margin-right: 6px;
}

.trackback .edit-link, .pingback .edit-link {
  margin-left: 0.5em;
}

@media only screen and (max-width: 991px) {
  .comment-author {
    margin-right: 1em;
  }
  .comment-author .avatar {
    max-width: 50px;
    max-height: 50px;
  }
  .comment-meta {
    flex-wrap: wrap;
  }
  .comment-metadata {
    margin-top: 0;
    margin-left: 0;
    width: 100%;
  }
}
.post-meta {
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-size: 13px;
  line-height: 1;
  font-weight: 500;
}
.post-meta i {
  font-size: 14px !important;
  vertical-align: middle;
  margin-right: 4px;
}
.post-meta li {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  line-height: 1.6;
  vertical-align: middle;
}
.post-meta li span {
  vertical-align: middle;
}
.post-meta li:not(:last-child):after {
  content: "/";
  color: var(--color-dark-1);
  display: inline-block;
  margin-left: 6px;
  margin-right: 5px;
  transition: all 0.6s ease-in-out;
}
.post-meta ul {
  padding-left: 0;
}
.post-meta a {
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
}

.post-meta_block li {
  display: block;
  margin: 0.5em 0;
}
.post-meta_block li:not(:last-child):after {
  display: none;
}

.post-meta_flex {
  display: flex;
  justify-content: space-between;
}
.post-meta_flex li:not(:last-child):after {
  display: none;
}

.post-meta__divider {
  display: inline-block;
  vertical-align: middle;
  margin: 0 1em;
  width: calc(1 * (60 * 1px));
  height: 1px;
  background-color: var(--color-border-dark);
}
@media screen and (min-width: 320px) {
  .post-meta__divider {
    width: calc(1 * (60 * 1px + (100 - 60) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .post-meta__divider {
    width: calc(1 * (100 * 1px));
  }
}

.post-meta_mini li:after {
  display: none !important;
}

.post-meta_mini-small li:after {
  display: none !important;
}
.post-meta_mini-small .post-meta__divider {
  width: 50px;
}

.tagcloud a, .widget .tagcloud a {
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
  background-color: transparent;
  padding: 8px 14px;
  margin-bottom: 6px;
  margin-right: 4px;
  border-radius: 100px;
  background-color: var(--color-light-3);
}
.tagcloud a:hover, .widget .tagcloud a:hover {
  border-color: var(--color-dark-1);
}
.tagcloud ul, .widget .tagcloud ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.tagcloud ul li, .widget .tagcloud ul li {
  display: inline-block;
  margin-bottom: 0;
}

@media screen and (max-width: 991px) {
  .form-contact {
    padding-left: var(--bs-gutter-x);
    padding-right: var(--bs-gutter-x);
  }
}
.input-float {
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 0;
}

.input-float__label {
  position: absolute;
  top: 0.75em;
  left: 0;
  margin: auto;
  display: block;
  font-size: 16px;
  transition: all 0.6s ease-in-out;
  transform-origin: left center;
  cursor: text;
  color: var(--color-gray-3);
}

.input-float__input {
  display: block;
  width: 100%;
  width: 100%;
  border-bottom: 1px solid var(--color-border-dark);
  border-top: none;
  border-right: none;
  border-left: none;
  outline: none;
  padding: 10px 0 4px;
  transition: all 0.6s ease-in-out;
  background-color: transparent;
  border-radius: 0;
  color: inherit;
}

.input-float__input_textarea {
  resize: none;
  height: 200px;
}

.input-float__input_focused + .input-float__label, .input-float__input_not-empty + .input-float__label {
  transform: scale(0.8571) translateY(-1.75rem);
}

.input-float__input_focused {
  border-color: var(--color-dark-1);
}
.input-float__input_focused + .input-float__label {
  color: var(--color-dark-1);
}

.input-search__input {
  padding-right: 30px;
}

.input-search__submit {
  display: inline-block;
  font-size: 18px;
  width: 24px;
  height: 24px;
  padding: 0;
  position: absolute;
  right: 0;
  top: 12px;
  bottom: 0;
  margin: auto;
  background: transparent;
  border: none;
  color: var(--color-gray-3);
  outline: none;
  transition: all 0.6s ease-in-out;
  cursor: pointer;
}
.input-search__submit:hover {
  color: var(--color-dark-1);
}
.input-search__submit:focus {
  outline: none;
}

.footer {
  color: #000;
  z-index: 100;
  padding-top: calc(1 * (60 * 1px));
  padding-bottom: calc(1 * (20 * 1px));
  background: url(../img/new/img_footer.png) no-repeat center center;
  background-color: var(--color-dark-1);
  background-size: contain;
  background-position: bottom right;
}
@media screen and (min-width: 320px) {
  .footer {
    padding-top: calc(1 * (60 * 1px + (140 - 60) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .footer {
    padding-top: calc(1 * (140 * 1px));
  }
}
@media screen and (min-width: 320px) {
  .footer {
    padding-bottom: calc(1 * (20 * 1px + (40 - 20) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .footer {
    padding-bottom: calc(1 * (40 * 1px));
  }
}
.footer a {
  color: #000;
}

.footer .form {
  width: 100%;
  padding-bottom: calc(1 * (60 * 1px));
}
@media screen and (min-width: 320px) {
  .footer .form {
    padding-bottom: calc(1 * (60 * 1px + (140 - 60) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .footer .form {
    padding-bottom: calc(1 * (140 * 1px));
  }
}
.footer .form p {
  display: flex;
  width: 100%;
}
.footer .form .wpcf7-form-control-wrap {
  width: calc(100% - 160px);
}
.footer .form .wpcf7-form-control-wrap input {
  width: 100%;
  border: none;
  border-bottom: 1px solid var(--color-light-1);
  background: transparent;
  height: 100%;
  font-size: calc(1 * (18 * 1px));
  color: var(--color-light-1);
}
@media screen and (min-width: 320px) {
  .footer .form .wpcf7-form-control-wrap input {
    font-size: calc(1 * (18 * 1px + (22 - 18) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .footer .form .wpcf7-form-control-wrap input {
    font-size: calc(1 * (22 * 1px));
  }
}
.footer .form .wpcf7-submit {
  color: var(--color-light-1) !important;
  font-weight: 600;
  border: none;
  background: var(--color-dark-1) url("../img/new/flecha_larga.svg") no-repeat;
  background-position: calc(100% - 15px) center;
  background-size: 40px auto;
  padding: 10px 45px 10px 25px !important;
  font-size: 18px !important;
  transition: all 0.4s ease;
  border-radius: 0;
  width: 160px;
  margin-left: calc(1 * (30 * 1px));
  cursor: pointer;
  opacity: 1;
  text-align: left;
}
@media screen and (min-width: 320px) {
  .footer .form .wpcf7-submit {
    margin-left: calc(1 * (30 * 1px + (60 - 30) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .footer .form .wpcf7-submit {
    margin-left: calc(1 * (60 * 1px));
  }
}
.footer .form .wpcf7-submit:hover {
  opacity: 0.9;
  transition: all 0.4s ease;
}
.footer .form .wpcf7-spinner {
  position: absolute;
}
.footer .form .wpcf7 .wpcf7-response-output {
  border: none !important;
  padding-left: 0px !important;
  font-weight: 600 !important;
  color: var(--color-light-1) !important;
  font-size: 18px !important;
  margin-top: 10px !important;
  margin-left: 0px !important;
}
.footer .form input, .footer .form textarea {
  border-radius: 0;
}
.footer .form ::placeholder {
  color: var(--color-light-1) !important;
  font-size: calc(var(--h3-min-font-size) * 1px);
  font-weight: 400;
}
@media screen and (min-width: 320px) {
  .footer .form ::placeholder {
    font-size: calc(var(--h3-min-font-size) * 1px + (var(--h3-max-font-size) - var(--h3-min-font-size)) * ((100vw - 320px) / 1600));
  }
}
@media screen and (min-width: 1920px) {
  .footer .form ::placeholder {
    font-size: calc(var(--h3-max-font-size) * 1px);
  }
}
.footer .form .wpcf7-not-valid-tip {
  display: none;
}

[data-arts-footer-logo=primary] .logo__img-primary {
  opacity: 1;
  visibility: visible;
}
[data-arts-footer-logo=primary] .logo__img-secondary {
  opacity: 0;
  visibility: hidden;
}

[data-arts-footer-logo=secondary] .logo__img-primary {
  opacity: 0;
  visibility: hidden;
}
[data-arts-footer-logo=secondary] .logo__img-secondary {
  opacity: 1;
  visibility: visible;
}

.preloader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 400;
  overflow: hidden;
}

.preloader_header-hidden + #page-header {
  transition: opacity 0.3s ease;
}
.preloader_header-hidden:not(.preloader_ended) + #page-header {
  opacity: 0;
  visibility: hidden;
  transform: translateY(50px);
}

.preloader_header-menu-hidden + #page-header .menu, .preloader_header-menu-hidden + #page-header .header__burger {
  transition: opacity 0.3s ease;
}
.preloader_header-menu-hidden:not(.preloader_ended) + #page-header .menu, .preloader_header-menu-hidden:not(.preloader_ended) + #page-header .header__burger {
  opacity: 0;
  visibility: hidden;
}

.preloader:not(.preloader_ended) + #page-header {
  pointer-events: none !important;
}
.preloader:not(.preloader_ended) + #page-header * {
  pointer-events: none !important;
}

.preloader__wrapper {
  display: flex;
  flex-wrap: wrap;
  position: fixed;
  top: var(--gutter-vertical, 120px);
  left: var(--gutter-horizontal, 120px);
  right: var(--gutter-horizontal, 120px);
  bottom: calc(var(--fix-bar-vh, 30px) + var(--gutter-vertical, 100px));
}

.preloader__counter {
  font-family: var(--font-primary);
  font-weight: 100;
  line-height: 1;
  font-size: calc(96 * 1px);
  letter-spacing: -10px;
  white-space: nowrap;
}
@media screen and (min-width: 320px) {
  .preloader__counter {
    font-size: calc(96 * 1px + (212 - 96) * ((100vw - 320px) / 1600));
  }
}
@media screen and (min-width: 1920px) {
  .preloader__counter {
    font-size: calc(212 * 1px);
  }
}

.underline.preloader__counter_started {
  animation-name: loading;
  animation-duration: 20s;
  transition: background-size 1.2s ease;
}
.underline.preloader__counter_paused {
  animation-play-state: paused;
}
.underline.preloader__counter_ended {
  animation-duration: 1s;
}

@keyframes loading {
  0% {
    background-size: 0% 2px;
  }
  100% {
    background-size: 100% 2px;
  }
}
.lazy-wrapper {
  display: inline-block;
  width: 100%;
  vertical-align: middle;
}

.header {
  pointer-events: none;
  padding-top: 10px;
}
.header a, .header select, .header input {
  pointer-events: initial;
}
.header:not(.opened) .header__label-burger_closed-hover, .header:not(.opened) .header__label-burger_opened-open, .header:not(.opened) .header__label-burger_opened-hover {
  transform: translateY(100%);
  opacity: 0;
}
.header:not(.opened) .header__burger:hover .header__label-burger_closed-open {
  transform: translateY(-100%);
  opacity: 0;
}
.header:not(.opened) .header__burger:hover .header__label-burger_closed-hover {
  transform: translateY(0%);
  opacity: 1;
}
.header.opened .header__label-burger_closed-open, .header.opened .header__label-burger_closed-hover, .header.opened .header__label-burger_opened-hover {
  transform: translateY(100%);
  opacity: 0;
}
.header.opened .header__burger:hover .header__label-burger_opened-open {
  transform: translateY(-100%);
  opacity: 0;
}
.header.opened .header__burger:hover .header__label-burger_opened-hover {
  transform: translateY(0%);
  opacity: 1;
}

.cursorfollower .header__overlay-menu-back[data-arts-cursor] .header__label {
  pointer-events: none !important;
  transition: all 0.3s ease !important;
}
.cursorfollower .header__overlay-menu-back[data-arts-cursor]:hover .header__label_status {
  transform: translate(25px, -100%);
  opacity: 0;
}
.cursorfollower .header__overlay-menu-back[data-arts-cursor]:hover .header__label_opened-back {
  transform: translate(25px, -50%);
  opacity: 1;
}
.cursorfollower .header__burger[data-arts-cursor] .header__burger-label {
  pointer-events: none !important;
}
.cursorfollower .header__burger[data-arts-cursor]:hover .header__burger-label {
  transform: translate(0px, -50%);
}

.header__controls {
  pointer-events: none;
}

.header_absolute {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 500;
}

.header_fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 500;
}

.header__container {
  padding-left: 0;
  padding-right: 0;
  position: relative;
  z-index: 501;
}

.header_menu-hidden .header__burger {
  opacity: 0;
  visibility: hidden;
}

.header__burger {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 50px;
  height: 50px;
  vertical-align: middle;
  cursor: pointer;
  pointer-events: initial;
  z-index: 500;
  margin-right: -12px;
  top: 5px;
}
.header__burger:hover .header__burger-line:before {
  transform: translateX(100%);
}
.header__burger:hover .header__burger-line:after {
  transform: translateX(0%);
}

.header__burger-label {
  display: inline-block;
  height: 14px;
  position: absolute;
  top: 50%;
  right: 100%;
  transform: translate(0, -50%);
  text-align: right;
  transition: all 0.3s ease !important;
}

.header__label-burger_inner {
  position: absolute;
  top: 0;
  right: 0;
  transition: transform 0.3s ease 0s, opacity 0.3s ease 0s;
}

.header__burger-line {
  position: relative;
  width: 26px;
  height: 2px;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  transition: all 1s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.header__burger-line:nth-of-type(1):before, .header__burger-line:nth-of-type(1):after {
  transition-delay: 0ms;
}
.header__burger-line:nth-of-type(2):before, .header__burger-line:nth-of-type(2):after {
  transition-delay: 50ms;
}
.header__burger-line:nth-of-type(3):before, .header__burger-line:nth-of-type(3):after {
  transition-delay: 100ms;
}
.header__burger-line:last-of-type {
  margin-top: 2px;
  margin-bottom: auto;
}
.header__burger-line:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(0%);
  background-color: var(--color-dark-2);
  transition: all 0.3s ease;
}
.header__burger-line:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(calc(-100% - 4px));
  background-color: var(--color-dark-2);
  transition: all 0.5s ease;
}

.header__burger_opened .header__burger-line:nth-of-type(1), .header__burger_opened:hover .header__burger-line:nth-of-type(1) {
  transform: scaleX(1) rotate(45deg) translate(2px, 2px);
}
.header__burger_opened .header__burger-line:nth-of-type(2), .header__burger_opened:hover .header__burger-line:nth-of-type(2) {
  transform: scaleX(1) rotate(-45deg) translate(2px, -2px);
}
.header__burger_opened .header__burger-line:nth-of-type(3), .header__burger_opened:hover .header__burger-line:nth-of-type(3) {
  transform-origin: right center;
  transform: scaleX(0);
}

.header__wrapper-overlay-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 500;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
}

.header__label {
  white-space: nowrap;
  transition: color 0.3s ease;
  font-size: calc(1 * (18 * 1px));
  line-height: 0.5;
  font-weight: 600;
}
@media screen and (min-width: 320px) {
  .header__label {
    font-size: calc(1 * (18 * 1px + (20 - 18) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .header__label {
    font-size: calc(1 * (20 * 1px));
  }
}
@media only screen and (max-width: 767px) {
  .header__label {
    font-size: 17px;
  }
}

.header__label_status {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: calc(100% + 2px);
  transition: all 0.3s ease;
}

.header__label_opened-back {
  position: absolute;
  top: 50%;
  transform: translate(0%, 100%);
  left: calc(100% + 2px);
  opacity: 0;
  transition: all 0.3s ease;
}

.header__label_side {
  position: absolute;
  top: 50%;
  left: 0;
  padding-left: 0;
  padding-right: 0;
  transform: translateY(-50%);
}

.header__wrapper-overlay-widgets .widget {
  margin-bottom: 0;
}

.header__wrapper-menu {
  position: relative;
  margin-top: auto;
  margin-bottom: auto;
  padding: calc(var(--gutter-vertical) * 1.5) var(--gutter-horizontal);
  width: 100%;
  height: 100%;
  display: flex !important;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.header__wrapper-menu .scroll-content {
  height: 100%;
  width: 100%;
  display: flex !important;
  flex-direction: column;
}

.header__wrapper-slider {
  padding-left: var(--gutter-horizontal);
  padding-right: var(--gutter-horizontal);
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  pointer-events: auto !important;
}

.header__overlay-menu-back {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  width: 50px;
  height: 50px;
  left: calc(41.666667% + var(--gutter-horizontal) - 20px);
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  pointer-events: initial;
  z-index: 50;
  transition: color 0.3s ease;
}
.header__overlay-menu-back i {
  font-size: 32px !important;
}
.header__overlay-menu-back:hover .header__label_status {
  transform: translate(0%, -100%);
  opacity: 0;
}
.header__overlay-menu-back:hover .header__label_opened-back {
  transform: translate(0%, -50%);
  opacity: 1;
}

.header__overlay-menu-info, .social__border_1, .social__border_2, .address, .logo__border, .social-top {
  opacity: 0;
  visibility: hidden;
}

.header_menu-right .menu .sub-menu {
  left: auto;
  right: 1em;
}
.header_menu-right .menu .sub-menu ul {
  left: auto;
  right: calc(100% + 1px);
}

.header_menu-split-center .header__col-right .menu .sub-menu {
  left: auto;
  right: 1em;
}
.header_menu-split-center .header__col-right .menu .sub-menu ul {
  left: auto;
  right: calc(100% + 1px);
}

.header__menu-gradient {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 60;
}

.header__menu-gradient_top {
  top: 0;
  height: calc(var(--gutter-vertical) * 2);
}

.header__menu-gradient_bottom {
  bottom: 0;
  height: calc(var(--gutter-vertical));
}

.header__scroll-container {
  width: 100%;
  height: 100%;
  display: flex !important;
}
.header__scroll-container .scroll-content {
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
}

@media screen and (max-width: 991px) {
  .header__burger {
    width: 32px;
    height: 32px;
    margin-right: -3px;
    margin-left: 3px;
  }
  .header__burger-label {
    margin-right: 6px;
  }
  .header__wrapper-menu {
    margin-top: 0;
    padding-top: 5em;
    padding-bottom: 5em;
  }
  .header__overlay-menu-info {
    display: none;
  }
  .header__overlay-menu-back {
    left: calc(var(--gutter-horizontal) * -1);
  }
  .header__wrapper-slider {
    padding-top: 1.5em;
    padding-bottom: 5em;
    border-top: 1px solid var(--color-border-dark);
  }
  .header__menu-gradient_top {
    height: calc(var(--gutter-vertical) * 3);
  }
  .header__menu-gradient_bottom {
    height: calc(var(--gutter-vertical) * 3);
  }
}
.address {
  position: absolute;
  top: 50px;
  left: 220px;
}
@media only screen and (max-width: 991px) {
  .address {
    display: none;
  }
}
.address a, .address p {
  font-size: 18px;
  margin: 0px !important;
  color: #000;
  line-height: 1.2;
}

.social-top {
  position: absolute;
  top: 40px;
  right: 180px;
}
.social-top .social__item {
  margin-right: 6px;
}
@media only screen and (max-width: 991px) {
  .social-top {
    display: none;
  }
}

.header__line {
  background: #000;
  height: 1px;
  width: 100%;
  position: absolute;
  top: 120.5px;
}
@media only screen and (max-width: 576px) {
  .header__line {
    top: 95.5px;
  }
}

.logo__border {
  background: #000;
  width: 1px !important;
  position: absolute;
  top: -20px;
  height: 140px;
  left: 170px;
}
@media only screen and (max-width: 991px) {
  .logo__border {
    display: none;
  }
}

.social__border_1 {
  background: #000;
  width: 1px !important;
  position: absolute;
  top: -20px;
  height: 140px;
  right: 305px;
}
@media only screen and (max-width: 991px) {
  .social__border_1 {
    display: none;
  }
}

.social__border_2 {
  background: #000;
  width: 1px !important;
  position: absolute;
  top: -20px;
  height: 140px;
  right: 150px;
}
@media only screen and (max-width: 991px) {
  .social__border_2 {
    display: none;
  }
}

.header-border {
  margin-top: 16px;
  height: 1px;
  position: relative;
  border-bottom: 1px solid var(--color-dark-1);
}
@media only screen and (max-width: 576px) {
  .header-border {
    margin-top: 10px;
  }
}
.header-border .section__headline {
  width: 100%;
}

.header__wrapper-slider {
  position: absolute !important;
  top: 0;
  right: 0;
  width: 50%;
}
@media only screen and (max-width: 991px) {
  .header__wrapper-slider {
    display: none;
  }
}
.header__wrapper-slider .lazy-wrapper {
  position: relative;
  margin-right: -20px;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: 40px;
}

.opened .header__container:after {
  content: "";
  position: absolute;
  top: 0px;
  width: calc(100% + 60px);
  background: #fff;
  height: 120px;
  z-index: -1;
  margin-left: -30px;
  margin-right: -30px;
  margin-top: -20px;
  left: 0;
  background: var(--color-yellow) !important;
  transition: all 0.6s ease-in-out;
}
@media only screen and (max-width: 767px) {
  .opened .header__container:after {
    height: 100px;
  }
}

.header .social .social__item a {
  background: var(--color-dark-1) !important;
  color: var(--color-light-1) !important;
}

[data-arts-header-logo=primary] .logo__img-primary, .preloader[data-arts-preloader-logo=primary]:not(.preloader_ended) + #page-header .logo__img-primary {
  opacity: 1;
  visibility: visible;
}
[data-arts-header-logo=primary] .logo__img-secondary, .preloader[data-arts-preloader-logo=primary]:not(.preloader_ended) + #page-header .logo__img-secondary {
  opacity: 0;
  visibility: hidden;
}
[data-arts-header-logo=primary] .logo__text-title, .preloader[data-arts-preloader-logo=primary]:not(.preloader_ended) + #page-header .logo__text-title {
  color: var(--paragraph-color-dark) !important;
}
[data-arts-header-logo=primary] .logo__text-tagline, .preloader[data-arts-preloader-logo=primary]:not(.preloader_ended) + #page-header .logo__text-tagline {
  color: var(--color-gray-2) !important;
}

[data-arts-header-logo=secondary] .logo__img-primary, .preloader[data-arts-preloader-logo=secondary]:not(.preloader_ended) + #page-header .logo__img-primary {
  opacity: 0;
  visibility: hidden;
}
[data-arts-header-logo=secondary] .logo__img-secondary, .preloader[data-arts-preloader-logo=secondary]:not(.preloader_ended) + #page-header .logo__img-secondary {
  opacity: 1;
  visibility: visible;
}
[data-arts-header-logo=secondary] .logo__text-title, .preloader[data-arts-preloader-logo=secondary]:not(.preloader_ended) + #page-header .logo__text-title {
  color: #fff !important;
}
[data-arts-header-logo=secondary] .logo__text-tagline, .preloader[data-arts-preloader-logo=secondary]:not(.preloader_ended) + #page-header .logo__text-tagline {
  color: var(--color-gray-2) !important;
}

.js-header-sticky {
  transition-property: padding-top, padding-bottom, box-shadow, background-color;
  transition-duration: 0.4s;
  transition-timing-function: ease;
  box-shadow: 0px 0px 30px 0px rgba(24, 24, 24, 0);
  will-change: padding-top, padding-bottom, box-shadow, background-color;
}
.js-header-sticky:not(.header_sticky) {
  background-color: unset;
}

.header_sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 0px;
}
.header_sticky .logo__wrapper-img img {
  width: 90px;
}
.header_sticky[data-arts-header-sticky-logo=primary] .logo__img-primary {
  opacity: 1;
  visibility: visible;
}
.header_sticky[data-arts-header-sticky-logo=primary] .logo__img-secondary {
  opacity: 0;
  visibility: hidden;
}
.header_sticky[data-arts-header-sticky-logo=primary] .logo__text-title {
  color: var(--paragraph-color-dark) !important;
}
.header_sticky[data-arts-header-sticky-logo=primary] .logo__text-tagline {
  color: var(--color-gray-1) !important;
}
.header_sticky[data-arts-header-sticky-logo=secondary] .logo__img-primary {
  opacity: 0;
  visibility: hidden;
}
.header_sticky[data-arts-header-sticky-logo=secondary] .logo__img-secondary {
  opacity: 1;
  visibility: visible;
}
.header_sticky[data-arts-header-sticky-logo=secondary] .logo__text-title {
  color: #fff !important;
}
.header_sticky[data-arts-header-sticky-logo=secondary] .logo__text-tagline {
  color: var(--color-gray-2) !important;
}
.header_sticky.bg-dark-1:hover, .header_sticky.bg-dark-2:hover, .header_sticky.bg-dark-3:hover, .header_sticky.bg-dark-4:hover,
.header_sticky .menu > li > a:hover {
  color: #fff;
}

.header_sticky_no-shadow {
  box-shadow: none !important;
}

.header[data-arts-theme-text=light], .preloader[data-arts-theme-text=light]:not(.preloader_ended) + #page-header {
  color: var(--color-gray-2);
}
.header[data-arts-theme-text=light] .header__label, .preloader[data-arts-theme-text=light]:not(.preloader_ended) + #page-header .header__label {
  color: #fff;
}
.header[data-arts-theme-text=light] .logo__text-title, .preloader[data-arts-theme-text=light]:not(.preloader_ended) + #page-header .logo__text-title {
  color: #fff;
}
.header[data-arts-theme-text=light] a, .header[data-arts-theme-text=light] .button, .preloader[data-arts-theme-text=light]:not(.preloader_ended) + #page-header a, .preloader[data-arts-theme-text=light]:not(.preloader_ended) + #page-header .button {
  color: var(--color-gray-1);
}
.header[data-arts-theme-text=light] a:hover, .header[data-arts-theme-text=light] .button:hover, .preloader[data-arts-theme-text=light]:not(.preloader_ended) + #page-header a:hover, .preloader[data-arts-theme-text=light]:not(.preloader_ended) + #page-header .button:hover {
  color: #fff;
}
.header[data-arts-theme-text=light] .header__burger-line:after, .header[data-arts-theme-text=light] .header__burger-line:before, .preloader[data-arts-theme-text=light]:not(.preloader_ended) + #page-header .header__burger-line:after, .preloader[data-arts-theme-text=light]:not(.preloader_ended) + #page-header .header__burger-line:before {
  background-color: #fff;
}
.header[data-arts-theme-text=light] .menu .sub-menu, .preloader[data-arts-theme-text=light]:not(.preloader_ended) + #page-header .menu .sub-menu {
  background-color: #333333;
}
.header[data-arts-theme-text=light] .menu .sub-menu > li a:hover, .preloader[data-arts-theme-text=light]:not(.preloader_ended) + #page-header .menu .sub-menu > li a:hover {
  background-color: #383838 !important;
  color: #fff;
  border-color: #fff;
}
.header[data-arts-theme-text=light] .menu > li.current-menu-ancestor .sub-menu li.current-menu-ancestor > a, .preloader[data-arts-theme-text=light]:not(.preloader_ended) + #page-header .menu > li.current-menu-ancestor .sub-menu li.current-menu-ancestor > a {
  background-color: #383838 !important;
  color: #fff !important;
}
.header[data-arts-theme-text=light] .menu > li.current-menu-ancestor .sub-menu li.current-menu-item > a, .preloader[data-arts-theme-text=light]:not(.preloader_ended) + #page-header .menu > li.current-menu-ancestor .sub-menu li.current-menu-item > a {
  background-color: #424242 !important;
  color: #fff !important;
}

.spinner {
  position: fixed;
  left: var(--gutter-horizontal);
  bottom: 10px;
  width: 30px;
  height: 30px;
  z-index: 10000;
  animation: rotator 1.2s ease-in-out infinite;
  opacity: 0;
  visibility: hidden;
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}
.spinner__path {
  stroke: var(--color-gray-1);
  stroke-dasharray: 202;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash 1.2s ease-in-out infinite;
}

@keyframes dash {
  0% {
    stroke-dashoffset: 202;
    transform: rotate(0deg);
  }
  50% {
    stroke-dashoffset: 50.5;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 202;
    transform: rotate(450deg);
  }
}
#page-header .menu {
  position: relative;
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin: 0 -1em;
}
#page-header .menu > li {
  display: inline-block;
}
#page-header .menu > li:not(:last-child) {
  margin-right: 1em;
}
#page-header .menu > li a {
  display: block;
  padding: 0.5em 1em;
}
#page-header .menu .menu-item-has-children {
  position: relative;
}
#page-header .menu .menu-item-has-children > a:hover ~ ul {
  opacity: 1;
  visibility: visible;
  transform: translate(0px, 0px);
  z-index: 50;
}
#page-header .menu > li > a {
  background-image: linear-gradient(currentColor, currentColor);
  background-position: calc(100% - 1em) calc(100% - 0.25em);
  background-repeat: no-repeat;
  background-size: 0% 2px;
  transition: background-size 0.4s ease, color 0.2s ease-in-out;
}
#page-header .menu > li > a:hover {
  background-position: 1em calc(100% - 0.25em);
  background-size: calc(100% - 2em) 2px;
}
#page-header .menu > li.current-menu-item > a {
  background-size: calc(100% - 2em) 2px;
  background-position: 1em calc(100% - 0.25em);
}
#page-header .menu > li.current-menu-ancestor > a {
  background-position: 1em calc(100% - 0.25em);
  background-size: calc(100% - 2em) 2px;
}
#page-header .menu > li.current-menu-ancestor .sub-menu li.current-menu-ancestor > a {
  background-color: #fafafa;
}
#page-header .menu > li.current-menu-ancestor .sub-menu li.current-menu-item > a {
  background-color: #fafafa;
  color: var(--color-dark-2);
}

.menu_hover > li > a:not(:hover) {
  background-position: calc(100% - 1em) calc(100% - 0.25em);
  background-size: 0% 2px;
}
.menu_hover > li.current-menu-item > a {
  background-position: calc(100% - 1em) calc(100% - 0.25em);
  background-size: 0% 2px;
}
.menu_hover > li.current-menu-ancestor > a {
  background-position: calc(100% - 1em) calc(100% - 0.25em);
  background-size: 0% 2px;
}

.menu.menu_disabled {
  pointer-events: none !important;
}
.menu.menu_disabled * {
  pointer-events: none !important;
}
.menu.menu_disabled .sub-menu {
  opacity: 0 !important;
  visibility: hidden !important;
  transform: translate(0px, 1em) !important;
}

.menu .sub-menu {
  position: absolute;
  top: 100%;
  left: 1em;
  transform: translate(0px, 1em);
  list-style-type: none;
  padding: 0;
  margin: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.6s ease-in-out;
  z-index: -1;
  background-color: #fff;
  border-radius: 2px;
  text-align: left;
  box-shadow: 0px 0px 30px 0px rgba(24, 24, 24, 0.04);
}
.menu .sub-menu:hover {
  opacity: 1;
  visibility: visible;
  transform: translate(0, 0);
  z-index: 50;
}
.menu .sub-menu > li {
  white-space: nowrap;
}
.menu .sub-menu > li a {
  padding: 0.5em 1em;
  background-color: transparent;
  border-left: 2px solid transparent;
}
.menu .sub-menu > li a:hover {
  background-color: #fafafa;
  border-color: var(--color-dark-2);
  color: var(--color-dark-2);
}
.menu .sub-menu ul {
  top: 0;
  left: calc(100% + 1px);
  transform: translate(0.5em, 0);
  opacity: 0;
  visibility: hidden;
}

@media screen and (max-width: 1360px) {
  .menu > li:not(:last-child) {
    margin-right: 0.25em;
  }
}
.breadcrumbs {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.breadcrumbs li {
  display: inline-block;
}
.breadcrumbs li:not(:last-child):after {
  content: "/";
  color: var(--color-dark-1);
  display: inline-block;
  margin-left: 6px;
  margin-right: 5px;
  transition: all 0.6s ease-in-out;
}

.menu-overlay {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  margin: auto 0;
  z-index: 50;
  margin-top: 150px;
  margin-left: 180px;
}
@media only screen and (max-width: 991px) {
  .menu-overlay {
    margin-left: 0px;
    margin-top: 100px;
  }
}
@media only screen and (max-width: 576px) {
  .menu-overlay {
    margin-top: 80px;
  }
}
.menu-overlay > li {
  display: flex;
  align-items: center;
  flex: 1 0 100%;
}
.menu-overlay > li .arts-split-text__line {
  padding-bottom: 0px;
}
.menu-overlay > li > a {
  display: block;
  min-width: 400px;
  padding-bottom: 20px;
}
@media only screen and (max-width: 767px) {
  .menu-overlay > li > a {
    min-width: auto;
  }
}
.menu-overlay > li.current-menu-item > a .menu-overlay__heading .arts-split-text__line {
  background-size: 100% 1px;
  background-position: 0% 100%;
}
.menu-overlay > li.current-menu-ancestor > a .menu-overlay__heading .arts-split-text__line {
  background-size: 100% 1px;
  background-position: 0% 100%;
  width: 100%;
}
.menu-overlay > li.current-menu-ancestor .sub-menu li.current-menu-ancestor > a .menu-overlay__heading .arts-split-text__line {
  background-size: 100% 1px;
  background-position: 0% 100%;
}
.menu-overlay > li.current-menu-ancestor .sub-menu li.current-menu-item > a .menu-overlay__heading .arts-split-text__line {
  background-size: 100% 1px;
  background-position: 0% 100%;
}
.menu-overlay li a:hover .menu-overlay__heading .arts-split-text__line {
  background-position: 0% 100%;
  background-size: 100% 1px;
}

.menu-overlay_hover li a:not(:hover) .menu-overlay__heading .arts-split-text__line {
  background-position: 100% 100%;
}
.menu-overlay_hover > li.current-menu-item > a:not(:hover) .menu-overlay__heading .arts-split-text__line {
  background-size: 0% 1px;
}
.menu-overlay_hover > li.current-menu-ancestor > a:not(:hover) .menu-overlay__heading .arts-split-text__line {
  background-size: 0% 1px;
}
.menu-overlay_hover > li.current-menu-ancestor .sub-menu li.current-menu-ancestor > a:not(:hover) .menu-overlay__heading .arts-split-text__line {
  background-size: 0% 1px;
}
.menu-overlay_hover > li.current-menu-ancestor .sub-menu li.current-menu-item > a:not(:hover) .menu-overlay__heading .arts-split-text__line {
  background-size: 0% 1px;
}

.menu-overlay__item-wrapper {
  width: 100%;
  height: 100%;
}

.menu-overlay .sub-menu {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: calc(var(--gutter-vertical) * 2) var(--gutter-horizontal);
  list-style-type: none;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  max-height: 100vh;
}
.menu-overlay .sub-menu > li {
  display: block;
}
.menu-overlay .sub-menu > li > a {
  display: inline-block;
  padding: 8px 0;
}

.menu-overlay__subheading {
  display: block;
}

[data-arts-header-animation=intransition] .menu-overlay li a .menu-overlay__heading .arts-split-text__line {
  background-position: 100% 100% !important;
  background-size: 0% 1px !important;
  transition: background-size 0.2s ease, color 0.2s ease-in-out !important;
}

.menu-overlay__heading {
  font-weight: 500;
}
@media only screen and (max-width: 576px) {
  .menu-overlay__heading {
    font-size: 40px;
  }
}

.section-content__divider {
  position: absolute;
  top: 0;
  left: calc(var(--bs-gutter-x) / 2);
  right: calc(var(--bs-gutter-x) / 2);
  width: auto;
  height: 1px;
  background-color: var(--color-border-dark);
}

.section-content__wrapper-item {
  position: relative;
}
.section-content__wrapper-item:first-child {
  padding-top: 0 !important;
}
.section-content__wrapper-item:first-child .section-content__divider {
  display: none;
}
.section-content__wrapper-item:last-child {
  padding-bottom: 0 !important;
  border-bottom: none;
}

.section-content__content {
  position: relative;
}

@media screen and (max-width: 576px) {
  .section-form {
    padding-bottom: 0 !important;
  }
}
.section-blog__wrapper-post:last-child {
  margin-bottom: 0 !important;
}

@media screen and (max-width: 576px) {
  .section-blog .section-offset__content {
    transform: none !important;
  }
}
.section-fullscreen-columns__border:not(:last-child) {
  border-right: 1px solid var(--color-border-dark);
}

@media screen and (max-width: 991px) {
  .section-fullscreen-columns__border:not(:last-child) {
    border-right: none;
    border-bottom: 1px solid var(--color-border-dark);
  }
}
@media screen and (min-width: 992px) {
  .section-fullscreen-columns__header {
    padding-top: calc(1 * (var(--distance-min-small) * 1px));
    padding-bottom: calc(1 * (var(--distance-min-small) * 1px));
  }
}
@media screen and (min-width: 992px) and (min-width: 320px) {
  .section-fullscreen-columns__header {
    padding-top: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 992px) and (min-width: 1920px) {
  .section-fullscreen-columns__header {
    padding-top: calc(1 * (var(--distance-max-small) * 1px));
  }
}
@media screen and (min-width: 992px) and (min-width: 320px) {
  .section-fullscreen-columns__header {
    padding-bottom: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 992px) and (min-width: 1920px) {
  .section-fullscreen-columns__header {
    padding-bottom: calc(1 * (var(--distance-max-small) * 1px));
  }
}
.section-image {
  display: flex;
  flex-direction: column;
}

.section-image__wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.section-image__overlay {
  z-index: 0;
}

.section-list__item {
  display: flex;
  align-items: center;
  margin-bottom: 2em;
}
.section-list__item:last-child {
  margin-bottom: 0;
}

.section-list__counter {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(1 * (50 * 1px));
  height: calc(1 * (50 * 1px));
  flex-shrink: 0;
  margin-right: 1em;
}
@media screen and (min-width: 320px) {
  .section-list__counter {
    width: calc(1 * (50 * 1px + (72 - 50) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .section-list__counter {
    width: calc(1 * (72 * 1px));
  }
}
@media screen and (min-width: 320px) {
  .section-list__counter {
    height: calc(1 * (50 * 1px + (72 - 50) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .section-list__counter {
    height: calc(1 * (72 * 1px));
  }
}
.section-list__counter .svg-circle {
  stroke-width: 1px;
  stroke: var(--color-border-dark);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.section-list__wrapper-circle {
  position: absolute;
  top: 0;
  le: 0;
  width: 100%;
  height: 100%;
}

.section-list__content {
  width: 100%;
}
.section-list__content ul, .section-list__content li {
  padding-left: 0 !important;
}
.section-list__content li:last-child {
  margin-bottom: 0;
}

.section-logos__wrapper-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.6s ease-in-out;
  transition-property: opacity;
  opacity: 0.5;
}
.section-logos__wrapper-logo:hover {
  opacity: 1;
}

.section-masthead__inner {
  position: relative;
  overflow: hidden;
  z-index: 50;
}

.section-masthead__background {
  overflow: hidden;
}

.section-masthead__background_fullscreen, .section-masthead__background_halfscreen {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.section-masthead__info {
  position: relative;
  z-index: 50;
}

.section-masthead__wrapper-content {
  position: relative;
  z-index: 50;
}

.section-masthead__wrapper-scroll-down {
  display: inline-block;
  position: absolute;
  bottom: var(--gutter-horizontal);
  z-index: 50;
}

.text-center .section-masthead__wrapper-scroll-down {
  left: 0;
  right: 0;
  margin: auto;
}

.text-start .container-fluid .section-masthead__wrapper-scroll-down {
  left: var(--gutter-horizontal);
}

.text-end .container-fluid .section-masthead__wrapper-scroll-down {
  right: var(--gutter-horizontal);
}

.text-start .container .section-masthead__wrapper-scroll-down {
  left: 0;
}

.text-end .container .section-masthead__wrapper-scroll-down {
  right: 0;
}

.section-nav-projects {
  flex-wrap: wrap;
}

.section-nav-projects__next-image {
  width: 100%;
  height: 30vh;
}
.section-nav-projects__next-image .lazy-wrapper {
  max-width: 100% !important;
  height: 100%;
}
.section-nav-projects__next-image .lazy {
  display: inline-block;
  width: auto;
  height: 100% !important;
  padding-bottom: 0 !important;
}
.section-nav-projects__next-image .lazy img {
  position: relative !important;
  width: auto;
  height: 100%;
}
.section-nav-projects__next-image .js-transition-img__transformed-el {
  width: 100%;
  height: 100%;
}

.section-nav-projects__header {
  cursor: pointer;
}

.section-nav-projects__next-image {
  cursor: pointer;
}

.section-nav-projects__link {
  display: block;
}

.section-nav-projects__subheading {
  position: relative;
}

.section-nav-projects__label_scroll {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(100%);
  opacity: 0;
  visibility: hidden;
}

.section-nav-projects__label {
  display: inline-block;
}

.section-nav-projects__inner {
  height: calc(var(--fix-bar-vh, 1vh) * 100);
}

.section-nav-projects__spacer {
  width: 100%;
  height: calc(var(--fix-bar-vh, 1vh) * 33);
}

@media screen and (max-width: 767px) {
  .section-nav-projects__inner {
    height: auto;
  }
}
.section-scroll-theme-switch {
  transition: all 0.6s ease-in-out;
}

@media screen and (max-width: 576px) {
  .section-slider-images {
    padding-left: 0;
    padding-right: 0;
  }
}
.section-video {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.section-video__link {
  display: block;
  margin: auto;
  width: calc(1 * (80 * 1px));
  height: calc(1 * (80 * 1px));
  border-radius: 100%;
  will-change: transform;
  font-weight: 500;
  color: #fff;
  z-index: 60;
}
@media screen and (min-width: 320px) {
  .section-video__link {
    width: calc(1 * (80 * 1px + (160 - 80) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .section-video__link {
    width: calc(1 * (160 * 1px));
  }
}
@media screen and (min-width: 320px) {
  .section-video__link {
    height: calc(1 * (80 * 1px + (160 - 80) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .section-video__link {
    height: calc(1 * (160 * 1px));
  }
}

.section-video__link-inner {
  background-color: var(--color-dark-1);
  color: #fff;
  border-radius: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-touchevents .section-video__link-inner[data-arts-cursor-label]:not([data-arts-cursor-label=false]):hover .section-video__icon {
  opacity: 0;
  visibility: hidden;
  transform: translateY(20px);
}

.section-video__link-inner[data-arts-cursor-label]:not([data-arts-cursor]):not([data-arts-cursor-label=false]):after {
  content: attr(data-arts-cursor-label);
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 50;
  transform: translate(-50%, -20px);
  opacity: 0;
  visibility: hidden;
  transition: all 0.6s ease-in-out;
}
.section-video__link-inner[data-arts-cursor-label]:not([data-arts-cursor]):not([data-arts-cursor-label=false]):hover:after {
  opacity: 1;
  visibility: visible;
  transform: translate(-50%, -50%);
}

.section-video__container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.section-video__icon.material-icons {
  transition: all 0.6s ease-in-out;
  font-size: calc(24 * 1px);
}
@media screen and (min-width: 320px) {
  .section-video__icon.material-icons {
    font-size: calc(24 * 1px + (32 - 24) * ((100vw - 320px) / 1600));
  }
}
@media screen and (min-width: 1920px) {
  .section-video__icon.material-icons {
    font-size: calc(32 * 1px);
  }
}
.section-video__icon.material-icons > * {
  color: #fff !important;
}

.slider-images {
  position: relative;
}

.slider-images__slider[data-drag-class] .slider-images__slide {
  transition: transform 1s ease;
}
.slider-images__slider[data-drag-class] img {
  transition: transform 1s ease;
}

.slider-images__captions {
  background-image: linear-gradient(var(--color-border-dark), var(--color-border-dark));
  background-position: 0% 0%;
  background-repeat: no-repeat;
  background-size: 100% 2px;
}
.slider-images__captions .swiper-slide {
  cursor: pointer;
  opacity: 0.4;
  transition: all 0.6s ease-in-out;
}
.slider-images__captions .swiper-slide:hover {
  opacity: 1;
}
.slider-images__captions .swiper-slide-active {
  opacity: 1;
}

.slider-images__underline {
  pointer-events: none;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: var(--color-dark-1);
  z-index: 50;
}

.slider-images_scale-up .slider-images__slide {
  transform: scale(0.95);
}
.slider-images_scale-up img {
  transform: scale(1.05);
}

.slider-menu {
  width: 100%;
  height: 100%;
}

.slider-menu__header {
  padding-top: 2em;
  padding-left: 2em;
}

.slider-menu__slide {
  height: auto;
  transition: opacity 0.3s ease;
}
.slider-menu__slide * {
  pointer-events: none !important;
}
.slider-menu__slide.swiper-slide-active {
  opacity: 1;
  pointer-events: auto;
}
.slider-menu__slide.swiper-slide-active * {
  pointer-events: auto !important;
}
.slider-menu__wrapper-image {
  display: block;
}
.slider-menu__wrapper-image img {
  transform: scale(1.1);
  transform-origin: center center;
}

@media screen and (max-width: 991px) {
  .slider-menu__header {
    padding-top: 1em;
    padding-left: 1em;
  }
  .slider-menu {
    margin-left: calc(var(--gutter-horizontal) * -1);
    margin-right: calc(var(--gutter-horizontal) * -1);
    width: auto;
  }
}
.slider-projects-fullscreen {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-projects-fullscreen__images {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.slider-projects-fullscreen__images[data-drag-class] .slider-projects-fullscreen__slide {
  transition: transform 1s ease;
}
.slider-projects-fullscreen__images[data-drag-class] img {
  transition: transform 1s ease;
}

.slider-projects-fullscreen__content {
  max-width: 100vw;
  max-height: 100vh;
  z-index: 60;
}
.section-fullheight__inner_mobile .slider-projects-fullscreen__content {
  max-height: calc(var(--fix-bar-vh, 1vh) * 100);
}

.slider-projects-fullscreen__container-bottom {
  padding-top: var(--gutter-vertical);
  padding-bottom: var(--gutter-vertical);
}

.slider-projects-fullscreen__wrapper-thumbs {
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  height: 136px;
  width: 100%;
  max-width: 1100px;
  z-index: 60;
}
.slider-projects-fullscreen__wrapper-thumbs .swiper-slide {
  cursor: pointer;
}

.slider-projects-fullscreen__wrapper-button {
  height: 136px;
  width: 136px;
  flex: 1 0 auto;
}

.slider-projects-fullscreen__thumbs {
  flex: 1 1 100%;
}

.slider-projects-fullscreen__button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 0;
  padding: 0;
}

.slider-projects-fullscreen__col-content {
  flex: 0 0 auto;
  max-width: 100%;
  max-width: 700px;
}

.slider-projects-fullscreen__col-images {
  flex: 0 0 auto;
  height: 100%;
  width: 100vw;
  z-index: 1;
}

.slider-projects-fullscreen__slide {
  display: flex;
  align-items: center;
  justify-content: center;
}
.slider-projects-fullscreen__slide img {
  width: auto;
  height: calc(100vh - var(--gutter-horizontal) * 4);
}

.slider-projects-fullscreen__fluid-container {
  padding-top: calc(var(--gutter-vertical) * 1.5);
  padding-bottom: calc(var(--gutter-vertical) * 1.5);
  padding-left: 0;
  padding-right: 0;
  z-index: 50;
}

.slider-projects-fullscreen__sidebar {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: calc(var(--gutter-horizontal) * 2);
  z-index: 10;
}

.slider-projects-fullscreen__counter {
  position: absolute;
  right: var(--gutter-horizontal);
  bottom: calc(var(--gutter-vertical) / 1.5);
  width: auto;
  padding-left: 0;
  padding-right: 0;
  z-index: 60;
  display: flex;
  gap: 6px;
  justify-content: center;
  align-items: center;
}

.slider-projects-fullscreen__arrows {
  position: absolute;
  left: var(--gutter-horizontal);
  bottom: calc(var(--gutter-vertical) / 1.5);
  z-index: 60;
  margin-left: -16px;
  margin-bottom: -16px;
}

.slider-projects-fullscreen__arrows_left {
  bottom: 0;
  top: 0;
  margin-bottom: auto;
  margin-top: auto;
}

.slider-projects-fullscreen__arrows_right-bottom {
  bottom: calc(var(--gutter-vertical) / 1.5);
  right: var(--gutter-horizontal);
  left: auto;
  top: auto;
  margin-left: 0;
  margin-right: -8px;
}

.slider-projects-fullscreen__arrows_right-center {
  bottom: 0;
  right: var(--gutter-horizontal);
  left: auto;
  top: 50%;
  transform: translateY(-50%);
}

.slider-projects-fullscreen__scrollbar {
  position: absolute;
  right: var(--gutter-horizontal);
  padding-left: 0 !important;
  padding-right: 0 !important;
  top: 0;
  bottom: 0;
}

.slider-projects-fullscreen__wrapper-scrollbar {
  z-index: 50;
}

.slider-projects-fullscreen__images_scale-up .slider-projects-fullscreen__slide {
  transform: scale(0.95);
}
.slider-projects-fullscreen__images_scale-up img {
  transform: scale(1.05);
}

.slider-projects-fullscreen.hover-zoom .swiper-slide-active {
  transform: scale(1);
}
.slider-projects-fullscreen.hover-zoom .swiper-slide-active img {
  transform: scale(1.05);
}

.slider-projects-fullscreen__wrapper-image {
  display: block;
}
.slider-projects-fullscreen__wrapper-image img {
  transform: scale(1.1);
  transform-origin: center center;
  user-select: none;
}

@media screen and (max-width: 1500px) {
  .slider-projects-fullscreen__fluid-container {
    padding-top: calc(var(--gutter-vertical) * 3);
    padding-bottom: calc(var(--gutter-vertical) * 3);
  }
}
@media screen and (max-width: 1360px) {
  .slider-projects-fullscreen__slide img {
    width: 100%;
    height: auto;
    opacity: 0.2;
  }
}
@media screen and (max-width: 991px) {
  .slider-projects-fullscreen__wrapper-scrollbar_mobile-absolute {
    position: absolute;
    display: inline-block;
    right: 0;
    left: auto;
  }
  .slider-projects-fullscreen__col-images {
    flex: 1 1 100%;
    width: 100%;
    max-width: unset;
    margin-left: calc(var(--gutter-horizontal) * -1);
    margin-right: calc(var(--gutter-horizontal) * -1);
    overflow: hidden;
  }
  .slider-projects-fullscreen__sidebar {
    width: 0;
  }
  .slider-projects-fullscreen__col-content {
    padding-right: 0;
    padding-left: 0;
    padding-top: 2em;
    padding-bottom: 2em;
  }
  .slider-projects-fullscreen__arrows:not(.slider-projects-fullscreen__arrows_left) {
    margin-bottom: 4px;
  }
  .slider-projects-fullscreen__arrows {
    left: 0;
    margin-left: 0;
  }
  .slider__wrapper-arrows_vertical.slider-projects-fullscreen__arrows_left {
    width: 50px;
  }
  .slider__wrapper-arrows_vertical.slider-projects-fullscreen__arrows_right-center {
    width: 50px;
  }
  .slider-projects-fullscreen__arrows_left {
    left: 0;
  }
  .slider-projects-fullscreen__arrows_right-center {
    left: auto;
    right: 0;
  }
  .slider-projects-fullscreen__arrows_right-bottom {
    margin-left: 0;
    left: auto;
    right: 0;
  }
  .slider-projects-fullscreen__counter {
    bottom: calc(var(--gutter-vertical) * 2);
  }
}
.slider__counter_current {
  margin-bottom: 0px;
}

.slider-projects-halfscreen__col-images {
  position: relative;
  max-width: calc(50vw - var(--gutter-horizontal) * 2);
  padding-left: 0;
  padding-right: 0;
}

@media screen and (max-width: 991px) {
  .slider-projects-halfscreen__col-images {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
  }
  .slider-projects-halfscreen__col-images .swiper-slide {
    padding: 0;
  }
}
.slider-projects__header {
  padding-top: 0px;
  padding-left: 1.5em;
}

.slider-projects__slide {
  transition: opacity 0.3s ease;
}
.slider-projects__slide * {
  pointer-events: none !important;
}
.slider-projects__slide.swiper-slide-active {
  opacity: 1;
  pointer-events: auto;
}
.slider-projects__slide.swiper-slide-active * {
  pointer-events: auto !important;
}
.slider-projects__wrapper-image {
  display: block;
}
.slider-projects__wrapper-image img {
  transform: scale(1.1);
  transform-origin: center center;
  user-select: none;
}

.slider-projects__wrapper_bottom-left {
  position: absolute;
  left: var(--gutter-horizontal);
  right: auto;
  bottom: calc(var(--gutter-vertical) / 2);
}

.slider-projects__wrapper_bottom-right {
  position: absolute;
  right: var(--gutter-horizontal);
  left: auto;
  bottom: calc(var(--gutter-vertical) / 2);
}

.slider-projects__wrapper_bottom-center {
  position: absolute;
  display: inline-block;
  left: 50%;
  transform: translateX(-50%);
  bottom: calc(var(--gutter-vertical) / 2);
}

@media screen and (max-width: 991px) {
  .slider-projects {
    position: static !important;
  }
  .slider-projects__header {
    padding-top: 1em;
    padding-left: 0;
  }
  .slider-projects__wrapper_bottom-center {
    bottom: var(--gutter-vertical);
    left: auto;
    right: var(--gutter-horizontal);
    transform: none;
  }
}
.slider-testimonials__wrapper-circle {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(1 * (80 * 1px));
  height: calc(1 * (80 * 1px));
  border-radius: 50%;
  border: 2px solid var(--color-border-dark);
}
@media screen and (min-width: 320px) {
  .slider-testimonials__wrapper-circle {
    width: calc(1 * (80 * 1px + (145 - 80) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .slider-testimonials__wrapper-circle {
    width: calc(1 * (145 * 1px));
  }
}
@media screen and (min-width: 320px) {
  .slider-testimonials__wrapper-circle {
    height: calc(1 * (80 * 1px + (145 - 80) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .slider-testimonials__wrapper-circle {
    height: calc(1 * (145 * 1px));
  }
}
.slider-testimonials__wrapper-circle .svg-circle {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.slider-testimonials__quote {
  width: calc(1 * (30 * 1px));
}
@media screen and (min-width: 320px) {
  .slider-testimonials__quote {
    width: calc(1 * (30 * 1px + (50 - 30) * ((100vw - 320px) / 1600)));
  }
}
@media screen and (min-width: 1920px) {
  .slider-testimonials__quote {
    width: calc(1 * (50 * 1px));
  }
}

.slider-testimonials__text {
  max-width: 100%;
}

.widget_nav_menu ul.menu > li {
  white-space: normal;
  word-break: break-word;
}
.widget_nav_menu ul.menu > li a {
  display: inline-block;
  padding: 0;
  font-size: 16px;
  line-height: 1.5;
  font-weight: normal;
  text-transform: none;
  letter-spacing: 0;
}
.widget_nav_menu ul.menu > li.menu-item-has-children {
  margin-bottom: 1.5em !important;
}
.widget_nav_menu ul.menu > li.menu-item-has-children a:after {
  display: none;
}

.widget_text .textwidget > *:first-child {
  margin-top: 0;
}
.widget_text .textwidget > *:last-child {
  margin-bottom: 0;
}

.widget_kinsey_social ul {
  text-align: inherit;
}
.widget_kinsey_social ul li {
  display: inline-block;
  margin-bottom: 0;
}