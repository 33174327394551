.footer
	color: #000
	z-index: 100
	+fluid('padding-top', 60, 140)
	+fluid('padding-bottom', 20, 40)
	background: url(../img/new/img_footer.png) no-repeat center center
	background-color: var(--color-dark-1)
	background-size: contain
	background-position: bottom right
	
	
	a
		color: #000
.footer
	.form
		width: 100%
		+fluid('padding-bottom', 60, 140)
		p
			display: flex
			width: 100%
		.wpcf7-form-control-wrap
			width: calc( 100% - 160px )
			input
				width: 100%
				border: none
				border-bottom: 1px solid var(--color-light-1)
				background: transparent
				height: 100%
				+fluid('font-size', 18, 22)
				color: var(--color-light-1)
		.wpcf7-submit
			color: var(--color-light-1) !important
			font-weight: 600
			border: none
			background: var(--color-dark-1) url('../img/new/flecha_larga.svg') no-repeat
			background-position: calc(100% - 15px) center
			background-size: 40px auto
			padding: 10px 45px 10px 25px !important
			font-size: 18px !important
			transition: all 0.4s ease
			border-radius: 0
			width:160px
			+fluid('margin-left', 30, 60)
			cursor: pointer
			opacity: 1
			text-align: left
			
			&:hover
				opacity: 0.9
				transition: all 0.4s ease
			
		.wpcf7-spinner
			position: absolute		
		.wpcf7 .wpcf7-response-output
			border: none !important
			padding-left: 0px !important
			font-weight: 600 !important
			color: var(--color-light-1) !important
			font-size: 18px !important
			margin-top: 10px !important
			margin-left: 0px !important

		input, textarea
			border-radius: 0

		::placeholder
			color: var(--color-light-1) !important
			@include fluid-type(var(--h3-min-font-size), var(--h3-max-font-size))
			font-weight: 400
				
		.wpcf7-not-valid-tip
			display: none	
