.button
	position: relative
	display: inline-block
	+fluid('font-size', 16, 24)
	line-height: 1
	font-weight: normal
	white-space: nowrap
	cursor: pointer
	box-shadow: none
	border-width: 2px
	border-style: solid
	padding: 13px 25px 15px 25px
	font-weight: 500
	//border-radius: 0px
	color: #fff !important
	background: var(--color-dark-1)
	+trans1
	transition-property: color, background-color, border-color
	&.white
		border-color: var(--color-light-1)
	&.small
		font-size: 16px
		padding: 12px 18px
	&.small-width
		+fluid('font-size', 16, 20)
		width: 100%
		display: flex
		justify-content: end
		padding: 18px 60px 18px 30px
		clip-path: polygon(0 0, calc(100% - 50px) 0, 100% 100%, 0 100%)
		.button__label-hover
			transform: translate(-20%, -50%)
			left: auto
			right: 40px
	&:focus
		outline: none
	&:hover
		.button__label-normal
			.button__title
				transform: translate(0%, -100%)
				opacity: 0
			.button__icon
				opacity: 0
				transform: translate(100%, 0%)
		.button__label-hover
			.button__title
				transform: translate(0%, 0%)
				opacity: 1
			.button__icon
				opacity: 1
				transform: translate(0%, 0%)

.button_icon
	display: inline-flex
	border: none
	.button__label
		padding: 0
.button__label-hover
	position: absolute
	top: 50%
	left: 50%
	transform: translate(-50%, -50%)
	.button__title
		transform: translate(0%, 100%)
		opacity: 0
	.button__icon
		transform: translate(-100%, 0%)
		opacity: 0

.button__label-normal
	.button__title
		transform: translate(0%, 0%)
		opacity: 1

.button__title
	display: inline-block
	transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out
.button__label
	display: block
	padding: 18px 42px

.button__icon
	display: inline-flex
	align-items: center
	justify-content: center
	// width: 60px
	transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out
	font-size: 100%
.button__icon_before
	margin-right: 0.5em
.button__icon_after
	margin-left: 0.5em
	&.arrow-1
		background: url('../img/new/flecha_larga.svg') no-repeat center center
		+fluid('width', 40, 70)
		+fluid('height', 10, 14)
	&.arrow-2
		background: url('../img/new/fkecha_derecha_white.svg') no-repeat center center
		+fluid('width', 25, 25)
		+fluid('height', 15, 15)	


.button_fullwidth
	width: 100%
.button-fullwidth
	.button
		width: 100%

.knz-arrow-right:before
	font-size: 14px
