.transition-curtain
	position: fixed
	top: 0
	left: 0
	right: 0
	bottom: 0
	transform: scaleY(0)
	transform-origin: bottom center
	margin: auto
	z-index: 400
	pointer-events: none
