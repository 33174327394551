.swatch__figure
	display: inline-block
	border-radius: 50%
.swatch__figure_color
	width: 120px
	height: 120px
.swatch__header
	display: block
	width: 100%
.swatch__subheading
	color: var(--color-gray-3)
