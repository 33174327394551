#arrow_1
	width: 80px
	@media only screen and (max-width: $sm)
		width: 70px
	.cls-1
		fill: none
	.cls-2,
	.cls-3
		stroke: none
	.cls-3
		fill: #fff
