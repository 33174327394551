.figure-category
	position: relative
	display: flex
	height: 0
	Width: 100%
	padding-bottom: 100%
.figure-category__icon
	@include fluid-type(26, 64)
.figure-category__link
	display: flex
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%
	flex-direction: column
	align-items: center
	justify-content: center
	padding: 30px
	border-radius: 100%
	border: 1px solid $color-border-dark
	transition-property: opacity, color, background-color, border-color
	&:hover
		border-color: $color-dark-1
.cursorfollower
	.figure-category__link[data-arts-cursor]
		&:hover
			border-color: transparent
