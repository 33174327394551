.kareoke
	touch-action: none
	pointer-events: none
	padding: 0px 20px
	h3
		color: grey !important
		margin-bottom: 40px
		font-weight: 500
		line-height: 1.3
	h3, span, br
		&::selection
			background: #0000
			color: white
	&::selection
		background: #0000
		color: white
.pantalla
	user-select: none

	height: 100%
	width: 100%
	background-color: transparent
	position: absolute
	top: 0

.fondo-miniatura
	position: absolute
	right: 40px
	opacity: 0
	transform: translateX(0)
	&:nth-child(1)
		animation: moverX 20s linear infinite
		top: 100px
		right: 50vw
		max-width: 260px
		@media only screen and (max-width: $md)
			max-width: 240px
		@media only screen and (max-width: $sm)
			max-width: 220px
		@media only screen and (max-width: $xs)
			max-width: 200px

	&:nth-child(2)
		animation: moverX 25s linear infinite
		top: -40px
		right: 10vw
		max-width: 100px
		@media only screen and (max-width: $md)
			max-width: 80px
		@media only screen and (max-width: $xs)
			top: -60px

	&:nth-child(3)
		animation: moverX 22s linear infinite
		right: 30vw
		bottom: -40px
		max-width: 160px
		@media only screen and (max-width: $md)
			max-width: 140px
		@media only screen and (max-width: $md)
			max-width: 120px
		@media only screen and (max-width: $xs)
			bottom: -60px
			max-width: 100px
	&:nth-child(4)
		animation: moverX 16s linear infinite
		top: 50%
		right: -5vw
		max-width: 180px
		@media only screen and (max-width: $md)
			max-width: 160px
		@media only screen and (max-width: $sm)
			max-width: 140px
		@media only screen and (max-width: $xs)
			max-width: 120px

@keyframes moverX
	20%
		opacity: 0.4
	50%
		opacity: 0.8
	100%
		opacity: 0
		transform: translateX(-25vw)

.section-texto-creatividad
	border-top: 1px solid #000
