.section-image
	// overflow: hidden
	display: flex
	flex-direction: column
.section-image__wrapper
	position: relative
	width: 100%
	height: 100%
.section-image__overlay
	z-index: 0
