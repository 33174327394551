.circle-header
	position: absolute
	z-index: 999
	z-index: 9
	top: -120px
	left: 0
	right: 0
	margin: 0 auto

	.circle
		max-width: 600px
		max-height: 600px
		overflow: hidden
		margin: auto
		border-radius: 0vw 0vw 300px 300px
		margin: 0 auto
		position: relative
		top: 0px
		@media only screen and (max-width: $lg)
			max-width: 560px
			max-height: 560px
		@media only screen and (max-width: $xs)
			margin-left: 20px
			margin-right: 20px

.section-estudios
	margin-top: 340px
	@media only screen and (max-width: $lg)
		margin-top: 320px
	@media only screen and (max-width: $sm)
		margin-top: 300px
	@media only screen and (max-width: $xs)
		margin-top: 260px
	.box-content
		.box

			position: relative
			margin-bottom: 40px
			@media only screen and (max-width: $xxl)
				padding: 0px 15px
			@media only screen and (max-width: $xl)
				padding: 0px 10px
			@media only screen and (max-width: $xs)
				margin-bottom: 40px
			.flex-1
				min-height: 270px

			.img-download
				min-height: 300px
				background-size: cover
				background-position: center
				position: relative
				@media only screen and (max-width: $xs)
					min-height: 260px
				.selectores
					position: absolute
					bottom: 20px
					left: 20px
					display: flex
					flex-direction: column
					gap: 5px
				.sector
					color: var(--color-light-1)
					background: var(--color-dark-1)
					padding: 4px 8px
					line-height: 1
					font-weight: bold
					text-transform: uppercase
					+fluid('font-size', 11, 12)
					letter-spacing: 1px
					clip-path: polygon(0 0, calc(100% - 15px) 0, 100% 100%, 0 100%)
					padding-right: 20px
					width: fit-content
					display: inline-flex
				.ano
					background: var(--color-dark-1)
					color: #fff
					display: inline-block
					padding: 10px 4px
					line-height: 1
					font-weight: 600
					position: absolute
					left: 30px
					+fluid('font-size', 18, 22)
					max-width: 50px
					word-break: break-all
					text-align: center
				.download
					font-size: 17px
					font-weight: 600
					color: #000
					position: absolute
					right: 20px
					display: flex
					flex-direction: column
					align-items: flex-end
					gap: 10px
					.lazy-wrapper
						margin-left: 10px
						width: 20px	
				.tringulo
					width: 180px
					height: 140px
					position: absolute
					right: 0px
					top: -1px
					clip-path: polygon(100% 0, 0 0, 100% 100%)

			.bg
				padding: 30px
				min-height: 200px
	
				

			

.filtros
	.todos, option
		@extend .h6
		margin-bottom: 4px
		font-weight: bold !important
		&.ano
			border-bottom: 0px

select
	display: block
	background: var(--color-dark-1)
	padding: 10px 15px
	outline: none
	-webkit-appearance: none
	-moz-appearance: none
	appearance: none
	background-image: url('../img/general/select_black.png')
	background-position: right 15px bottom 50%
	background-repeat: no-repeat
	background-size: 6px 4px
	font-weight: 600
	font-size: 16px
	color: var(--color-light-1)
	border: none
	margin-bottom: 5px


.section-estudios
	.box-content
		> div
			&:nth-child(6n+1)
				.bg, .tringulo
					background: var(--color-rose)  // rosado
			&:nth-child(6n+2)
				.bg, .tringulo
					background: var(--color-yellow)  // amarillo
			&:nth-child(6n+3)
				.bg, .tringulo
					background: var(--color-orange)
			&:nth-child(6n+4)
				.bg, .tringulo
					background: var(--color-orange)  // naranja
			&:nth-child(6n+5)
				.bg, .tringulo
					background: var(--color-rose)  // rosado
			&:nth-child(6n+6)
				.bg, .tringulo
					background: var(--color-yellow)  // amarillo
