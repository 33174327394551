/* CONTACTO  */	

.section-contacto
	.margin-header
		margin-top: 0px

.modal.contacto
	.wpcf7
		margin-top: 50px

		input, textarea
			border-radius: 0px
			border: 1px solid #000
			min-width: 460px
			padding: 8px
			font-size: 18px
			text-align: center
		textarea
			height: 120px
		input
			height: 36px

		label
			font-size: 18px
			text-transform: uppercase
			font-weight: notmal
			letter-spacing: 1px
			@media only screen and (max-width: $sm)
				font-size: 16px

		.wpcf7-not-valid-tip
			text-align: center
			font-weight: 500
			color: var(--color-rose)
		.wpcf7-submit
			color: var(--color-rose) !important
			font-weight: 600
			margin-top: 10px
			border: 2px solid var(--color-rose) !important
			padding: 8px 25px !important
			background: transparent
			font-size: 18px !important
			transition: all 0.4s ease
			min-width: 140px
			height: auto
			margin-left: 60px
			margin-top: 20px
			&:hover
				background: var(--color-rose) !important
				color: #fff !important
				transition: all 0.4s ease

.section-contacto
	.bottom
		display: none
		@media only screen and (max-width: $sm)
			display: block
	.line-full-2, .horizontal
		@media only screen and (max-width: $sm)
			display: none

.grid__item:nth-child(3n+1)
	.section__headline.horizontal_
		display: none !important


.grecaptcha-badge
	display: none !important





