.overflow
	position: relative
	overflow: hidden
	&.d-inline-block
		vertical-align: middle
.overflow-initial
	overflow: initial
.position-relative
	position: relative
.position-fixed
	position: fixed
.of-cover
	width: 100% !important
	height: 100% !important
	object-fit: cover
	font-family: 'object-fit: cover;'
.of-contain
	width: 100%
	height: 100%
	object-fit: contain
	font-family: 'object-fit: contain;'
.hidden
	opacity: 0
	visibility: hidden
.no-gutters
	padding-left: 0
	padding-right: 0
.grayscale
	filter: grayscale(70%)
.w-100
	width: 100% !important
.w-100vh
	width: 100vh !important
.w-100vw
	width: 100vw !important
.h-100
	height: 100% !important
.h-100vh
	height: 100vh !important
.h-100vw
	height: 100vw !important
.clearfix:after
	content: ''
	display: table
	clear: both
.backgroundblendmode
	.blend-difference
		mix-blend-mode: difference
		color: #fff
		a
			color: #fff
			&:hover
				opacity: .7
.hidden_absolute
	position: absolute
	top: -9999px
	left: -9999px
	opacity: 0
	visibility: hidden
#js-webgl
	display: none
.d-flex-center
	display: flex
	align-items: center
	justify-content: center
.d-flex-top
	display: flex
	align-items: center
	justify-content: start
	@media only screen and (max-width: $sm)
		display: flex
		align-items: center
		justify-content: center

.d-flex-bottom
	display: flex
	align-items: center
	justify-content: end
	@media only screen and (max-width: $sm)
		display: flex
		align-items: center
		justify-content: center
.z-50
	z-index: 50
.z-100
	z-index: 100
.z-500
	z-index: 500
.z-1000
	z-index: 1000

.block-circle
	border-radius: 100%
.block-counter
	padding-left: 0.75em
	padding-right: 0.75em
	position: relative
.block-counter__counter
	position: absolute
	top: -6px
	left: calc(100% - 6px)
	@extend .subheading
	letter-spacing: 0
	white-space: nowrap
.border-right
	//border-right: 1px solid #000 !important
.border-left
	//border-left: 1px solid #000 !important

.border-bottom
	//border-bottom: 1px solid #000 !important

.color-rose
	color: var(--color-rose) !important

.color-orange
	color: var(--color-orange) !important

.bg-rose
	background: var(--color-rose)
	color: #fff !important

.bg-blue
	background: var(--color-blue)
	color: #fff !important

.bg-orange
	background: var(--color-orange)
	color: #fff !important

.bg-degraded-1
	background: var(--color-dark-1)

.bg-degraded-2
	background: var(--color-dark-1)
	
.bg-cover
	background-size: cover
.view-all
	color: var(--color-rose) !important
	text-transform: uppercase
	text-decoration: underline
	font-size: 15px
	font-weight: 600

.strong_orange
	b, strong
		color: var(--color-orange)

.strong_rose
	&.underline
		b
			text-decoration: underline
			color: var(--color-rose)
	b
		color: var(--color-rose)

.section__headline.horizontal
	width: 1px !important
	height: 100%

b, strong
	font-weight: bold

@media screen and (min-width: $md + 1px)
	.h-lg-100
		height: 100% !important
	.h-lg-100vh
		height: 100vh !important
	.h-lg-100vh-fix-bar
		height: calc(var(--fix-bar-vh, 1vh) * 100) !important
	.h-lg-100vw
		width: 100vw !important
	.row-wide
		margin-left: -50px
		margin-right: -50px
	.col-wide
		padding-left: 50px
		padding-right: 50px

.pointer-events-none
	pointer-events: none !important
	*:not(.pointer-events-auto)
		pointer-events: none !important

// [data-arts-os-animation].pointer-events-none
// 	pointer-events: none !important
// 	.pointer-events-auto, .pointer-events-none .pointer-events-auto
// 		pointer-events: none !important
// 		*
// 			pointer-events: none !important
// 	*
// 		pointer-events: none !important
// 	.slider-projects__slide.swiper-slide-active *
// 		pointer-events: none !important
.pointer-events-auto, .pointer-events-none .pointer-events-auto
	pointer-events: auto !important
	*
		pointer-events: auto !important
.swiper-slide:not(.swiper-slide-active)
	.pointer-events-auto, .pointer-events-none .pointer-events-auto
		pointer-events: none !important
		*
			pointer-events: none !important
.border-radius-100
	border-radius: 100%
.grayscale
	filter: grayscale(70%)
.border-none
	border: none !important
