.tabs
	margin: 0px
	padding: 0px
	width: 100%
	margin-bottom: 50px
	@media only screen and (max-width: $xs)
		margin-left: 60px

	.tablinks
		list-style: none
		font-size: 18px
		cursor: pointer
		padding: 6px 15px
		margin-right: 30px
		font-weight: 500
		@media only screen and (max-width: $xs)
			font-size: 16px

		&.active
			transition: all 1s ease
			color: var(--color-rose)
			border: 1px solid var(--color-rose)


.tabs.style-2
	margin: 0
	padding: 0
	width: 100%
	display: flex
	position: relative
	overflow: visible
	max-width: 900px
	margin-bottom: 0px
	@media only screen and (max-width: $xs)
		flex-direction: column
		max-width: 100%
		margin-left: 0
	
	.tablinks:first-child
		+fluid(padding-left, 20, 200)
		@media only screen and (max-width: $xs)
			padding-left: 30px

	.tablinks:last-child
		text-align: center
		width: calc(50% + 100px)
		@media only screen and (max-width: $xs)
			width: 100%
			text-align: left
			padding-left: 30px
			top: 0px

	.tablinks
		list-style: none
		font-weight: bold
		@extend .h4
		cursor: pointer
		padding: 25px 30px
		margin: 0
		background: var(--color-light-1)
		position: relative
		width: 50%
		text-align: left
		transition: background 0.3s ease, color 0.3s ease
		border: 1px solid var(--color-dark-1)
		@media only screen and (max-width: $xs)
			width: 100%
			padding: 20px 30px
		
		&:after
			content: ''
			position: absolute
			width: 120px
			height: 100%
			background: var(--color-light-1)
			top: 0
			right: -80px
			transform: skewX(40deg)
			z-index: 1
			border-right: 1px solid var(--color-dark-1)
			border-bottom: 1px solid var(--color-dark-1)
			height: calc( 100% + 1px )
			transition: background 0.3s ease
			@media only screen and (max-width: $xs)
				width: 60px
				right: -30px
				border-left: 1px solid var(--color-dark-1)
				top: -1px
				

		&:last-child
			padding-left: 60px
			@media only screen and (max-width: $xs)
				padding-left: 30px
			&:after
				width: 120px
				right: -38.5px
				@media only screen and (max-width: $xs)
					width: 60px
					right: -30px
					border-left: 1px solid var(--color-dark-1)
					

		&.active
			background: var(--color-blue)
			color: white
			&:after
				background: var(--color-blue)
				@media only screen and (max-width: $xs)
					background: var(--color-light-1)
					top: -1px			

.wrapper_tabcontent
	z-index: 1
	position: relative
	opacity: 1
	padding: 0
	overflow: hidden
	transition: all 0.4s ease
	top: 0
	text-align: left

.tabcontent
	display: none
	&.active
		display: block