.modal
	max-width: none
	max-height: 100vh
	display: none
	flex-direction: column
	position: absolute
	z-index: 1
	top: 0
	width: 100vw
	height: 100vh
	border: none
	transform: scale(.5)
	opacity: 0
	margin: 0
	padding: 0px
	box-sizing: border-box
	background-color: var(--color-dark-1)

	/* HEADER */ 
	.circle-header
		right: auto
		@include fluid('left', 50, 200)
		@include fluid('top', -200, -300)
		z-index: 1
		.circle
			@include fluid('max-width', 400, 700)
			@include fluid('max-height', 400, 700)
			border-radius: 50%

	header
		width: 100%
		display: flex
		justify-content: space-between
		background-color: $color
		padding-left: var(--gutter-horizontal)
		padding-right: var(--gutter-horizontal)
		position: sticky
		top: 0
		z-index: 999
		background:	var(--color-dark-1)
		&.fix::after
			width: calc(100% - 30px)
			margin-right: 0
		&::after
			content: ''
			position: absolute
			bottom: 0
			width: 100%
			left: 0
			right: 0
			margin: 0 auto
			height: 1px
			background: var(--color-light-1)
			z-index: 999

		.logo
			padding: 10px 0px
			@include fluid('width', 90, 110)

		.cerrar-modal
			font-size: 18px
			padding-left: 40px
			border: none
			border-left: solid 1px var(--color-light-1)
			cursor: pointer
			font-size: 16px
			background: transparent
			color: var(--color-light-1)
			span
				font-size: 22px
				position: relative
				top: 0px
				padding-left: 10px
				color: var(--color-light-1)

	/* CONTENT  */ 
	.container
		padding: 80px 20px
		@media only screen and (max-width: $sm)
			padding: 60px 20px

	.section-modal-content
		overflow-x: hidden
		width: 100%
		height: 100%
		z-index: 9
		.content-full
			max-width: 900px
			width: 100%
			margin: 0 auto
			text-align: left
			padding: 40px 20px
			position: sticky
			top: 0
			.h2, p
				color: #fff !important
	/* FICHA */ 	

	.ficha
		padding: 60px 40px 60px 40px
		background-color: var(--color-blue)
		text-align: left
		color: var(--color-dark-1)
		.content-right:first-child
			border-top: 1px solid rgba(#fff, 0.9)
			padding-top: 20px
		.content-right
			padding-bottom: 20px
			margin-bottom: 20px
			border-bottom: 1px solid rgba(#fff, 0.9)
			.h6
				margin-top: 0px
				+fluid('font-size', 20, 26)
				font-weight: bold
				+fluid('min-height', 26, 30)
			p, a
				+fluid('font-size', 14, 16)
				margin-top: 0
				margin-bottom: 5px
				font-weight: 600
				display: block
				
				line-height: 1.7
			a
				text-decoration: underline
			&.editor
				a
					display: inline
					text-decoration: underline

	.modal-wraper-contenido
		position: relative
		flex: 1
		overflow: auto

		.modal-contenido
			position: absolute
			width: 100%
			min-height: calc( 100vh - 364px)
			display: flex
			opacity: 0
			transition: all .2s ease
			transform: translateY(-100%)
			background-color: var(--color-dark-1)
			&.show

				transform: translateY(0)
				opacity: 1
				position: relative
				z-index: 9
			.modal-cotenido-right
				p
					@include fluid-type(var(--h6-min-font-size), var(--h6-max-font-size))
					margin-top: 0px
					color: var(--color-light-1)
				a
					color: var(--color-blue)
					font-weight: 500
				.web
					color: var(--color-blue)
					font-weight: 500
					font-size: 20px
					margin-top: 40px
					display: inline-block
					@media only screen and (max-width: $md)
						font-size: 18px

			.modal-cotenido-left
				.hashtag
					margin-top: 30px
					font-size: 18px
					color: var(--color-light-1)
				.h3

					p
						@include fluid-type(var(--h2-min-font-size), var(--h2-max-font-size))
						line-height: 1
						margin-top: 0px
						margin-bottom: 10px
						font-weight: 500
						color: var(--color-light-1)
						@media only screen and (max-width: $sm)
							font-size: 36px
					strong, b
						color: var(--color-blue)
						margin-top: 10px
						display: block

				.text-orange
					color: $naranja

	.modal-nav
		box-sizing: border-box
		display: flex
		padding-top: 50px
		width: 100%
		background-color: var(--color-dark-1)
		@media only screen and (max-width: $sm)
			display: block
		button
			width: 50%
			height: 10rem
			padding: 0px 60px
			background: none
			color: $color
			@include fluid('font-size', 18, 20)
			border: solid 1px rgba(#fff, 0.6)
			border-width: 1px 0
			cursor: pointer
			transition: 0.5s all ease
			@media only screen and (max-width: $sm)
				width: 100%

			&.modal-nav-next
				border-width: 1px 0 1px 1px
				@media only screen and (max-width: $sm)
					border-width: 1px 0 1px 0px

			&:hover
				color: var(--color-blue)

	.d-grid
		margin-bottom: 20px
		p
			padding: 0
			margin: 0
			font-size: 16px
			margin-bottom: 4px

	ul
		list-style-type: '- '
		list-style-position: inside
		color: white
		padding: 0

	.centrar
		width: 100%
		display: flex
		flex-direction: column
		align-items: start

	.contenido
		max-width: 600px

	.titulo-principal
		font-size: 3.5rem
		max-width: 650px
		font-weight: 500
		line-height: 4rem
		margin: 4rem 0

	&[open]
		display: flex
		animation: mostrar-modal .2s forwards ease-out
	&.cerrar
		animation: cerrar-modal .2s forwards ease-out
	&::backdrop
		background: transparent

@keyframes mostrar-modal
	100%
		transform: scale(1)
		opacity: 1

@keyframes cerrar-modal
	from
		transform: scale(1)
		opacity: 1

	100%
		transform: scale(.5)
		opacity: 0

.modal.contacto
	.ticker-wrap
		position: relative
		border-bottom: 1px solid #000
		.xxl
			color: #000 !important

:focus
	outline: none




