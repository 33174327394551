
#feedInstagram
	.feed
		width: 33%
		padding: 0px 15px
		@media only screen and (max-width: $md)
			padding: 0px 10px
		@media only screen and (max-width: $sm)
			width: 50%
	.feed:last-child
		@media only screen and (max-width: $sm)
			width: 100%

.feedInstagram
	.feed
		display: inline-block
		overflow: hidden
		position: relative
		margin-bottom: 25px
		width: 40%
		@media only screen and (max-width: $sm)
			margin-bottom: 20px
		video
			width: 100%
			overflow: hidden
			height: 100%
			transition: all 1s ease
		a
			width: 100%
			height: 100%
			display: flex
			overflow: hidden
			img
				transition: all 1s ease
				transform: scale(1)
				height: 100%
				overflow: hidden
		svg
			fill: white
			width: 48px
			height: 48px
			position: absolute
			top: 50%
			left: 50%
			transform: translate(-50%, -50%) scale(2)
			opacity: 0
			transition: all .5s ease
			@media only screen and (max-width: $sm)
				width: 34px
				height: 34px

		&:hover
			img, video
				filter: saturate(0)
				transform: scale(1.1)
			svg
				transform: translate(-50%, -50%) scale(1)
				opacity: 1
