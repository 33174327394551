.bg-blue
	.new-style
		a, summary
			border-bottom: 1px solid var(--color-light-1)
			border-right: 1px solid var(--color-light-1)
		h2
			color: var(--color-light-1)

.new-style, details
	+fluid(padding-right, 20, 200)
	summary
		padding-left: 90px !important
		@media only screen and (max-width: $xxl)
			padding-left: 40px !important
		@media only screen and (max-width: $xl)
			padding-left: 20px !important
	&[open]
		summary
			border-bottom: 1px solid var(--color-light-1)
			&:before
					transform: rotate(-90deg)
			&:after
				height: 100%	
			svg
				transition: all 0.3s ease
				stroke: var(--color-light-1)
				transform: rotate(180deg)
				line, polyline
					stroke: var(--color-light-1)
			&:after
				height: 100%
			h2
				color: var(--color-light-1)
				transition: all 0.3s ease
	a, summary
		border-bottom: 1px solid var(--color-dark-1)
		border-right: 1px solid var(--color-dark-1)
		display: flex
		justify-content: space-between
		align-items: center
		+fluid(padding-top, 20, 35)
		+fluid(padding-bottom, 20, 35)
		+fluid(padding-left, 20, 200)
		+fluid(padding-right, 20, 100)
		position: relative
		overflow: hidden
		cursor: pointer
		.button
			background: transparent
			span
				color: var(--color-dark-1)
				position: relative
				z-index: 1
		h2
			@extend .h4
			font-weight: 600 !important
			margin: 0
			position: relative
			z-index: 1
			transition: all 0.3s ease
		svg
			+fluid(width, 80, 90)
			transition: all 0.3s ease
			position: relative
			z-index: 1
		&:hover
			svg
				transition: all 0.3s ease
				stroke: var(--color-light-1)
				transform: rotate(180deg)
				line, polyline
					stroke: var(--color-light-1)
					
			&:after
				height: 100%
			h2
				color: var(--color-light-1)
				transition: all 0.3s ease		


		&:after
			position: absolute
			left: 0
			top: 0
			content: ''
			width: 100%
			height: 0
			background-color: var(--color-dark-1)
			transition: all .5s ease
					

.cursor-pointer
	cursor: pointer !important


.box-hidden
	overflow: hidden
	.bg-img
		transition: all 0.5s ease
	&:hover
		.bg-img
			transform: scale(1.05)
			transition: all 0.5s ease

.estudios
	.box
		.img-download
			transition: all 0.5s ease
		&:hover
			.img-download
				transform: scale(1.04)
				transition: all 0.5s ease
				


.box-blog
	&:hover
		.bg-img
			transform: scale(1.05)
			transition: all 0.5s ease


	
.categories-menu
	a
		position: relative
		&::after
			content: ''
			position: absolute
			top: -3px
			left: -3px
			right: -3px
			bottom: -3px
			border: 3px solid transparent
			transition: all 0.3s ease
		&:hover
			background: transparent !important
			color: #000 !important
			&::after
				border-color: #000


.fix-underline
	span
		text-decoration: underline
		text-decoration-thickness: 3px !important
		text-underline-offset: 4px !important
		

