/* BLOG */	



.section-blog
	.grid

		.box
			margin-top: 35px
			margin-bottom: 35px
		.section__headline.horizontal
			position: absolute
			right: 15px
			height: calc( 100% - 0px )

		.section__headline.line-full-2
			width: calc( 100%) !important
			position: absolute
			left: -15px
			@media only screen and (max-width: $xs)
				left: 0px
		.grid__item:nth-child(3n+1)
			.section__headline.horizontal
				display: none !important
				@media only screen and (max-width: $md)
					display: block !important
		.grid__item:nth-child(2n+1)
			.section__headline.horizontal
				@media only screen and (max-width: $md)
					display: none !important
		.grid__item:nth-child(1n+1)
			.section__headline.horizontal
				@media only screen and (max-width: $sm)
					display: none !important

	.grid__item_fluid-1
		padding-top: 0px
		padding-left: 20px
		padding-right: 20px
		padding-bottom: 40px
		@media only screen and (max-width: $xl)
			padding-left: 10px
			padding-right: 10px
		@media only screen and (max-width: $xs)
			padding-left: 0px
			padding-right: 0px	

	.grid_fluid-1
		margin: 0px -20px
		@media only screen and (max-width: $xl)
			margin-left: 15px
		@media only screen and (max-width: $sm)
			margin: 0px !important



.blog-internal
	.editor
		@include fluid-type(var(--paragraph-min-font-size), var(--paragraph-max-font-size))
		.ol, ul
			padding-left: 15px
		h1
			@include fluid-type(var(--h2-min-font-size), var(--h2-max-font-size))
			color: var(--color-rose)
			font-weight: 600
		h2
			@include fluid-type(var(--h3-min-font-size), var(--h3-max-font-size))
			color: var(--color-orange)
			font-weight: 600
		h3
			@include fluid-type(var(--h4-min-font-size), var(--h4-max-font-size))
		h4
			@include fluid-type(var(--h5-min-font-size), var(--h5-max-font-size))
		a
			color: var(--color-orange)
			font-weight: 600
		p
			@extend .h6

.blog-title
	margin-top: 140px
	margin-bottom: 40px
	.tags
		margin-top: 30px
		a
			border: 1px solid var(--color-orange)
			color: var(--color-orange)
			font-size: 12px !important

@media only screen and (min-width: $md)
	.single-post
		.header
			mix-blend-mode: difference
			.header__burger-label
				color: #fff
			.header__burger-line:before, .header__burger-line:after
				background: #fff
			.logo__wrapper-img
				filter: invert(100%)
			.section__headline
				background-color: #fff
			&.header_sticky, &.opened, &[data-arts-header-animation="intransition"]
				mix-blend-mode: normal
				.header__burger-label
					color: #000
				.header__burger-line:before, .header__burger-line:after
					background: #000
				.logo__wrapper-img
					filter: invert(0%)
				.section__headline
					background: #000

.share
	p
		color: var(--color-rose)
		margin-bottom: 10px
.section-blog
	.box-blog
		@media only screen and (max-width: $xs)
			margin-right: 0px
	

.box-blog
	background: var(--color-light-3)
	min-height: 620px
	@media only screen and (max-width: $xs)
		min-height: auto
		
.box-blog.fix
	.categorie
		display: flex
		justify-content: center
		align-items: center
		padding-right: 20px
		gap: 4px
		a
			color: var(--color-dark-1)
			
	.content
		background: var(--color-light-3)
		.title
			padding: 0px 20px
	.bg-img
		height: 300px !important
	h4
		color: var(--color-dark-1)	



.categories-menu
	display: flex
	gap: 12px
	a
		color: var(--color-light-1)
		+fluid(font-size, 18, 24)
		font-weight: bold
		background: var(--color-dark-1)
		padding: 5px 10px
		&.category_active
			background: var(--color-blue)
		&:hover
			opacity: 0.8




.box-blog.old
	padding: 20px
	.date
		margin-top: -20px
		margin-left: -20px
		padding: 12px 10px
		font-size: 18px
		line-height: 1.2
		text-align: center
		font-weight: 500

.box-blog.old-2
	.date
		margin-top: -20px
		padding: 12px 10px
		font-size: 18px
		line-height: 1.2
		text-align: center
		font-weight: 500

.date
	padding: 0px 15px
	font-size: 18px
	line-height: 1.2
	font-weight: 600
	display: flex
	flex-direction: column
	justify-content: center
	text-transform: uppercase
	background: var(--color-dark-1)
	.number
		+fluid('font-size', 20, 26)
		color: var(--color-light-1)
	.month
		+fluid('font-size', 16, 18)
		color: var(--color-light-1)	



.intro-single
	margin-top: 130px
	@media only screen and (max-width: $sm)
		margin-top: 112px
	&:before
		content: ''
		position: absolute
		top: 0
		left: 0
		width: 100%
		height: calc( 100% - 180px)
		background: var(--color-blue)
		z-index: -1
		@media only screen and (max-width: $md)
			height: calc( 100% - 100px)
	h1
		padding-top: 20px
		padding-bottom: 220px
		@media only screen and (max-width: $md)
			padding-bottom: 20px
	.mask-blog
		background: var(--color-light-1)
		width: 180px
		height: 180px
		position: absolute    
		bottom: 0
		left: 0
		clip-path: polygon(0 0, 100% 100%, 100% 100%, 0 100%)
		bottom: -1px
		@media only screen and (max-width: $md)
			display: none
	.date
		position: absolute
		top: 0
.blog-internal
	margin-top: -180px
	@media only screen and (max-width: $md)
		margin-top: 0px
	.date
		@media only screen and (max-width: $md)
			display: none	
	.editor
		margin-top: 20px
		margin-bottom: 100px
		.col-12
			> p:first-of-type:not(blockquote p)
				width: 60%
				@extend .h4
				@media only screen and (max-width: $md)
					width: 100%
			> p:nth-of-type(2):not(blockquote p)
				width: 70%
				@extend .h5
				@media only screen and (max-width: $md)
					width: 100%
			p
				width: 80%
			> ul
				> li:first-of-type
					width: 50%
				> li:nth-of-type(2)
					width: 50%




.box-blog
	a
		&:hover
		

