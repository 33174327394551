.button.button_solid
	border-color: transparent
	color: $color-gray-3
	&.bg-dark-1
		color: #fff
		background-color: $color-dark-1
		// &:hover
		// 	background-color: $color-dark-3
	&.bg-dark-2
		color: #fff
		background-color: $color-dark-2
	&.bg-dark-3
		color: #fff
		background-color: $color-dark-3
	&.bg-dark-4
		color: #fff
		background-color: $color-dark-4
	&.bg-light-1
		background-color: $color-light-1
		color: $color-dark-1
	&.bg-light-2
		background-color: $color-light-2
		color: $color-dark-1
	&.bg-light-3
		background-color: $color-light-3
		color: $color-dark-1
	&.bg-light-4
		background-color: $color-light-4
		color: $color-dark-1
	&.bg-white-1
		background-color: $color-border-light
		color: $color-dark-1
		// &:hover
		// 	background-color: $color-gray-1
	&.bg-gray-1
		background-color: $color-gray-1
	&.bg-gray-2
		background-color: $color-gray-2
	&.bg-gray-3
		background-color: $color-gray-3
	// &:hover
	// 	background-color: $color-dark-1
	// 	color: #fff
