/* PROYECTOS  */	

.section-slider-ruta
	padding-left: 120px !important
	position: relative
	@media only screen and (max-width: $xxl)
		padding-left: 100px !important
	@media only screen and (max-width: $xs)
		padding-left: 90px !important

	.section__headline.line-full
		position: absolute
	.section__headline.bottom
		bottom: 1px

	.section__headline.horizontal
		position: absolute
		right: 0px
	.section__headline.horizontal.left
		position: absolute
		left: 0px
		right: auto
	.section__headline.top
		top: 50%
		position: absolute

	.swiper-slide
		width: auto
		&:last-child
			padding-right: 200px

		.section-content__content
			@media only screen and (max-width: $xs)
				display: flex
				justify-content: center
				align-items: center
				h5
					margin-top: 0px !important

		.box-content
			text-align: left
			height: 340px
			border-right: none
			position: relative
			padding-left: 0px

			&.left
				left: 140px
				position: relative
				z-index: 999
				@media only screen and (max-width: $xs)
					left: 0px

			.box_inside
				padding-left: 40px
				padding-right: 40px
				width: 500px
				position: relative
				@media only screen and (max-width: $xs)
					width: 340px
					padding-left: 25px
					padding-right: 25px

				h5
					margin-top: 40px

				&:after
					z-index: -1
					position: absolute
					left: 0
					top: 0%
					content: ""
					width: 0%
					height: 100%
					transition: all 0.5s ease
				&.rosa:after
					background-color: var(--color-rose)
				&.naranjo:after
					background-color: var(--color-orange)
				&.azul:after
					background-color: var(--color-blue)

				&:hover
					&:after
						width: 100%

				h5
					font-weight: 600

		.first_1
			border-left: 0px
			.box_inside
				left: 100px
				margin-right: 100px
				@media only screen and (max-width: $md)
					left: 160px
					margin-right: 160px
				@media only screen and (max-width: $xs)
					left: 0px
					margin-right: 0px
				.horizontal.left
					@media only screen and (max-width: $xs)
						display: none

		.first_2
			border-left: 0px
			.box_inside
				left: 240px
				margin-right: 0px
				@media only screen and (max-width: $xs)
					left: 0px
				.horizontal.left
					@media only screen and (max-width: $xs)
						display: none

	&.proceso
		.swiper-slide
			width: auto
			&:last-child
				padding-right: 60px !important

		.box-content
			text-align: left
			min-height: 600px
			border-right: none
			position: relative
			padding-left: 0px

			.box_inside
				padding-left: 30px
				padding-right: 30px
				width: 550px
				position: relative
				@media only screen and (max-width: $lg)
					width: 500px
				@media only screen and (max-width: $md)
					width: 450px
				@media only screen and (max-width: $sm)
					width: 400px
				@media only screen and (max-width: $xs)
					width: 340px
					padding-left: 20px
					padding-right: 20px

				h5
					margin-top: 0px
				&:after
					z-index: -1
					position: absolute
					left: 0
					top: 0%
					content: ""
					width: 0%
					height: 100%
					background-color: var(--color-rose)
					transition: all 0.5s ease
				&.rose:after
					background: var(--color-rose)
				&.orange:after
					background: var(--color-orange)
				&.blue:after
					background: var(--color-blue)

				&:hover
					&:after
						width: 100%

					.color-rose
						color: #fff !important

				h5
					font-weight: 600

				.title
					margin-top: 40px
					@media only screen and (max-width: $xs)
						margin-top: 30px

		.first_1
			border-left: 0px
			.box_inside
				left: 160px
				margin-right: 160px
				@media only screen and (max-width: $xs)
					left: 0px
					margin-right: 0px

#tab2
	h5
		max-width: 300px
		padding-right: 20px
		@media only screen and (max-width: $xs)
			margin-bottom: 15px !important
		br
			@media only screen and (max-width: $xs)
				display: none
	h6
		font-size: 19px
		@media only screen and (max-width: $xs)
			font-size: 17px

	p
		@media only screen and (max-width: $xs)
			font-size: 17px
