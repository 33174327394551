.widget_recent_comments
	ul
		li
			padding: 20px 0
			margin-bottom: 0 !important
			border-bottom: 1px solid $color-border-dark
			&:first-child
				padding-top: 0
			&:last-child
				padding-bottom: 0
				border-bottom: none
			a
				// font-family: $font-secondary
				font-weight: bold
	.comment-author-link
		a
			// font-family: $font-primary
			font-weight: normal
