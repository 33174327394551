.social
	display: inline-block
	+reset-ul
	.social__item
		display: inline-block
		transition: none
		margin-bottom: 0
		margin-right: 15px
		@media screen and (max-width: $xl)
			margin-right: 15px
		@media screen and (max-width: $lg)
			margin-right: 8px

		a
			display: flex !important
			align-items: center
			justify-content: center
			width: 40px
			height: 40px
			font-size: 20px
			border-radius: 50%
			background-color: #fff
			color: var(--color-dark-1)
			// border: 1px solid $color-border-dark
			@media screen and (max-width: $lg)
				font-size: 18px
				width: 38px
				height: 38px
			&:hover
				background: transparent
				color: #fff
