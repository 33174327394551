[data-arts-theme-text=light]
	.menu-scroll
		&.style__2
			ul
				li
					&:after
						background: #fff
		ul
			li
				border-bottom: 1px solid rgba(#fff, 0.6)
				a
					color: rgba(#fff, 0.6)
				&:hover
					color: #000
				&.active
					border-bottom: 1px solid #fff
					color: #fff !important

					a
						color: #fff

.menu-scroll
	width: 250px !important
	&.slider
		position: absolute
		right: 40px
		top: 50%
		transform: translate(0%,0%)
		width: 260px
		@media only screen and (max-width: $xl)
			right: 10px
	&.style__2
		width: auto !important
		ul
			display: flex
			margin-bottom: 0
			li
				position: relative
				padding-right: 30px
				border: none
				margin-bottom: 0px
				&:after
					content: ""
					position: absolute
					right: 15px
					height: 18px
					width: 1px
					top: 4px
					background: #000
				&.active
					border: none
					color: #fff !important
				&:last-child:after
					display: none !important

	ul
		list-style: none
		li
			border-bottom: 1px solid rgba(#000, 0.6)
			margin-bottom: 10px
			font-size: 18px
			a
				color: rgba(#000, 0.6)
				font-size: 18px
				display: block
			&:hover
				color: #000
			&.active
				border-bottom: 1px solid #000
				font-weight: 500

				a
					color: #000
					font-weight: 600

.section-texto-creatividad
	.sub-svg
		line-height: 1.3 !important
	&.show
		&::after
			opacity: 1
			transition: opacity 2s


.section-nosotros
	+fluid('padding-top', 170, 260)
	strong
		font-weight: normal !important
		text-decoration: underline
		text-underline-offset: 10px
		text-decoration-thickness: 3px


.section-apoyamos
	.image-hover
		@media only screen and (max-width: $md)
			background: var(--color-dark-1) !important
	.content-number:nth-child(1)
		.image-hover, .diagonal
			background: var(--color-rose)
			
	.content-number:nth-child(2)
		.image-hover, .diagonal
			background: var(--color-orange)
	.content-number:nth-child(3)
		.image-hover, .diagonal
			background: var(--color-yellow)				
	.inside-number:hover
			height: 350px


.content-number
	position: relative
	transition: margin 0.5s ease-in-out
	
	.horizontal.left
		position: absolute
		left: 0
	.horizontal.right
		position: absolute
		right: 0
	.inside-number
		padding: 0px
		position: relative
		+fluid('height', 100, 240)
		overflow: hidden
		transition: all 0.5s ease-in-out
		
		@media only screen and (max-width: $md)
			padding: 50px 20px 40px 0px
			height: auto !important
			overflow: visible
		@media only screen and (max-width: $xs)
			padding: 30px 10px 20px 0px
		
		
			
		.num
			+fluid('margin-right', 0,20)
			font-weight: bold !important

		.title
			line-height: 1.1

		&:hover
			@media only screen and (min-width: $md)
				height: 350px
				
				& + .content-number
					margin-top: 20px
					transition: margin 0.5s ease-in-out
				
				.normal
					transform: translateY(-100%)
					opacity: 0
				
				.image-hover
					transform: translateY(0)
					opacity: 1

	.normal
		position: absolute
		width: 100%
		transition: all 0.5s ease-in-out
		transform: translateY(0)
		opacity: 1
		
		@media only screen and (max-width: $md)
			position: relative

	.image-hover
		position: absolute
		width: 100%
		transition: all 0.5s ease-in-out
		transform: translateY(100%)
		opacity: 0
		height: 100%
		@media only screen and (max-width: $md)
			position: relative
			opacity: 1
			transform: none
			margin-top: 40px
		
		img
			object-fit: cover
			height: 200px
			width: 100%
			padding: 0px 5px
			@media only screen and (max-width: $lg)
				padding: 0px
		.img-2
			position: absolute
			bottom: 0
			@media only screen and (max-width: $md)
				position: relative
			
	.diagonal-fix
		background: var(--color-dark-1)
		height: 100%
		+fluid('width', -100, 300)
		position: absolute
		right: 0
		top: 1px
		clip-path: polygon(100% 0, 0 100%, 100% 100%)
		@media only screen and (max-width: $md)
			display: none

	.text-inside
		margin-bottom: 30px
		+fluid('padding-left', 0, 20)
		font-weight: 600
		max-width: 87%
		@extend .h5
		@media only screen and (max-width: $md)
			color: var(--color-light-1) !important
			padding-left: 0px
			max-width: 100%
		
	

			

.section-linea-tiempo
	padding-top: 20px
	.section-fullheight__inner_mobile
		height: 900px
		@media only screen and (max-width: $sm)
			height: 800px
	.menu-scroll
		position: absolute
		top: 0px



	.section__heading

		position: relative
		left: 40px
	.slider__heading
		strong, b
			color: var(--color-orange) !important
		&.h5
			max-width: 300px
			p, a
				font-size: 26px
				margin: 0px 0px 4px 0px
				font-weight: 600
				color: var(--color-orange)
				line-height: 1.1
			a
				color: #000 !important
	.bandera
		margin-bottom: 6px

	.slider-projects__wrapper-image
		img
			filter: grayscale(100%)
	.slider-projects__wrapper-image
		max-width: 400px
	.ano
		position: absolute
		left: -200px

		z-index: 1
		.numero
			transform: rotate(270deg)
			font-size: 160px
			color: var(--color-orange)
			transition: all 1s ease
			font-weight: 600
			@media only screen and (max-width: $xs)
				font-size: 100px
	.d-flex-bottom
		.ano
			bottom: 110px !important
			@media only screen and (max-width: $sm)
				top: 0px !important
				bottom: auto !important
	.d-flex-top
		.ano
			top: 120px !important
			@media only screen and (max-width: $sm)
				top: 0px !important
	.d-flex-center
		.ano
			top: 80px !important
			@media only screen and (max-width: $sm)
				top: 0px !important

	.swiper-slide-active

		.ano
			left: -220px
			@media only screen and (max-width: $sm)
				left: -50px

			.numero
				color: var(--color-orange)
				transition: all 1s ease
		.slider-projects__wrapper-image
			img
				filter: grayscale(0%)
	.swiper-slide
		padding: 180px 0px 140px 0px
	.cuadrada
		max-width: 280px !important
		@media only screen and (max-width: $sm)
			max-width: 240px !important
	.horizontal
		max-width: 400px !important
		@media only screen and (max-width: $sm)
			max-width: 300px !important
	.vertical
		max-width: 240px !important
		@media only screen and (max-width: $sm)
			max-width: 220px !important
	.fix-center
		position: relative
		left: 10vw
		display: flex
		padding-left: 30px
		padding-right: 20px
		@media only screen and (max-width: $sm)
			display: block
			left: 0px

	.section-fullheight__header
		width: 800px !important
		height: 10px
		z-index: 10
		.arts-split-text__line
			padding-bottom: 2px
		.menu-scroll
			padding-top: 90px
			padding-left: 0px

		.section__heading
			padding-top: 160px
		.menu-scroll
			ul
				margin: 0px !important
				padding: 0px !important
				li:after
					display: none
				span
					color: rgba(0, 0, 0, 0.6) !important
					position: relative
					left: 12px
.section-equipo
	> div
		padding: 100px 20px
	> div:nth-child(4n+1)
		background: var(--color-rose)
	> div:nth-child(4n+2)
		background: var(--color-blue)
	> div:nth-child(4n+3)
		background: var(--color-blue)
	> div:nth-child(4n)
		background: var(--color-rose)
	@media only screen and (max-width: $sm)
		> div:nth-child(odd)
			background: var(--color-rose)
		> div:nth-child(even)
			background: var(--color-blue)

.menu-fix
	position: absolute
	top: -80px

.sub
	position: relative
	&::after
		content: ""
		position: absolute
		bottom: 0
		width: 100%
		left: 0
		height: 100%
		background-image: url(../img/general/sub.svg)
		background-repeat: no-repeat
		background-size: 100%
		background-position: center bottom
		opacity: 1

.sub-svg
	em, a
		font-style: normal !important

.section-linea-tiempo
	.sin-imagen
		.slider-projects__header.text
			position: relative
			left: 50px
			top: 30px

.section-apoyamos
	> .section-content
		@media only screen and (max-width: $xs)
			padding: 0px 15px


.diagonal
	position: relative
	+fluid('height', 80, 120)
	+fluid('width', 80, 120)
	clip-path: polygon(0 0, 100% 0, 0 100%)
	display: flex
	align-items: flex-start
	justify-content: flex-start
	padding: 10px 0 0 10px
	font-size: 24px
	font-weight: bold
	color: #000


.title-top
	position: absolute
	top: 70px
	+fluid('left', 10, 80)
	

.scroll-line
	position: absolute
	bottom: 20px
	+fluid('gap', 10, 20)
	+fluid('left', 10, 80)
	svg
		+fluid('width', 30, 40)
		position: relative
		top: 4px