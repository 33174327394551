.search-form
	::placeholder
		color: #000
		font-size: 18px
		font-weight: 400
		text-align: center
		@media only screen and (max-width: $md)
			font-size: 16px

	label
		input
			width: 300px
			border: none
			border-bottom: 1px solid var(--color-dark-1)
			padding: 6px 0px
			font-weight: 400
			background: transparent
			

	.search-submit
		width: 50px
		height: 50px
		background: transparent
		border: none
		cursor: pointer
		background-image: url("data:image/svg+xml,%3Csvg width='50' height='50' viewBox='0 0 50 50' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='30' cy='20' r='10' stroke='black' stroke-width='2' fill='none'/%3E%3Cline x1='23' y1='27' x2='15' y2='35' stroke='black' stroke-width='2'/%3E%3C/svg%3E")
		background-repeat: no-repeat
		background-position: center
		background-size: contain
		font-size: 0px



