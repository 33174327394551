.card-carrusel
	flex: 1
	width: 100%
	height: 100%
	overflow: hidden
	display: flex
	flex-direction: column
	justify-content: center
	.root
		transform: rotate(-35deg) translateX(-50%)
		.tira
			background-color: black
			padding: 1rem 0
			width: max-content
			color: white
			font-size: 2rem
			font-weight: 500
			margin: 3.5rem 0
			animation: carrusel 16s infinite linear
			@media only screen and (max-width: $xs)
				font-size: 1.8rem
				padding: 0.6rem 0
				margin: 2rem 0
			&.inverse
				animation-direction: reverse

@keyframes carrusel
	0%
		transform: translateX(0%)
	100%
		transform: translateX(-49%)
