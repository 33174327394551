.button.button_bordered
	background-color: unset !important
	color: $color-dark-1
	border-width: 1px
	border-style: solid
	// &.bg-dark-1
	// 	border-color: $color-dark-1
	// 	// &:hover
	// 	// 	border-color: $color-dark-2
	// 	// 	color: $color-dark-2
	// &.bg-dark-2
	// 	border-color: $color-dark-2
	// &.bg-dark-3
	// 	border-color: $color-dark-3
	// &.bg-dark-4
	// 	border-color: $color-dark-4
	// &.bg-light-1
	// 	border-color: $color-light-1
	// &.bg-light-2
	// 	border-color: $color-light-2
	// &.bg-light-3
	// 	border-color: $color-light-3
	// &.bg-light-4
	// 	border-color: $color-light-4
	// &.bg-white-1
	// 	border-color: $color-border-light
	// 	color: #fff
	// 	&:hover
	// 		border-color: #fff
	// &.bg-gray-1
	// 	border-color: $color-gray-1
	// &.bg-gray-2
	// 	border-color: $color-gray-2
	// &.bg-gray-3
	// 	border-color: $color-gray-3
	// &:hover
	// 	border-color: $color-dark-1
	// 	color: $color-dark-1
