.feature__wrapper-icon
	display: inline-flex
	align-items: center
	justify-content: center
	@include fluid('width', 120, 220)
	@include fluid('height', 120, 220)
	// width: 220px
	// height: 220px
	border: 1px solid $color-border-dark
	border-radius: 50%
	padding: 30px
.feature__header
	display: block
	width: 100%
.feature__subheading
	color: var(--color-gray-3)
