.card
	width: 100%
	height: 100%
	position: relative
.card__content
	ul, ol
		padding-left: 0 !important
.card__counter
	position: absolute
	right: 40px
	bottom: 30px
.card__arrow
	margin-left: 1rem


@media screen and (max-width: $md)
	.card__counter
		position: absolute
		right: 25px
		bottom: 15px
