.tira-vertical
	height: 100%
	overflow: hidden
	display: flex
	justify-content: center
	border: solid 1px
	width: 4rem
	.texto
		padding: .5rem
		writing-mode: vertical-lr
		transform: rotate(180deg) translateY(0)
		height: max-content
		animation: anim1 8s infinite linear

.flex-row
	flex-direction: row !important

@keyframes anim1
	from
		transform: rotate(180deg) translateY(0)
	to
		transform: rotate(180deg) translateY(16.66666%)
